import { createSlice } from '@reduxjs/toolkit';
import { LEGEND_INITIAL_STATE } from './legend.constants';
import {
  closeLegendReducer,
  openLegendReducer,
  removePluginLegendReducer,
  setActiveLegendIdReducer,
  setDefaultLegendIdReducer,
  setPluginLegendReducer,
} from './legend.reducers';

const legendSlice = createSlice({
  name: 'legend',
  initialState: LEGEND_INITIAL_STATE,
  reducers: {
    openLegend: openLegendReducer,
    closeLegend: closeLegendReducer,
    setPluginLegend: setPluginLegendReducer,
    setActiveLegendId: setActiveLegendIdReducer,
    setDefaultLegendId: setDefaultLegendIdReducer,
    removePluginLegend: removePluginLegendReducer,
  },
});

export const {
  openLegend,
  closeLegend,
  setPluginLegend,
  setActiveLegendId,
  setDefaultLegendId,
  removePluginLegend,
} = legendSlice.actions;

export default legendSlice.reducer;
