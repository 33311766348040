import { bioShapeSchema } from '@/models/bioShapeSchema';
import { apiPath } from '@/redux/apiPath';
import { ArrowType, BioShape, LineType } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { z } from 'zod';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import {
  ARROW_TYPES_FETCHING_ERROR_PREFIX,
  LINE_TYPES_FETCHING_ERROR_PREFIX,
  SHAPES_FETCHING_ERROR_PREFIX,
} from '@/redux/shapes/shapes.constants';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { lineTypeSchema } from '@/models/lineTypeSchema';
import { arrowTypeSchema } from '@/models/arrowTypeSchema';
import { ArrowTypeDict, BioShapesDict, LineTypeDict } from '@/redux/shapes/shapes.types';
import arrayToDict from '@/utils/array/arrayToDict';

export const getShapes = createAsyncThunk<BioShapesDict | undefined, void, ThunkConfig>(
  'vectorMap/getShapes',
  async () => {
    try {
      const { data } = await axiosInstanceNewAPI.get<BioShape[]>(apiPath.getShapes());
      const isDataValid = validateDataUsingZodSchema(data, z.array(bioShapeSchema));
      if (!isDataValid) {
        return {};
      }
      return arrayToDict(data, 'sboTerm', 'shapes') as BioShapesDict;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: SHAPES_FETCHING_ERROR_PREFIX }));
    }
  },
);

export const getLineTypes = createAsyncThunk<LineTypeDict | undefined, void, ThunkConfig>(
  'vectorMap/getLineTypes',
  async () => {
    try {
      const { data } = await axiosInstanceNewAPI.get<LineType[]>(apiPath.getLineTypes());
      const isDataValid = validateDataUsingZodSchema(data, z.array(lineTypeSchema));

      if (!isDataValid) {
        return {};
      }
      return arrayToDict(data, 'name', 'pattern') as LineTypeDict;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: LINE_TYPES_FETCHING_ERROR_PREFIX }));
    }
  },
);

export const getArrowTypes = createAsyncThunk<ArrowTypeDict | undefined, void, ThunkConfig>(
  'vectorMap/getArrowTypes',
  async () => {
    try {
      const { data } = await axiosInstanceNewAPI.get<ArrowType[]>(apiPath.getArrowTypes());
      const isDataValid = validateDataUsingZodSchema(data, z.array(arrowTypeSchema));

      if (!isDataValid) {
        return {};
      }
      return arrayToDict(data, 'arrowType', 'shapes') as ArrowTypeDict;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: ARROW_TYPES_FETCHING_ERROR_PREFIX }));
    }
  },
);
