export const ERROR_MAP_NOT_FOUND = 'Map with provided id does not exist';
export const ERROR_INVALID_QUERY_TYPE = 'Invalid query type. The query should be of string type';
export const ERROR_INVALID_COORDINATES = 'Invalid coordinates type or values';
export const ERROR_INVALID_MODEL_ID_TYPE = 'Invalid model id type. The model id should be a number';
export const ERROR_PROJECT_NOT_FOUND = 'Project does not exist';
export const ERROR_PROJECT_ID_NOT_FOUND = 'Project id does not exist';
export const ERROR_OVERLAY_NAME_NOT_PROVIDED = 'Overlay name is not provided';
export const ERROR_FAILED_TO_READ_FILE = 'Failed to read file';
export const ERROR_INVALID_TYPE_FILE_CONTENT = 'Invalid type of fileContent';
export const ERROR_OVERLAY_ID_NOT_FOUND = 'Overlay with provided id does not exist';
export const ERROR_OVERLAY_ID_NOT_ACTIVE = 'Overlay with provided id is not active';
export const ERROR_OVERLAY_ID_ALREADY_ACTIVE = 'Overlay with provided id is already active';
export const ERROR_INVALID_MODEL_ID_TYPE_FOR_RETRIEVAL =
  'Unable to retrieve the id of the active map: the modelId is not a number';
export const ERROR_INVALID_EVENT_TYPE = (type: string): string => `Invalid event type: ${type}`;
export const ERROR_PLUGIN_CRASH = (pluginName: string): string =>
  `Plugin "${pluginName}" has crashed. Please contact the plugin developer for assistance`;
export const ERROR_PLUGIN_URL_MISMATCH =
  'The URL specified in the plugin script does not match the actual script URL provided during the registration or loading process';
