import { apiPath } from '@/redux/apiPath';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { NewReaction, NewReactions } from '@/types/models';
import { ThunkConfig } from '@/types/store';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getError } from '@/utils/error-report/getError';
import { newReactionSchema } from '@/models/newReactionSchema';
import { pageableSchema } from '@/models/pageableSchema';
import { NEW_REACTIONS_FETCHING_ERROR_PREFIX } from '@/redux/newReactions/newReactions.constants';

export const getNewReactionsForModel = createAsyncThunk<
  Array<NewReaction> | undefined,
  number,
  ThunkConfig
>('newReactions/getNewReactionsForModel', async (modelId: number) => {
  try {
    const { data } = await axiosInstanceNewAPI.get<NewReactions>(
      apiPath.getNewReactionsForModel(modelId),
    );
    const isDataValid = validateDataUsingZodSchema(data, pageableSchema(newReactionSchema));
    return isDataValid ? data.content : undefined;
  } catch (error) {
    return Promise.reject(getError({ error, prefix: NEW_REACTIONS_FETCHING_ERROR_PREFIX }));
  }
});
