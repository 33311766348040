interface ManageUserIconProps {
  className?: string;
}

export const ManageUserIcon = ({ className }: ManageUserIconProps): JSX.Element => (
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 13V15C4.68629 15 2 17.6863 2 21H0C0 16.5817 3.58172 13 8 13ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10ZM10.5946 17.8115C10.5327 17.5511 10.5 17.2794 10.5 17C10.5 16.7207 10.5327 16.449 10.5945 16.1886L9.6029 15.6161L10.6029 13.884L11.5952 14.4569C11.9883 14.0851 12.4676 13.8034 13 13.6449V12.5H15V13.6449C15.5324 13.8034 16.0116 14.0851 16.4047 14.4569L17.3971 13.8839L18.3972 15.616L17.4055 16.1885C17.4673 16.449 17.5 16.7207 17.5 17C17.5 17.2793 17.4673 17.551 17.4055 17.8114L18.3972 18.3839L17.3972 20.116L16.4048 19.543C16.0117 19.9149 15.5325 20.1966 15.0001 20.355V21.5H13.0001V20.3551C12.4677 20.1967 11.9884 19.915 11.5953 19.5431L10.603 20.1161L9.6029 18.384L10.5946 17.8115ZM14 18.5C14.8284 18.5 15.5 17.8284 15.5 17C15.5 16.1716 14.8284 15.5 14 15.5C13.1716 15.5 12.5 16.1716 12.5 17C12.5 17.8284 13.1716 18.5 14 18.5Z" />
  </svg>
);
