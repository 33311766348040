import { DEFAULT_ERROR } from '@/constants/errors';
import { ModelElementsState } from '@/redux/modelElements/modelElements.types';
import { FetchDataState } from '@/types/fetchDataState';
import { ModelElement } from '@/types/models';

export const MODEL_ELEMENTS_INITIAL_STATE_MOCK: ModelElementsState = {};

export const MODEL_ELEMENTS_STATE_INITIAL_MODEL_MOCK: FetchDataState<Array<ModelElement>> = {
  data: [],
  loading: 'idle',
  error: DEFAULT_ERROR,
};
