import type { RootState } from '@/redux/store';
import { Action } from '@reduxjs/toolkit';
import { getModelIdFromAction } from './getModelIdFromAction';

export const checkIfIsMapUpdateActionValid = (action: Action, state: RootState): boolean => {
  const { modelId: currentModelId } = state.map.data;
  const payloadModelId = getModelIdFromAction(action);
  const isModelIdTheSame = currentModelId === payloadModelId;

  return !isModelIdTheSame;
};
