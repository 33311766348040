import { createSlice } from '@reduxjs/toolkit';
import {
  getArrowTypesReducer,
  getLineTypesReducer,
  getShapesReducer,
} from '@/redux/shapes/shapes.reducers';
import { SHAPES_STATE_INITIAL_MOCK } from '@/redux/shapes/shapes.mock';

export const shapesSlice = createSlice({
  name: 'shapes',
  initialState: SHAPES_STATE_INITIAL_MOCK,
  reducers: {},
  extraReducers: builder => {
    getShapesReducer(builder);
    getLineTypesReducer(builder);
    getArrowTypesReducer(builder);
  },
});

export default shapesSlice.reducer;
