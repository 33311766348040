interface PlusIconProps {
  className?: string;
}

export const PlusIcon = ({ className }: PlusIconProps): JSX.Element => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2014_6319)">
      <path d="M6.4165 6.41602V2.91602H7.58317V6.41602H11.0832V7.58268H7.58317V11.0827H6.4165V7.58268H2.9165V6.41602H6.4165Z" />
    </g>
    <defs>
      <clipPath id="clip0_2014_6319">
        <rect width="14" height="14" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
