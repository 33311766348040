// updating state
import { getSearchData } from '@/redux/search/search.thunks';
import { SearchState, SetPerfectMatchAction } from '@/redux/search/search.types';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { SEARCH_INITIAL_STATE } from './search.constants';

export const getSearchDataReducer = (builder: ActionReducerMapBuilder<SearchState>): void => {
  builder.addCase(getSearchData.pending, (state, action) => {
    state.searchValue = action.meta.arg.searchQueries;
    state.perfectMatch = action.meta.arg.isPerfectMatch;
    state.loading = 'pending';
  });
  builder.addCase(getSearchData.fulfilled, state => {
    state.loading = 'succeeded';
  });
  builder.addCase(getSearchData.rejected, state => {
    state.loading = 'failed';
    // TODO: error management to be discussed in the team
  });
};

export const setPerfectMatchReducer = (state: SearchState, action: SetPerfectMatchAction): void => {
  state.perfectMatch = action.payload;
};

export const clearSearchDataReducer = (state: SearchState): void => {
  state.searchValue = SEARCH_INITIAL_STATE.searchValue;
  state.loading = SEARCH_INITIAL_STATE.loading;
};
