import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { StatisticsState } from './statistics.types';
import { getStatisticsById } from './statistics.thunks';

export const getStatisticsByIdReducer = (
  builder: ActionReducerMapBuilder<StatisticsState>,
): void => {
  builder.addCase(getStatisticsById.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getStatisticsById.fulfilled, (state, action) => {
    state.data = action.payload;
    state.loading = 'succeeded';
  });
  builder.addCase(getStatisticsById.rejected, state => {
    state.loading = 'failed';
  });
};
