import { ProjectState } from '@/redux/project/project.types';
import { createSlice } from '@reduxjs/toolkit';
import { getProjectByIdReducer, setProjectIdReducer } from './project.reducers';

const initialState: ProjectState = {
  data: undefined,
  loading: 'idle',
  error: { name: '', message: '' },
  projectId: '',
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getProjectByIdReducer(builder);
    setProjectIdReducer(builder);
  },
});

export default projectSlice.reducer;
