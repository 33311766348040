import { createSlice } from '@reduxjs/toolkit';
import { getAllBackgroundsByProjectIdReducer } from './backgrounds.reducers';
import { BackgroundsState } from './backgrounds.types';

const initialState: BackgroundsState = {
  data: [],
  loading: 'idle',
  error: { name: '', message: '' },
};

const backgroundsState = createSlice({
  name: 'backgrounds',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getAllBackgroundsByProjectIdReducer(builder);
  },
});

export default backgroundsState.reducer;
