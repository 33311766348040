import { FIRST_ARRAY_ELEMENT, SIZE_OF_EMPTY_ARRAY } from '@/constants/common';
import { openReactionDrawerById, selectTab } from '@/redux/drawer/drawer.slice';
import { openMapAndOrSetActiveIfSelected } from '@/redux/map/map.slice';
import { modelsNameMapSelector } from '@/redux/models/models.selectors';
import { getReactionsByIds } from '@/redux/reactions/reactions.thunks';
import type { AppDispatch, store } from '@/redux/store';
import type { BioEntityContent, NewReaction } from '@/types/models';
import getModelElementsIdsFromReaction from '@/components/Map/MapViewer/MapViewerVector/listeners/mouseClick/getModelElementsIdsFromReaction';

interface Args {
  bioEntityContents: BioEntityContent[];
  dispatch: AppDispatch;
  getState: typeof store.getState;
}

type ReactionId = {
  id: number;
  modelId: number;
};

const getReactionsIdsFromBioEntities = (bioEntites: BioEntityContent[]): ReactionId[] => {
  return bioEntites
    .filter(c => c?.bioEntity?.idReaction)
    .filter(c => typeof c?.bioEntity?.id === 'number')
    .map(c => {
      let id: number;
      if (typeof c.bioEntity.id === 'string') {
        id = parseInt(c.bioEntity.id, 10);
      } else {
        id = c.bioEntity.id;
      }
      return { id, modelId: c.bioEntity.model };
    });
};

const fetchReactions = async (
  reactionsIds: ReactionId[],
  dispatch: AppDispatch,
): Promise<NewReaction[]> => {
  const result = await dispatch(
    getReactionsByIds({
      ids: reactionsIds,
      shouldConcat: true,
    }),
  );

  // if it has error (toast show should be handled by getReactionsByIds)
  if (typeof result.payload === 'string') {
    return [];
  }

  const reactions = result.payload?.data;
  if (!reactions) {
    return [];
  }

  return reactions;
};

const handleReactionShowInfoAndOpenMap = async (
  { dispatch, getState }: Args,
  firstReaction: NewReaction,
): Promise<void> => {
  const modelsNames = modelsNameMapSelector(getState());

  dispatch(openReactionDrawerById(firstReaction.id));
  dispatch(selectTab(''));
  dispatch(
    openMapAndOrSetActiveIfSelected({
      modelId: firstReaction.model,
      modelName: modelsNames[firstReaction.model],
    }),
  );
};

export const fetchReactionsAndGetBioEntitiesIds = async (args: Args): Promise<number[]> => {
  const { dispatch, bioEntityContents } = args;

  const bioEntityReactionsIds = getReactionsIdsFromBioEntities(bioEntityContents || []);
  if (bioEntityReactionsIds.length === SIZE_OF_EMPTY_ARRAY) {
    return [];
  }

  const reactions = await fetchReactions(bioEntityReactionsIds, dispatch);
  if (reactions.length === SIZE_OF_EMPTY_ARRAY) {
    return [];
  }

  const bioEntitiesIds = reactions
    .map(reaction => getModelElementsIdsFromReaction(reaction))
    .flat();
  const firstReaction = reactions[FIRST_ARRAY_ELEMENT];
  if (firstReaction) {
    handleReactionShowInfoAndOpenMap(args, firstReaction);
  }

  return bioEntitiesIds;
};
