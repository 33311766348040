import { z } from 'zod';
import { positionSchema } from './positionSchema';
import { colorSchema } from './colorSchema';

export const structuralStateSchema = z.object({
  value: z.string(),
  position: positionSchema,
  z: z.number(),
  width: z.number(),
  height: z.number(),
  fontSize: z.number(),
  size: z.number(),
  center: positionSchema,
  borderColor: colorSchema,
  elementId: z.string(),
});
