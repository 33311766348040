interface ThreeDotsIconProps {
  className?: string;
}

export const ThreeDotsIcon = ({ className }: ThreeDotsIconProps): JSX.Element => (
  <svg
    width="4"
    height="22"
    viewBox="0 0 4 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M-8.74228e-08 11C-1.35705e-07 12.1046 0.89543 13 2 13C3.10457 13 4 12.1046 4 11C4 9.89543 3.10457 9 2 9C0.895431 9 -3.91405e-08 9.89543 -8.74228e-08 11Z"
      fill="#070130"
    />
    <path
      d="M-8.74228e-08 2C-1.35705e-07 3.10457 0.89543 4 2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 -3.91405e-08 2 -8.74228e-08C0.895431 -1.35705e-07 -3.91405e-08 0.89543 -8.74228e-08 2Z"
      fill="#070130"
    />
    <path
      d="M-8.74228e-08 20C-1.35705e-07 21.1046 0.89543 22 2 22C3.10457 22 4 21.1046 4 20C4 18.8954 3.10457 18 2 18C0.895431 18 -3.91405e-08 18.8954 -8.74228e-08 20Z"
      fill="#070130"
    />
  </svg>
);
