import { createSlice } from '@reduxjs/toolkit';
import { COMMENT_INITIAL_STATE } from '@/redux/comment/comment.constants';
import {
  getCommentElementReducer,
  getCommentReactionReducer,
  getCommentsReducer,
  hideCommentsReducer,
  showCommentsReducer,
} from '@/redux/comment/comment.reducers';

export const commentsSlice = createSlice({
  name: 'comments',
  initialState: COMMENT_INITIAL_STATE,
  reducers: {
    showComments: showCommentsReducer,
    hideComments: hideCommentsReducer,
  },
  extraReducers: builder => {
    getCommentsReducer(builder);
    getCommentElementReducer(builder);
    getCommentReactionReducer(builder);
  },
});

export const { showComments, hideComments } = commentsSlice.actions;

export default commentsSlice.reducer;
