import { DEFAULT_ERROR } from '@/constants/errors';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getChemicals, getMultiChemicals } from './chemicals.thunks';
import { ChemicalsState } from './chemicals.types';

export const getChemicalsReducer = (builder: ActionReducerMapBuilder<ChemicalsState>): void => {
  builder.addCase(getChemicals.pending, (state, action) => {
    state.data.push({
      searchQueryElement: action.meta.arg,
      data: undefined,
      loading: 'pending',
      error: DEFAULT_ERROR,
    });
  });
  builder.addCase(getChemicals.fulfilled, (state, action) => {
    const chemicals = state.data.find(chemical => chemical.searchQueryElement === action.meta.arg);
    if (chemicals) {
      chemicals.data = action.payload;
      chemicals.loading = 'succeeded';
    }
  });
  builder.addCase(getChemicals.rejected, (state, action) => {
    const chemicals = state.data.find(chemical => chemical.searchQueryElement === action.meta.arg);
    if (chemicals) {
      chemicals.loading = 'failed';
      // TODO: error management to be discussed in the team
    }
  });
};

export const getMultiChemicalsReducer = (
  builder: ActionReducerMapBuilder<ChemicalsState>,
): void => {
  builder.addCase(getMultiChemicals.pending, state => {
    state.data = [];
    state.loading = 'pending';
  });
  builder.addCase(getMultiChemicals.fulfilled, state => {
    state.loading = 'succeeded';
  });
  builder.addCase(getMultiChemicals.rejected, state => {
    state.loading = 'failed';
    // TODO: error management to be discussed in the team
  });
};

export const clearChemicalsDataReducer = (state: ChemicalsState): void => {
  state.data = [];
  state.loading = 'idle';
};
