interface PluginIconProps {
  className?: string;
}

export const PluginIcon = ({ className }: PluginIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5563 9.30382C15.3111 9.30382 15.0799 9.35382 14.8618 9.4316V5.13715H10.5674C10.6452 4.9191 10.6952 4.68785 10.6952 4.44271C10.6952 4.16912 10.6413 3.89821 10.5366 3.64545C10.4319 3.39269 10.2784 3.16302 10.085 2.96957C9.8915 2.77611 9.66184 2.62266 9.40907 2.51796C9.15631 2.41326 8.8854 2.35938 8.61182 2.35938C8.33823 2.35938 8.06732 2.41326 7.81456 2.51796C7.5618 2.62266 7.33213 2.77611 7.13868 2.96957C6.94522 3.16302 6.79177 3.39269 6.68707 3.64545C6.58237 3.89821 6.52848 4.16912 6.52848 4.44271C6.52848 4.68785 6.57848 4.9191 6.65626 5.13715H2.36182V9.30382C2.6354 9.30382 2.90631 9.35771 3.15907 9.4624C3.41184 9.5671 3.6415 9.72056 3.83496 9.91401C4.02841 10.1075 4.18187 10.3371 4.28657 10.5899C4.39126 10.8427 4.44515 11.1136 4.44515 11.3872C4.44515 11.6607 4.39126 11.9316 4.28657 12.1844C4.18187 12.4372 4.02841 12.6668 3.83496 12.8603C3.6415 13.0537 3.41184 13.2072 3.15907 13.3119C2.90631 13.4166 2.6354 13.4705 2.36182 13.4705V17.6372H6.52848C6.52848 17.0846 6.74798 16.5547 7.13868 16.164C7.52938 15.7733 8.05928 15.5538 8.61182 15.5538C9.16435 15.5538 9.69426 15.7733 10.085 16.164C10.4757 16.5547 10.6952 17.0846 10.6952 17.6372H14.8618V13.3427C15.0799 13.4205 15.3111 13.4705 15.5563 13.4705C15.8298 13.4705 16.1008 13.4166 16.3535 13.3119C16.6063 13.2072 16.8359 13.0537 17.0294 12.8603C17.2229 12.6668 17.3763 12.4372 17.481 12.1844C17.5857 11.9316 17.6396 11.6607 17.6396 11.3872C17.6396 11.1136 17.5857 10.8427 17.481 10.5899C17.3763 10.3371 17.2229 10.1075 17.0294 9.91401C16.8359 9.72056 16.6063 9.5671 16.3535 9.4624C16.1008 9.35771 15.8298 9.30382 15.5563 9.30382V9.30382Z"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
