import { z } from 'zod';
import { publicationSchema } from './publicationsSchema';

export const publicationsResponseSchema = z.object({
  data: z.array(publicationSchema),
  totalSize: z.number(),
  filteredSize: z.number(),
  length: z.number(),
  page: z.number(),
});
