/* eslint-disable no-magic-numbers */

import { getConfigValue, getProjectIdFromUrl } from './index.utils';

export const BASE_API_URL = getConfigValue('BASE_API_URL');
export const BASE_MAP_IMAGES_URL = getConfigValue('BASE_MAP_IMAGES_URL');
export const BASE_NEW_API_URL = getConfigValue('BASE_NEW_API_URL');
export const DEFAULT_PROJECT_ID = getConfigValue('DEFAULT_PROJECT_ID');
export const PROJECT_ID = getProjectIdFromUrl() || DEFAULT_PROJECT_ID;
export const ZOD_SEED = parseInt(process.env.ZOD_SEED || '123', 10);
export const BIO_ENTITY = 'bioEntity';
export const DRUGS_CHEMICALS = ['drugs', 'chemicals'];
export const MINERVA_WEBSITE_URL = 'https://minerva.pages.uni.lu/doc/';
export const ADMIN_PANEL_URL = getConfigValue('ADMIN_PANEL_URL');
export const CURRENT_PROJECT_ADMIN_PANEL_URL = `${ADMIN_PANEL_URL}?id=${PROJECT_ID}`;
