import { createSlice } from '@reduxjs/toolkit';
import {
  addOverlayReducer,
  getAllPublicOverlaysByProjectIdReducer,
  getAllUserOverlaysByCreatorReducer,
  removeOverlayReducer,
  updateOverlaysReducer,
} from './overlays.reducers';
import { OverlaysState } from './overlays.types';

const initialState: OverlaysState = {
  data: [],
  loading: 'idle',
  error: { name: '', message: '' },
  addOverlay: {
    loading: 'idle',
    error: { name: '', message: '' },
  },
  userOverlays: {
    data: [],
    loading: 'idle',
    error: { name: '', message: '' },
  },
  updateOverlays: {
    loading: 'idle',
    error: { name: '', message: '' },
  },
  removeOverlay: {
    loading: 'idle',
    error: { name: '', message: '' },
  },
};

const overlaysState = createSlice({
  name: 'overlays',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getAllPublicOverlaysByProjectIdReducer(builder);
    addOverlayReducer(builder);
    getAllUserOverlaysByCreatorReducer(builder);
    updateOverlaysReducer(builder);
    removeOverlayReducer(builder);
  },
});

export default overlaysState.reducer;
