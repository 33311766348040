import { createSlice } from '@reduxjs/toolkit';
import { CompartmentPathwaysState } from './compartmentPathways.types';
import { getCompartmentPathwaysReducer } from './compartmentPathways.reducers';

export const initialState: CompartmentPathwaysState = {
  loading: 'idle',
  error: { name: '', message: '' },
  data: [],
};

export const compartmentPathwaysSlice = createSlice({
  name: 'compartmentPathways',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getCompartmentPathwaysReducer(builder);
  },
});

export default compartmentPathwaysSlice.reducer;
