import { rootSelector } from '@/redux/root/root.selectors';
import { createSelector } from '@reduxjs/toolkit';

export const mapSelector = createSelector(rootSelector, state => state.map);

export const mapDataSelector = createSelector(mapSelector, map => map.data);

export const mapDataSizeSelector = createSelector(mapDataSelector, map => map.size);

export const mapDataPositionSelector = createSelector(mapDataSelector, map => map.position);

export const mapOpenedMapsSelector = createSelector(mapSelector, state => state.openedMaps);

export const mapModelIdSelector = createSelector(mapDataSelector, map => map.modelId);
export const mapDataInitialPositionSelector = createSelector(
  mapDataPositionSelector,
  position => position.initial,
);

export const mapOpenedMapPositionByIdSelector = createSelector(
  [mapOpenedMapsSelector, (_state, modelId: number): number => modelId],
  (openedMaps, modelId) =>
    openedMaps.find(openedMap => openedMap.modelId === modelId)?.lastPosition,
);

export const mapDataLastPositionSelector = createSelector(
  mapDataPositionSelector,
  position => position.last,
);

export const mapDataLastZoomValue = createSelector(
  mapDataLastPositionSelector,
  position => position.z,
);

export const mapDataMaxZoomValue = createSelector(mapDataSizeSelector, model => model.maxZoom);

export const mapBackgroundTypeSelector = createSelector(mapSelector, map => map.backgroundType);
