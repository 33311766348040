import {
  OverlayBioEntity,
  OverlayElementWithBioEntity,
  OverlayElementWithReaction,
  OverlayLeftBioEntity,
  OverlayLeftReaction,
} from '@/types/models';

export const isReaction = (e: OverlayBioEntity): e is OverlayElementWithReaction =>
  (e.left as OverlayLeftReaction).line !== undefined;

export const isBioEntity = (e: OverlayBioEntity): e is OverlayElementWithBioEntity =>
  (e.left as OverlayLeftBioEntity).x !== undefined &&
  (e.left as OverlayLeftBioEntity).y !== undefined;

export const isSubmapLink = (e: OverlayBioEntity): e is OverlayElementWithBioEntity =>
  (e.left as OverlayLeftBioEntity).x !== undefined &&
  (e.left as OverlayLeftBioEntity).y !== undefined &&
  (e.left as OverlayLeftBioEntity).submodel !== undefined;
