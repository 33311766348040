import { AxiosError } from 'axios';
import { HTTP_ERROR_MESSAGES, UNKNOWN_ERROR } from './getErrorMessage.constants';
import { HttpStatuses } from './getErrorMessage.types';

type Error = { error: string; reason: string };

export const extractAxiosErrorMessage = (error: AxiosError<Error>): string => {
  if (error.response?.data?.reason) {
    return error.response.data.reason;
  }

  const status = error.response?.status as HttpStatuses;
  return HTTP_ERROR_MESSAGES[status] || UNKNOWN_ERROR;
};
