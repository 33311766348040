import { DEFAULT_ERROR } from '@/constants/errors';
import { Loading } from '@/types/loadingState';
import { ConstantMainState } from './constant.types';

const REQUEST_INITIAL_STATUS: { loading: Loading; error: Error } = {
  loading: 'idle',
  error: DEFAULT_ERROR,
};

const MAIN_CONSTANT_INITIAL_STATE: ConstantMainState = {
  data: undefined,
  ...REQUEST_INITIAL_STATUS,
};

export const CONSTANT_INITIAL_STATE = {
  main: MAIN_CONSTANT_INITIAL_STATE,
};

export type ConstantState = typeof CONSTANT_INITIAL_STATE;
