import { FetchDataState } from '@/types/fetchDataState';
import { BioEntity } from '@/types/models';
import { BioEntityContentsState } from './bioEntity.types';

export const DEFAULT_BIOENTITY_PARAMS = {
  perfectMatch: false,
};

export const BIO_ENTITY_FETCHING_ERROR_PREFIX = 'Failed to fetch bio entity';
export const MULTI_BIO_ENTITY_FETCHING_ERROR_PREFIX = 'Failed to fetch multi bio entity';

export const BIOENTITY_SUBMAP_CONNECTIONS_INITIAL_STATE: FetchDataState<BioEntity[]> = {
  data: [],
  loading: 'idle',
  error: { name: '', message: '' },
};

export const BIOENTITY_INITIAL_STATE: BioEntityContentsState = {
  data: [],
  loading: 'idle',
  error: { name: '', message: '' },
  submapConnections: BIOENTITY_SUBMAP_CONNECTIONS_INITIAL_STATE,
  isContentTabOpened: false,
};
