import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import type { RootState } from '../store';

export const dispatchPluginsEvents = (searchQueries: string[], state: RootState): void => {
  const bioEntities = state.bioEntity.data;
  const bioEntitiesResults = bioEntities.map(bioEntity => (bioEntity.data ? bioEntity.data : []));

  const drugs = state.drugs.data;
  const drugsResults = drugs.map(drug => (drug.data ? drug.data : []));

  const chemicals = state.chemicals.data;
  const chemicalsResults = chemicals.map(chemical => (chemical.data ? chemical.data : []));

  PluginsEventBus.dispatchEvent('onSearch', {
    type: 'bioEntity',
    searchValues: searchQueries,
    results: bioEntitiesResults,
  });
  PluginsEventBus.dispatchEvent('onSearch', {
    type: 'drugs',
    searchValues: searchQueries,
    results: drugsResults,
  });
  PluginsEventBus.dispatchEvent('onSearch', {
    type: 'chemicals',
    searchValues: searchQueries,
    results: chemicalsResults,
  });
};
