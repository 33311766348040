interface ArrowIconProps {
  className?: string;
}

export const ArrowIcon = ({ className, ...rest }: ArrowIconProps): JSX.Element => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_2014_6288)">
      <path d="M9.91683 7L5.8335 10.5V3.5L9.91683 7Z" />
    </g>
    <defs>
      <clipPath id="clip0_2014_6288">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
