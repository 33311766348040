import { z } from 'zod';
import { articleSchema } from './articleSchema';

export const referenceSchema = z.object({
  link: z.string().url().nullable(),
  article: articleSchema.optional().nullable(),
  type: z.string(),
  resource: z.string(),
  id: z.number(),
  annotatorClassName: z.string(),
});
