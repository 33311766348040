import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ShapesState } from '@/redux/shapes/shapes.types';
import { getArrowTypes, getLineTypes, getShapes } from '@/redux/shapes/shapes.thunks';

export const getShapesReducer = (builder: ActionReducerMapBuilder<ShapesState>): void => {
  builder.addCase(getShapes.pending, state => {
    state.bioShapesState.loading = 'pending';
  });
  builder.addCase(getShapes.fulfilled, (state, action) => {
    state.bioShapesState.data = action.payload || {};
    state.bioShapesState.loading = 'succeeded';
  });
  builder.addCase(getShapes.rejected, state => {
    state.bioShapesState.loading = 'failed';
  });
};

export const getLineTypesReducer = (builder: ActionReducerMapBuilder<ShapesState>): void => {
  builder.addCase(getLineTypes.pending, state => {
    state.lineTypesState.loading = 'pending';
  });
  builder.addCase(getLineTypes.fulfilled, (state, action) => {
    state.lineTypesState.data = action.payload || {};
    state.lineTypesState.loading = 'succeeded';
  });
  builder.addCase(getLineTypes.rejected, state => {
    state.lineTypesState.loading = 'failed';
  });
};

export const getArrowTypesReducer = (builder: ActionReducerMapBuilder<ShapesState>): void => {
  builder.addCase(getArrowTypes.pending, state => {
    state.arrowTypesState.loading = 'pending';
  });
  builder.addCase(getArrowTypes.fulfilled, (state, action) => {
    state.arrowTypesState.data = action.payload || {};
    state.arrowTypesState.loading = 'succeeded';
  });
  builder.addCase(getArrowTypes.rejected, state => {
    state.arrowTypesState.loading = 'failed';
  });
};
