import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { NewReaction } from '@/types/models';
import { REACTIONS_INITIAL_STATE } from './reactions.constants';
import { getReactionsByIds } from './reactions.thunks';
import { ReactionsState } from './reactions.types';

export const getReactionsReducer = (builder: ActionReducerMapBuilder<ReactionsState>): void => {
  builder.addCase(getReactionsByIds.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getReactionsByIds.fulfilled, (state, action) => {
    const { payload } = action;
    if (!payload) return;

    const newData = payload.shouldConcat ? [...(state.data || []), ...payload.data] : payload.data;
    state.data = newData;
    state.loading = 'succeeded';
  });
  builder.addCase(getReactionsByIds.rejected, state => {
    state.loading = 'failed';
    // TODO: error management to be discussed in the team
  });
};

export const resetReactionsDataReducer = (state: ReactionsState): void => {
  state.data = REACTIONS_INITIAL_STATE.data;
  state.error = REACTIONS_INITIAL_STATE.error;
  state.loading = REACTIONS_INITIAL_STATE.loading;
};

export const setReactionsReducer = (
  state: ReactionsState,
  action: PayloadAction<Array<NewReaction>>,
): void => {
  state.data = action.payload;
  state.loading = 'succeeded';
};
