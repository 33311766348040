import { createSlice } from '@reduxjs/toolkit';
import { LAYERS_STATE_INITIAL_MOCK } from '@/redux/layers/layers.mock';
import {
  getLayersForModelReducer,
  setLayerVisibilityReducer,
} from '@/redux/layers/layers.reducers';

export const layersSlice = createSlice({
  name: 'layers',
  initialState: LAYERS_STATE_INITIAL_MOCK,
  reducers: {
    setLayerVisibility: setLayerVisibilityReducer,
  },
  extraReducers: builder => {
    getLayersForModelReducer(builder);
  },
});

export const { setLayerVisibility } = layersSlice.actions;

export default layersSlice.reducer;
