import { createSlice } from '@reduxjs/toolkit';
import { ExportState } from './export.types';
import { downloadNetworkReducer, downloadElementsReducer } from './export.reducers';

const initialState: ExportState = {
  downloadNetwork: {
    error: {
      message: '',
      name: '',
    },
    loading: 'idle',
  },
  downloadElements: {
    error: {
      message: '',
      name: '',
    },
    loading: 'idle',
  },
};

const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {},
  extraReducers: builder => {
    downloadNetworkReducer(builder);
    downloadElementsReducer(builder);
  },
});

export default exportSlice.reducer;
