import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { projectSchema } from '@/models/projectSchema';
import { PageOf, Project } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { PROJECTS_FETCHING_ERROR_PREFIX } from '@/redux/projects/projects.constants';
import { pageableSchema } from '@/models/pageableSchema';
import { apiPath } from '../apiPath';

export const getProjects = createAsyncThunk<Project[], void, ThunkConfig>(
  'project/getProjects',
  async () => {
    try {
      const response = await axiosInstanceNewAPI.get<PageOf<Project>>(apiPath.getProjects());

      const isDataValid = validateDataUsingZodSchema(response.data, pageableSchema(projectSchema));

      return isDataValid ? response.data.content : [];
    } catch (error) {
      return Promise.reject(getError({ error, prefix: PROJECTS_FETCHING_ERROR_PREFIX }));
    }
  },
);
