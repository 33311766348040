import { DrugsState } from '@/redux/drugs/drugs.types';
import { createSlice } from '@reduxjs/toolkit';
import { clearDrugsDataReducer, getDrugsReducer, getMultiDrugsReducer } from './drugs.reducers';

const initialState: DrugsState = {
  data: [],
  loading: 'idle',
  error: { name: '', message: '' },
};

export const drugsSlice = createSlice({
  name: 'drugs',
  initialState,
  reducers: {
    clearDrugsData: clearDrugsDataReducer,
  },
  extraReducers: builder => {
    getDrugsReducer(builder);
    getMultiDrugsReducer(builder);
  },
});

export const { clearDrugsData } = drugsSlice.actions;

export default drugsSlice.reducer;
