import { bioEntityResponseSchema } from '@/models/bioEntityResponseSchema';
import { apiPath } from '@/redux/apiPath';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { BioEntity, BioEntityContent, BioEntityResponse } from '@/types/models';
import { IdSearchQuery, PerfectSearchParams } from '@/types/search';
import { ThunkConfig } from '@/types/store';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getError } from '@/utils/error-report/getError';
import { getCommentElement } from '@/redux/comment/thunks/getComments';
import { addNumbersToEntityNumberData } from '../../entityNumber/entityNumber.slice';
import { BIO_ENTITY_FETCHING_ERROR_PREFIX } from '../bioEntity.constants';

type GetBioEntityProps = PerfectSearchParams;

export const getBioEntity = createAsyncThunk<
  BioEntityContent[] | undefined,
  GetBioEntityProps,
  ThunkConfig
>(
  'project/getBioEntityContents',
  async ({ searchQuery, isPerfectMatch, addNumbersToEntityNumber = true }, { dispatch }) => {
    try {
      const response = await axiosInstanceNewAPI.get<BioEntityResponse>(
        apiPath.getBioEntityContentsStringWithQuery({ searchQuery, isPerfectMatch }),
      );

      const isDataValidBioEnity = validateDataUsingZodSchema(
        response.data,
        bioEntityResponseSchema,
      );

      if (addNumbersToEntityNumber && response.data.content) {
        const bioEntityIds = response.data.content.map(b => b.bioEntity.elementId);
        dispatch(addNumbersToEntityNumberData(bioEntityIds));
      }

      return isDataValidBioEnity ? response.data.content : undefined;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: BIO_ENTITY_FETCHING_ERROR_PREFIX }));
    }
  },
);

type GetBioEntityByIdProps = IdSearchQuery;
type GetBioEntityByIdAction = PayloadAction<BioEntity | null>; // if error thrown, string containing error message is returned

export const getBioEntityById = createAsyncThunk<
  BioEntity | null,
  GetBioEntityByIdProps,
  ThunkConfig
>(
  'project/getBioEntityById',
  async ({ elementId, modelId, type, addNumbersToEntityNumber }, { dispatch }) => {
    try {
      if (type === 'ALIAS') {
        const elementAction = (await dispatch(
          getCommentElement({ elementId, modelId }),
        )) as GetBioEntityByIdAction;

        const element = elementAction.payload;

        if (addNumbersToEntityNumber && element) {
          dispatch(addNumbersToEntityNumberData([element.elementId]));
        }

        return element;
      }
      throw new Error('Not implemented');
    } catch (error) {
      return Promise.reject(getError({ error, prefix: BIO_ENTITY_FETCHING_ERROR_PREFIX }));
    }
  },
);
