import { z } from 'zod';
import { lineSchema } from '@/models/lineSchema';
import { reactionProduct } from '@/models/reactionProduct';
import { referenceSchema } from '@/models/referenceSchema';
import { operatorSchema } from '@/models/operatorSchema';

export const newReactionSchema = z.object({
  id: z.number().int().positive(),
  abbreviation: z.string().nullable(),
  elementId: z.string(),
  formula: z.string().nullable(),
  geneProteinReaction: z.string().nullable(),
  idReaction: z.string(),
  kinetics: z.null(),
  line: lineSchema,
  lowerBound: z.number().nullable(),
  mechanicalConfidenceScore: z.number().int().nullable(),
  model: z.number(),
  modifiers: z.array(reactionProduct),
  name: z.string(),
  notes: z.string(),
  operators: z.array(operatorSchema),
  processCoordinates: z.null(),
  products: z.array(reactionProduct),
  reactants: z.array(reactionProduct),
  references: z.array(referenceSchema),
  reversible: z.boolean(),
  sboTerm: z.string(),
  subsystem: z.null(),
  symbol: z.null(),
  synonyms: z.array(z.unknown()),
  upperBound: z.null(),
  visibilityLevel: z.string(),
  z: z.number(),
});
