import { z } from 'zod';
import { colorSchema } from './colorSchema';
import { geneVariant } from './geneVariant';

export const overlayRightBioEntitySchema = z.object({
  id: z.number(),
  name: z.string(),
  modelName: z.boolean().nullable(),
  elementId: z.string().nullable(),
  reverseReaction: z.boolean().nullable(),
  lineWidth: z.number().nullable().optional(),
  value: z.number().nullable(),
  color: colorSchema.nullable(),
  description: z.string().nullable(),
  geneVariants: z.array(geneVariant).optional().nullable(),
});
