import { chemicalSchema } from '@/models/chemicalSchema';
import { drugSchema } from '@/models/drugSchema';
import { targetSearchNameResult } from '@/models/targetSearchNameResult';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { Chemical, Drug, TargetSearchNameResult } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { apiPath } from '../apiPath';
import {
  CHEMICALS_FOR_BIO_ENTITY_FETCHING_ERROR_PREFIX,
  DRUGS_FOR_BIO_ENTITY_FETCHING_ERROR_PREFIX,
} from './drawer.constants';

const QUERY_COLUMN_NAME = 'name';

const getDrugsNamesForTarget = async (target: string): Promise<TargetSearchNameResult[]> => {
  const response = await axiosInstanceNewAPI.get<TargetSearchNameResult[]>(
    apiPath.getDrugsStringWithColumnsTarget(QUERY_COLUMN_NAME, target),
  );

  const isDataValid = (obj: unknown): obj is TargetSearchNameResult =>
    validateDataUsingZodSchema(obj, targetSearchNameResult);

  return response.data.filter(isDataValid);
};

const getDrugsByName = async (drugName: string): Promise<Drug[]> => {
  const response = await axiosInstanceNewAPI.get<Drug[]>(apiPath.getDrugsStringWithQuery(drugName));

  const isDataValid = (obj: unknown): obj is Drug => validateDataUsingZodSchema(obj, drugSchema);

  return response.data.filter(isDataValid);
};

export const getDrugsForBioEntityDrawerTarget = createAsyncThunk<Drug[], string, ThunkConfig>(
  'drawer/getDrugsForBioEntityDrawerTarget',
  async target => {
    try {
      const drugsNames = await getDrugsNamesForTarget(target);
      const drugsArrays = await Promise.all(
        drugsNames.map(({ name }) => getDrugsByName(encodeURIComponent(name))),
      );
      const drugs = drugsArrays.flat();

      return drugs;
    } catch (error) {
      return Promise.reject(
        getError({ error, prefix: DRUGS_FOR_BIO_ENTITY_FETCHING_ERROR_PREFIX }),
      );
    }
  },
);

const getChemicalsNamesForTarget = async (target: string): Promise<TargetSearchNameResult[]> => {
  const response = await axiosInstanceNewAPI.get<TargetSearchNameResult[]>(
    apiPath.getChemicalsStringWithColumnsTarget(QUERY_COLUMN_NAME, target),
  );

  const isDataValid = (obj: unknown): obj is TargetSearchNameResult =>
    validateDataUsingZodSchema(obj, targetSearchNameResult);

  return response.data.filter(isDataValid);
};

const getChemicalsByName = async (chemicalName: string): Promise<Chemical[]> => {
  const response = await axiosInstanceNewAPI.get<Chemical[]>(
    apiPath.getChemicalsStringWithQuery(chemicalName),
  );

  const isDataValid = (obj: unknown): obj is Chemical =>
    validateDataUsingZodSchema(obj, chemicalSchema);

  return response.data.filter(isDataValid);
};

export const getChemicalsForBioEntityDrawerTarget = createAsyncThunk<
  Chemical[],
  string,
  ThunkConfig
>('drawer/getChemicalsForBioEntityDrawerTarget', async target => {
  try {
    const chemicalsNames = await getChemicalsNamesForTarget(target);
    const chemicalsArrays = await Promise.all(
      chemicalsNames.map(({ name }) => getChemicalsByName(encodeURIComponent(name))),
    );
    const chemicals = chemicalsArrays.flat();

    return chemicals;
  } catch (error) {
    return Promise.reject(
      getError({ error, prefix: CHEMICALS_FOR_BIO_ENTITY_FETCHING_ERROR_PREFIX }),
    );
  }
});
