/* eslint-disable no-magic-numbers */
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { getModels } from './models.thunks';
import { ModelsState } from './models.types';

export const getModelsReducer = (builder: ActionReducerMapBuilder<ModelsState>): void => {
  builder.addCase(getModels.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getModels.fulfilled, (state, action) => {
    state.data = action.payload || [];
    state.loading = 'succeeded';
  });
  builder.addCase(getModels.rejected, state => {
    state.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};

export const setModelVectorRenderingReducer = (
  state: ModelsState,
  action: PayloadAction<{ vectorRendering: boolean; mapId: number }>,
): void => {
  const { payload } = action;
  const modelIndex = state.data.findIndex(model => model.idObject === payload.mapId);
  if (modelIndex !== -1) {
    state.data[modelIndex].vectorRendering = payload.vectorRendering;
  }
};
