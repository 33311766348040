export const DEFAULT_ERROR: Error = { message: '', name: '' };

export const OVERVIEW_IMAGE_ERRORS = {
  IMAGE_ID_IS_INVALID: "Image id is invalid. There's no such image in overview images list",
};

export const ZOOM_ERRORS = {
  ZOOM_VALUE_TOO_HIGH: (maxZoom: number): string =>
    `Provided zoom value exeeds max zoom of ${maxZoom}`,
  ZOOM_VALUE_TOO_LOW: (minZoom: number): string =>
    `Provided zoom value exceeds min zoom of ${minZoom}`,
};

export const MOLART_ERRORS = {
  UNEXPECTED_MOLART_ERROR: 'UNEXPECTED_MOLART_ERROR',
};
