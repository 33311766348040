import { z } from 'zod';
import { positionSchema } from './positionSchema';

export const overviewImageLinkImage = z.object({
  id: z.number(),
  polygon: z.array(positionSchema),
  linkedOverviewImage: z.number(),
  // type: z.string(),
});

export const overviewImageLinkModel = z.object({
  id: z.number(),
  polygon: z.array(positionSchema),
  zoomLevel: z.number(),
  xCoord: z.number(),
  yCoord: z.number(),
  linkedModel: z.number(),
  // type: z.string(),
});

export const overviewImageLinkSearch = z.object({
  id: z.number(),
  idObject: z.number(),
  polygon: z.array(positionSchema),
  query: z.string(),
  type: z.string(),
});

export const overviewImageLink = z.union([
  overviewImageLinkImage,
  overviewImageLinkModel,
  overviewImageLinkSearch,
]);
