import { rootSelector } from '@/redux/root/root.selectors';
import { createSelector } from '@reduxjs/toolkit';
import { MODEL_ID_DEFAULT } from '../map/map.constants';
import { mapDataSelector } from '../map/map.selectors';

export const modelsSelector = createSelector(rootSelector, state => state.models);

export const modelsDataSelector = createSelector(modelsSelector, models => models?.data || []);

export const currentModelSelector = createSelector(
  modelsDataSelector,
  mapDataSelector,
  (models, mapData) => models.find(model => model.idObject === mapData.modelId),
);

export const modelsIdsSelector = createSelector(modelsDataSelector, models =>
  models.map(model => model.idObject),
);

export const modelsNameMapSelector = createSelector(modelsDataSelector, models =>
  models.reduce(
    (acc, model) => ({ ...acc, [model.idObject]: model.name }),
    {} as Record<number, string>,
  ),
);

export const modelsIdsAndNamesSelector = createSelector(modelsDataSelector, models =>
  models.map(({ idObject, name }) => ({ id: idObject, name })),
);

export const currentModelIdSelector = createSelector(
  currentModelSelector,
  model => model?.idObject || MODEL_ID_DEFAULT,
);

export const lastClickSelector = createSelector(mapDataSelector, mapData => mapData.lastClick);
export const lastRightClickSelector = createSelector(
  mapDataSelector,
  mapData => mapData.lastRightClick,
);

export const currentModelNameSelector = createSelector(
  currentModelSelector,
  model => model?.name || '',
);

export const modelByIdSelector = createSelector(
  [modelsSelector, (_state, modelId: number): number => modelId],
  (models, modelId) => (models?.data || []).find(({ idObject }) => idObject === modelId),
);

const MAIN_MAP = 0;
export const mainMapModelSelector = createSelector(modelsDataSelector, models => models[MAIN_MAP]);

export const loadingModelsSelector = createSelector(modelsSelector, state => state.loading);

export const mainMapModelDescriptionSelector = createSelector(
  modelsDataSelector,
  models => models[MAIN_MAP].description,
);

export const vectorRenderingSelector = createSelector(
  currentModelSelector,
  model => model?.vectorRendering || false,
);
