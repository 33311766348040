import { PluginsState } from './plugins.types';

export const PLUGINS_INITIAL_STATE: PluginsState = {
  list: {
    data: [],
    loading: 'idle',
    error: { name: '', message: '' },
  },
  activePlugins: {
    data: {},
    pluginsId: [],
  },
  drawer: {
    isOpen: false,
    currentPluginHash: undefined,
  },
};

export const PLUGIN_REGISTER_ERROR_PREFIX = 'Failed to register plugin';
export const PLUGIN_INIT_FETCHING_ERROR_PREFIX = 'Failed to initialize fetching plugin';
export const PLUGIN_MINERVA_VERSION_NOT_SUPPORTED = 'Minerva version not supported';
export const PLUGIN_FETCHING_ALL_ERROR_PREFIX = 'Failed to fetch all plugins';
