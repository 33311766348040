import { z } from 'zod';
import { lineSchema } from './lineSchema';
import { reactionProduct } from './reactionProduct';
import { referenceSchema } from './referenceSchema';

export const overlayLeftReactionSchema = z.object({
  id: z.number(),
  notes: z.string(),
  idReaction: z.string(),
  name: z.string(),
  reversible: z.boolean(),
  symbol: z.null(),
  abbreviation: z.null(),
  formula: z.null(),
  mechanicalConfidenceScore: z.null(),
  lowerBound: z.null(),
  upperBound: z.null(),
  subsystem: z.null(),
  geneProteinReaction: z.null(),
  visibilityLevel: z.string().nullable(),
  z: z.number(),
  synonyms: z.array(z.unknown()),
  model: z.number(),
  kinetics: z.null(),
  line: lineSchema,
  processCoordinates: z.null(),
  modifiers: z.array(reactionProduct),
  reactants: z.array(reactionProduct),
  products: z.array(reactionProduct),
  elementId: z.string(),
  operators: z.array(z.unknown()),
  references: z.array(referenceSchema),
  sboTerm: z.string(),
});
