export const SIZE_OF_EMPTY_ARRAY = 0;
export const SIZE_OF_ARRAY_WITH_FOUR_ELEMENTS = 4;
export const SIZE_OF_ARRAY_WITH_ONE_ELEMENT = 1;

export const MINUS_ONE = -1;

export const ZERO = 0;
export const FIRST_ARRAY_ELEMENT = 0;

export const ONE = 1;
export const SECOND_ARRAY_ELEMENT = 1;

export const TWO = 2;

export const FIVE = 5;

export const THIRD_ARRAY_ELEMENT = 2;

export const NOOP = (): void => {};

export const ONE_DECIMAL = 0.1;
export const ONE_HUNDRED = 100;
export const ONE_THOUSAND = 1000;

export const EMPTY_ARRAY_STRING = '[]';
export const ZOOM_FACTOR = 2.0; // Zoom factor indicating doubling the distance for each zoom level

export const ENTER_KEY_CODE = 'Enter';
