/* eslint-disable no-magic-numbers */
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { getLayersForModel } from '@/redux/layers/layers.thunks';
import { LayersState } from '@/redux/layers/layers.types';
import {
  LAYER_STATE_DEFAULT_DATA,
  LAYERS_STATE_INITIAL_LAYER_MOCK,
} from '@/redux/layers/layers.mock';
import { DEFAULT_ERROR } from '@/constants/errors';

export const getLayersForModelReducer = (builder: ActionReducerMapBuilder<LayersState>): void => {
  builder.addCase(getLayersForModel.pending, (state, action) => {
    const modelId = action.meta.arg;
    if (state[modelId]) {
      state[modelId].loading = 'pending';
    } else {
      state[modelId] = { ...LAYERS_STATE_INITIAL_LAYER_MOCK, loading: 'pending' };
    }
  });
  builder.addCase(getLayersForModel.fulfilled, (state, action) => {
    const modelId = action.meta.arg;
    const data = action.payload || { ...LAYER_STATE_DEFAULT_DATA };
    if (state[modelId]) {
      state[modelId].data = data;
      state[modelId].loading = 'succeeded';
    } else {
      state[modelId] = { data, loading: 'pending', error: DEFAULT_ERROR };
    }
  });
  builder.addCase(getLayersForModel.rejected, (state, action) => {
    const modelId = action.meta.arg;
    if (state[modelId]) {
      state[modelId].loading = 'failed';
    } else {
      state[modelId] = { ...LAYERS_STATE_INITIAL_LAYER_MOCK, loading: 'failed' };
    }
  });
};

export const setLayerVisibilityReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; visible: boolean; layerId: number }>,
): void => {
  const { modelId, visible, layerId } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  if (data.layersVisibility[layerId] !== undefined) {
    data.layersVisibility[layerId] = visible;
  }
};
