import { createSlice } from '@reduxjs/toolkit';
import { CONFIGURATION_INITIAL_STATE } from './configuration.adapter';
import { getConfigurationOptionsReducer, getConfigurationReducer } from './configuration.reducers';

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState: CONFIGURATION_INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    getConfigurationOptionsReducer(builder);
    getConfigurationReducer(builder);
  },
});

export default configurationSlice.reducer;
