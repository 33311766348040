import { store } from '@/redux/store';
import { MapInstanceProvider } from '@/utils/context/mapInstanceContext';
import { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { Toaster } from 'sonner';

interface AppWrapperProps {
  children: ReactNode;
}

export const AppWrapper = ({ children }: AppWrapperProps): JSX.Element => {
  return (
    <MapInstanceProvider>
      <Provider store={store}>
        <>
          <Toaster
            position="top-center"
            visibleToasts={1}
            style={{
              width: '700px',
            }}
          />
          {children}
        </>
      </Provider>
    </MapInstanceProvider>
  );
};
