import type { AppListenerEffectAPI, AppStartListening } from '@/redux/store';
import { Action, createListenerMiddleware, isRejected } from '@reduxjs/toolkit';
import { createErrorData } from '@/utils/error-report/errorReporting';
import { openAccessDeniedModal, openErrorReportModal } from '@/redux/modal/modal.slice';
import { getProjects } from '@/redux/projects/projects.thunks';
import axios from 'axios';
import { AXIOS_ERROR_NETWORK } from '@/utils/getErrorMessage/getErrorMessage.constants';
import { showToast } from '@/utils/showToast';

export const errorListenerMiddleware = createListenerMiddleware();

const startListening = errorListenerMiddleware.startListening as AppStartListening;

export const errorMiddlewareListener = async (
  action: Action,
  { getState, dispatch }: AppListenerEffectAPI,
): Promise<void> => {
  if (isRejected(action) && action.type !== 'user/getSessionValid/rejected') {
    if (action.error.code === '403') {
      dispatch(getProjects());
      dispatch(openAccessDeniedModal());
    } else if (axios.isAxiosError(action.error) && action.error.code === AXIOS_ERROR_NETWORK) {
      // eslint-disable-next-line no-console
      console.log(action.error);
      showToast({
        type: 'error',
        message:
          'There was a problem with fetching data from minerva server. ' +
          'Please check your internet connection and try again. ' +
          'If problem problem persists contact system administrator.',
        duration: 15000,
      });
    } else {
      const errorData = await createErrorData(action.error, getState());
      dispatch(openErrorReportModal(errorData));
    }
  }
};

startListening({
  matcher: isRejected,
  effect: errorMiddlewareListener,
});
