import { FetchDataState } from '@/types/fetchDataState';
import { CommentsState } from '@/redux/comment/comment.types';

export const COMMENT_SUBMAP_CONNECTIONS_INITIAL_STATE: FetchDataState<Comment[]> = {
  data: [],
  loading: 'idle',
  error: { name: '', message: '' },
};

export const COMMENT_INITIAL_STATE: CommentsState = {
  data: [],
  loading: 'idle',
  error: { name: '', message: '' },
  isOpen: false,
  commentElement: null,
  commentReaction: null,
};
