import { z } from 'zod';
import { lineSchema } from './lineSchema';

export const operatorSchema = z.object({
  id: z.number(),
  line: lineSchema,
  inputs: z.array(z.object({ id: z.number() })),
  outputs: z.array(z.object({ id: z.number() })),
  operatorText: z.string(),
  reactantOperator: z.boolean(),
  productOperator: z.boolean(),
  modifierOperator: z.boolean(),
});
