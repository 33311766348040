import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ProjectsState } from '@/redux/projects/projects.types';
import { getProjects } from '@/redux/projects/projects.thunks';

export const getProjectsReducer = (builder: ActionReducerMapBuilder<ProjectsState>): void => {
  builder.addCase(getProjects.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getProjects.fulfilled, (state, action) => {
    state.data = action.payload || undefined;
    state.loading = 'succeeded';
  });
  builder.addCase(getProjects.rejected, state => {
    state.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};
