import { z } from 'zod';

export const configurationOptionSchema = z.object({
  idObject: z.number(),
  type: z.string(),
  valueType: z.string(),
  commonName: z.string(),
  isServerSide: z.boolean(),
  value: z.string().optional(),
  group: z.string(),
});
