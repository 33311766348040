import { createSlice } from '@reduxjs/toolkit';
import { OauthState } from '@/redux/oauth/oauth.types';
import { getOauthReducer } from '@/redux/oauth/oauth.reducers';

const initialState: OauthState = {
  data: undefined,
  loading: 'idle',
  error: { name: '', message: '' },
  orcidEndpoint: undefined,
};

const oauthSlice = createSlice({
  name: 'oauth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getOauthReducer(builder);
  },
});

export default oauthSlice.reducer;
