export const UNKNOWN_ERROR = 'An unknown error occurred. Please try again later.';
export const UNKNOWN_AXIOS_ERROR_CODE = 'UNKNOWN_AXIOS_ERROR';
export const NOT_FOUND_AXIOS_ERROR_CODE = '404';
export const GENERIC_AXIOS_ERROR_CODE = 'ERR_BAD_REQUEST';
export const AXIOS_ERROR_NETWORK = 'ERR_NETWORK';

export const HTTP_ERROR_MESSAGES = {
  400: "The server couldn't understand your request. Please check your input and try again.",
  401: "You're not authorized to access this resource. Please log in or check your credentials.",
  403: "Access Forbidden! You don't have permission to access this resource.",
  404: "The page you're looking for doesn't exist. Please verify the URL and try again.",
  500: 'Unexpected server error. Please try again later or contact support.',
  501: 'Sorry, this feature is not yet implemented. Please try again later.',
  503: 'Service Unavailable! The server is currently down for maintenance. Please try again later.',
};
