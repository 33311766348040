import { axiosInstance } from '@/services/api/utils/axiosInstance';
import { Statistics } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { statisticsSchema } from '@/models/statisticsSchema';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { apiPath } from '../apiPath';
import { STATISTICS_FETCHING_ERROR_PREFIX } from './statistics.constants';

export const getStatisticsById = createAsyncThunk<Statistics | undefined, string, ThunkConfig>(
  'statistics/getStatisticsById',
  async id => {
    try {
      const response = await axiosInstance.get<Statistics>(apiPath.getStatisticsById(id));

      const isDataValid = validateDataUsingZodSchema(response.data, statisticsSchema);

      return isDataValid ? response.data : undefined;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: STATISTICS_FETCHING_ERROR_PREFIX }));
    }
  },
);
