export const MIN_COLOR_VAL_NAME_ID = 'MIN_COLOR_VAL';
export const MAX_COLOR_VAL_NAME_ID = 'MAX_COLOR_VAL';
export const SIMPLE_COLOR_VAL_NAME_ID = 'SIMPLE_COLOR_VAL';
export const NEUTRAL_COLOR_VAL_NAME_ID = 'NEUTRAL_COLOR_VAL';
export const OVERLAY_OPACITY_NAME_ID = 'OVERLAY_OPACITY';
export const SEARCH_DISTANCE_NAME_ID = 'SEARCH_DISTANCE';
export const REQUEST_ACCOUNT_EMAIL = 'REQUEST_ACCOUNT_EMAIL';
export const TERMS_OF_SERVICE_ID = 'TERMS_OF_USE';
export const MATOMO_URL = 'MATOMO_URL';
export const COOKIE_POLICY_URL = 'COOKIE_POLICY_URL';
export const LEFT_LOGO_IMG = 'LEFT_LOGO_IMG';
export const LEFT_LOGO_LINK = 'LEFT_LOGO_LINK';
export const LEFT_LOGO_TEXT = 'LEFT_LOGO_TEXT';
export const RIGHT_LOGO_IMG = 'RIGHT_LOGO_IMG';
export const RIGHT_LOGO_LINK = 'RIGHT_LOGO_LINK';
export const RIGHT_LOGO_TEXT = 'RIGHT_LOGO_TEXT';

export const LEGEND_FILE_NAMES_IDS = [
  'LEGEND_FILE_1',
  'LEGEND_FILE_2',
  'LEGEND_FILE_3',
  'LEGEND_FILE_4',
];

export const GPML_HANDLER_NAME_ID = 'GPML';
export const SBML_HANDLER_NAME_ID = 'SBML';
export const CELL_DESIGNER_SBML_HANDLER_NAME_ID = 'CellDesigner SBML';
export const SBGN_ML_HANDLER_NAME_ID = 'SBGN-ML';

export const PNG_IMAGE_HANDLER_NAME_ID = 'PNG image';
export const PDF_HANDLER_NAME_ID = 'PDF';
export const SVG_IMAGE_HANDLER_NAME_ID = 'SVG image';

export const CONFIGURATION_OPTIONS_FETCHING_ERROR_PREFIX = 'Failed to fetch configuration options';

export const CONFIGURATION_FETCHING_ERROR_PREFIX = 'Failed to fetch configuration';
