import { createSelector } from '@reduxjs/toolkit';
import { rootSelector } from '../root/root.selectors';

export const overlaysSelector = createSelector(rootSelector, state => state.overlays);

export const overlaysDataSelector = createSelector(
  overlaysSelector,
  overlays => overlays?.data || [],
);

export const overlaysIdsAndOrderSelector = createSelector(overlaysDataSelector, overlays =>
  overlays.map(({ idObject, order }) => ({ idObject, order })),
);

export const overlaySelector = createSelector(
  [overlaysDataSelector, (_, overlayId: number): number => overlayId],
  (overlays, overlayId) => overlays.find(overlay => overlay.idObject === overlayId),
);

export const loadingAddOverlay = createSelector(
  overlaysSelector,
  state => state.addOverlay.loading,
);

const userOverlaysSelector = createSelector(overlaysSelector, overlays => overlays.userOverlays);

export const loadingUserOverlaysSelector = createSelector(
  userOverlaysSelector,
  state => state.loading,
);

export const userOverlaysDataSelector = createSelector(
  userOverlaysSelector,
  overlays => overlays.data,
);

export const userOverlaysIdsAndOrderSelector = createSelector(
  userOverlaysDataSelector,
  userOverlays => userOverlays?.map(({ idObject, order }) => ({ idObject, order })) || [],
);

export const userOverlaySelector = createSelector(
  [userOverlaysDataSelector, (_, userOverlayId: number): number => userOverlayId],
  (userOverlays, userOverlayId) =>
    userOverlays?.find(userOverlay => userOverlay.idObject === userOverlayId),
);
