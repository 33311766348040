type ConfigKeys = keyof typeof window.config;

export const getConfigValue = (key: ConfigKeys): string => {
  const defaultValue = '';
  if (typeof window !== 'undefined' && window.config && window.config[key]) {
    return window.config[key];
  }
  return defaultValue;
};

export const getProjectIdFromUrl = (): string | null | undefined => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);

    return params.get('id');
  }

  return undefined;
};
