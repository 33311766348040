import { createSlice } from '@reduxjs/toolkit';
import { getNewReactionsReducer } from '@/redux/newReactions/newReactions.reducers';
import { NEW_REACTIONS_INITIAL_STATE_MOCK } from '@/redux/newReactions/newReactions.mock';

export const newReactionsSlice = createSlice({
  name: 'reactions',
  initialState: NEW_REACTIONS_INITIAL_STATE_MOCK,
  reducers: {},
  extraReducers: builder => {
    getNewReactionsReducer(builder);
  },
});

export default newReactionsSlice.reducer;
