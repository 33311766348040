import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ConstantState } from './constant.adapter';
import { getConstant } from './constant.thunks';

export const getConstantReducer = (builder: ActionReducerMapBuilder<ConstantState>): void => {
  builder.addCase(getConstant.pending, state => {
    state.main.loading = 'pending';
  });
  builder.addCase(getConstant.fulfilled, (state, action) => {
    state.main.loading = 'succeeded';
    state.main.data = action.payload;
  });
  builder.addCase(getConstant.rejected, state => {
    state.main.loading = 'failed';
  });
};
