const PLUGINS_EVENTS = {
  overlay: {
    onAddDataOverlay: 'onAddDataOverlay',
    onRemoveDataOverlay: 'onRemoveDataOverlay',
    onShowOverlay: 'onShowOverlay',
    onHideOverlay: 'onHideOverlay',
  },
  background: {
    onBackgroundOverlayChange: 'onBackgroundOverlayChange',
  },
  submap: {
    onSubmapOpen: 'onSubmapOpen',
    onSubmapClose: 'onSubmapClose',
    onZoomChanged: 'onZoomChanged',
    onCenterChanged: 'onCenterChanged',
    onBioEntityClick: 'onBioEntityClick',
    onPinIconClick: 'onPinIconClick',
    onSurfaceClick: 'onSurfaceClick',
  },
  search: {
    onSearch: 'onSearch',
  },
  plugins: {
    onPluginUnload: 'onPluginUnload',
  },
};

export const ALLOWED_PLUGINS_EVENTS = Object.values(PLUGINS_EVENTS).flatMap(obj =>
  Object.values(obj),
);

export const LISTENER_NOT_FOUND = -1;

export const LOCAL_LISTENER_ID = 'local';
