import { getErrorMessage } from '@/utils/getErrorMessage';
import { SerializedError } from '@reduxjs/toolkit';
import { getErrorName } from '@/utils/error-report/getErrorName';
import { getErrorCode } from '@/utils/error-report/getErrorCode';
import { getErrorStack } from '@/utils/error-report/getErrorStack';

type GetErrorMessageConfig = {
  error: unknown;
  message?: string;
  prefix?: string;
};

export const getError = ({ error, message, prefix }: GetErrorMessageConfig): SerializedError => {
  const errorMessage = getErrorMessage({ error, message, prefix });

  const name = getErrorName(error);
  const stack = getErrorStack(error);
  const code = getErrorCode(error);

  return {
    name,
    message: errorMessage,
    stack,
    code,
  };
};
