import { chemicalSchema } from '@/models/chemicalSchema';
import { apiPath } from '@/redux/apiPath';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { Chemical } from '@/types/models';
import { ThunkConfig } from '@/types/store';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { z } from 'zod';
import { getError } from '@/utils/error-report/getError';
import { addNumbersToEntityNumberData } from '../entityNumber/entityNumber.slice';
import {
  CHEMICALS_FETCHING_ERROR_PREFIX,
  MULTI_CHEMICALS_FETCHING_ERROR_PREFIX,
} from './chemicals.constants';

export const getChemicals = createAsyncThunk<Chemical[] | undefined, string, ThunkConfig>(
  'project/getChemicals',
  async searchQuery => {
    try {
      const response = await axiosInstanceNewAPI.get<Chemical[]>(
        apiPath.getChemicalsStringWithQuery(searchQuery),
      );

      const isDataValid = validateDataUsingZodSchema(response.data, z.array(chemicalSchema));

      return isDataValid ? response.data : undefined;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: CHEMICALS_FETCHING_ERROR_PREFIX }));
    }
  },
);

export const getMultiChemicals = createAsyncThunk<void, string[], ThunkConfig>(
  'project/getMultChemicals',
  // eslint-disable-next-line consistent-return
  async (searchQueries, { dispatch }) => {
    try {
      const asyncGetChemicalsFunctions = searchQueries.map(searchQuery =>
        dispatch(getChemicals(searchQuery)),
      );

      const chemicalsDataActions = await Promise.all(asyncGetChemicalsFunctions);

      const chemicalsTargetsData = chemicalsDataActions
        .map(chemicalsDataAction =>
          typeof chemicalsDataAction.payload === 'string' ? [] : chemicalsDataAction.payload || [],
        )
        .flat()
        .map(drug => drug.targets)
        .flat()
        .map(target => target.targetElements)
        .flat();

      const chemicalsIds = chemicalsTargetsData.map(d => d.elementId);
      dispatch(addNumbersToEntityNumberData(chemicalsIds));
    } catch (error) {
      return Promise.reject(getError({ error, prefix: MULTI_CHEMICALS_FETCHING_ERROR_PREFIX }));
    }
  },
);
