import { z } from 'zod';

export const elementTypeSchema = z.object({
  className: z.string(),
  name: z.string(),
  parentClass: z.string(),
});

export const optionSchema = z.object({
  idObject: z.number(),
  type: z.string(),
  valueType: z.string(),
  commonName: z.string(),
  isServerSide: z.boolean(),
  group: z.string(),
  value: z.string().optional(),
});

export const formatSchema = z.object({
  name: z.string(),
  handler: z.string(),
  extension: z.string(),
});

export const overlayTypeSchema = z.object({ name: z.string() });

export const reactionTypeSchema = z.object({
  className: z.string(),
  name: z.string(),
  parentClass: z.string(),
});

export const miriamTypesSchema = z.record(
  z.string(),
  z.object({
    commonName: z.string(),
    homepage: z.string().nullable(),
    registryIdentifier: z.string().nullable(),
    uris: z.array(z.string()),
  }),
);

export const bioEntityFieldSchema = z.object({ commonName: z.string(), name: z.string() });

export const annotatorSchema = z.object({
  className: z.string(),
  name: z.string(),
  description: z.string(),
  url: z.string(),
  elementClassNames: z.array(z.string()),
  parameters: z.array(
    z.object({
      field: z.string().nullable().optional(),
      annotation_type: z.string().nullable().optional(),
      order: z.number(),
      type: z.string(),
    }),
  ),
});

export const privilegeTypeSchema = z.record(
  z.string(),
  z.object({
    commonName: z.string(),
    objectType: z.string().nullable(),
    valueType: z.string(),
  }),
);

export const mapTypeSchema = z.object({ name: z.string(), id: z.string() });

export const unitTypeSchema = z.object({ name: z.string(), id: z.string() });

export const modificationStateTypeSchema = z.record(
  z.string(),
  z.object({ commonName: z.string(), abbreviation: z.string() }),
);

export const configurationSchema = z.object({
  elementTypes: z.array(elementTypeSchema),
  options: z.array(optionSchema),
  imageFormats: z.array(formatSchema),
  modelFormats: z.array(formatSchema),
  overlayTypes: z.array(overlayTypeSchema),
  reactionTypes: z.array(reactionTypeSchema),
  miriamTypes: miriamTypesSchema,
  bioEntityFields: z.array(bioEntityFieldSchema),
  version: z.string(),
  buildDate: z.string(),
  gitHash: z.string(),
  annotators: z.array(annotatorSchema),
  privilegeTypes: privilegeTypeSchema,
  mapTypes: z.array(mapTypeSchema),
  unitTypes: z.array(unitTypeSchema),
  modificationStateTypes: modificationStateTypeSchema,
});
