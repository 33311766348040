import { getMultiBioEntity } from '@/redux/bioEntity/bioEntity.thunks';
import { getMultiChemicals } from '@/redux/chemicals/chemicals.thunks';
import { getMultiDrugs } from '@/redux/drugs/drugs.thunks';
import { PerfectMultiSearchParams } from '@/types/search';
import { ThunkConfig } from '@/types/store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getError } from '@/utils/error-report/getError';
import { resetReactionsData } from '../reactions/reactions.slice';
import type { RootState } from '../store';
import { DATA_SEARCHING_ERROR_PREFIX } from './search.constants';
import { dispatchPluginsEvents } from './search.thunks.utils';

type GetSearchDataProps = PerfectMultiSearchParams;

export const getSearchData = createAsyncThunk<
  void,
  GetSearchDataProps,
  { state: RootState } & ThunkConfig
>(
  'project/getSearchData',
  // eslint-disable-next-line consistent-return
  async ({ searchQueries, isPerfectMatch }, { dispatch, getState }) => {
    try {
      dispatch(resetReactionsData());

      const project = getState().project.data;
      let containsDisease = false;
      if (project) {
        containsDisease = project.disease !== undefined && project.disease !== null;
      }
      if (containsDisease) {
        await Promise.all([
          dispatch(getMultiBioEntity({ searchQueries, isPerfectMatch })),
          dispatch(getMultiDrugs(searchQueries)),
          dispatch(getMultiChemicals(searchQueries)),
        ]);
      } else {
        await Promise.all([
          dispatch(getMultiBioEntity({ searchQueries, isPerfectMatch })),
          dispatch(getMultiDrugs(searchQueries)),
        ]);
      }

      dispatchPluginsEvents(searchQueries, getState());
    } catch (error) {
      return Promise.reject(getError({ error, prefix: DATA_SEARCHING_ERROR_PREFIX }));
    }
  },
);
