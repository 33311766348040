import { LatLng, Point } from '@/types/map';
import { z } from 'zod';
import { HALF_SECOND_MS, ONE_HUNDRED_MS } from './time';

export const DEFAULT_TILE_SIZE = 256;
export const DEFAULT_MIN_ZOOM = 2;
export const DEFAULT_MAX_ZOOM = 8;
export const DEFAULT_ZOOM = 4;
export const DEFAULT_CENTER_X = 0;
export const DEFAULT_CENTER_Y = 0;
// eslint-disable-next-line no-magic-numbers
export const LATLNG_FALLBACK: LatLng = [0, 0];
export const EXTENT_PADDING_MULTIPLICATOR = 1;
export const DEFAULT_EXTENT_PADDING = 20;
export const ZOOM_RESCALING_FACTOR = 1;

export const DEFAULT_CENTER_POINT: Point = {
  x: DEFAULT_CENTER_X,
  y: DEFAULT_CENTER_Y,
  z: DEFAULT_ZOOM,
};

export const OPTIONS = {
  showFullExtent: false,
  wrapXInTileLayer: false,
  queryPersistTime: HALF_SECOND_MS,
  clickPersistTime: ONE_HUNDRED_MS,
};

export const VALID_MAP_SIZE_SCHEMA = z.object({
  width: z.number().nonnegative(),
  height: z.number().nonnegative(),
  tileSize: z.number().nonnegative(),
  minZoom: z.number().nonnegative(),
  maxZoom: z.number().nonnegative(),
});
