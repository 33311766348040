import { currentBackgroundSelector } from '@/redux/backgrounds/background.selectors';
import type { AppListenerEffectAPI, AppStartListening } from '@/redux/store';
import { getUpdatedMapData } from '@/utils/map/getUpdatedMapData';
import { Action, createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { mapOpenedMapPositionByIdSelector } from '../map.selectors';
import {
  closeMapAndSetMainMapActive,
  openMapAndOrSetActiveIfSelected,
  openMapAndSetActive,
  setActiveMap,
  setMapBackground,
  setMapData,
  setMapPosition,
} from '../map.slice';
import { checkIfIsMapUpdateActionValid } from './checkIfIsMapUpdateActionValid';
import { getUpdatedModel } from './getUpdatedModel';

export const mapListenerMiddleware = createListenerMiddleware();

const startListening = mapListenerMiddleware.startListening as AppStartListening;

export const mapDataMiddlewareListener = async (
  action: Action,
  { getOriginalState, dispatch }: AppListenerEffectAPI,
): Promise<void> => {
  const state = getOriginalState();
  const updatedModel = getUpdatedModel(action, state);
  const isActionValid = checkIfIsMapUpdateActionValid(action, state);

  if (!updatedModel || !isActionValid) {
    return;
  }

  const background = currentBackgroundSelector(state);
  const modelId = updatedModel.idObject;
  const lastPosition = mapOpenedMapPositionByIdSelector(state, modelId);
  const updatedMapData = getUpdatedMapData({
    model: updatedModel,
    position: { initial: lastPosition, last: lastPosition },
    background,
  });
  dispatch(setMapData(updatedMapData));
  dispatch(setMapPosition(updatedMapData.position.initial));
};

startListening({
  matcher: isAnyOf(
    setMapData,
    setActiveMap,
    openMapAndSetActive,
    closeMapAndSetMainMapActive,
    setMapBackground,
    openMapAndOrSetActiveIfSelected,
  ),
  effect: mapDataMiddlewareListener,
});
