import { z } from 'zod';
import { apiPath } from '@/redux/apiPath';
import { Layer, Layers } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { layerSchema } from '@/models/layerSchema';
import { LAYERS_FETCHING_ERROR_PREFIX } from '@/redux/layers/layers.constants';
import { LayersVisibilitiesState } from '@/redux/layers/layers.types';
import { layerTextSchema } from '@/models/layerTextSchema';
import { layerRectSchema } from '@/models/layerRectSchema';
import { pageableSchema } from '@/models/pageableSchema';
import { layerOvalSchema } from '@/models/layerOvalSchema';
import { layerLineSchema } from '@/models/layerLineSchema';

export const getLayersForModel = createAsyncThunk<
  LayersVisibilitiesState | undefined,
  number,
  ThunkConfig
>('vectorMap/getLayers', async (modelId: number) => {
  try {
    const { data } = await axiosInstanceNewAPI.get<Layers>(apiPath.getLayers(modelId));
    const isDataValid = validateDataUsingZodSchema(data, pageableSchema(layerSchema));
    if (!isDataValid) {
      return undefined;
    }
    let layers = await Promise.all(
      data.content.map(async (layer: Layer) => {
        const [textsResponse, rectsResponse, ovalsResponse, linesResponse] = await Promise.all([
          axiosInstanceNewAPI.get(apiPath.getLayerTexts(modelId, layer.id)),
          axiosInstanceNewAPI.get(apiPath.getLayerRects(modelId, layer.id)),
          axiosInstanceNewAPI.get(apiPath.getLayerOvals(modelId, layer.id)),
          axiosInstanceNewAPI.get(apiPath.getLayerLines(modelId, layer.id)),
        ]);

        return {
          details: layer,
          texts: textsResponse.data.content,
          rects: rectsResponse.data.content,
          ovals: ovalsResponse.data.content,
          lines: linesResponse.data.content,
        };
      }),
    );
    layers = layers.filter(layer => {
      return (
        z.array(layerTextSchema).safeParse(layer.texts).success &&
        z.array(layerRectSchema).safeParse(layer.rects).success &&
        z.array(layerOvalSchema).safeParse(layer.ovals).success &&
        z.array(layerLineSchema).safeParse(layer.lines).success
      );
    });
    const layersVisibility = layers.reduce((acc: { [key: string]: boolean }, layer) => {
      acc[layer.details.id] = layer.details.visible;
      return acc;
    }, {});
    return {
      layers,
      layersVisibility,
    };
  } catch (error) {
    return Promise.reject(getError({ error, prefix: LAYERS_FETCHING_ERROR_PREFIX }));
  }
});
