import { PayloadAction } from '@reduxjs/toolkit';
import { LegendState, PluginId } from './legend.types';
import { DEFAULT_LEGEND_ID } from './legend.constants';

export const openLegendReducer = (state: LegendState): void => {
  state.isOpen = true;
};

export const closeLegendReducer = (state: LegendState): void => {
  state.isOpen = false;
};

export const setActiveLegendIdReducer = (
  state: LegendState,
  action: PayloadAction<string>,
): void => {
  state.activeLegendId = action.payload;
};

export const setDefaultLegendIdReducer = (state: LegendState): void => {
  state.activeLegendId = DEFAULT_LEGEND_ID;
};

export const setPluginLegendReducer = (
  state: LegendState,
  {
    payload,
  }: PayloadAction<{
    pluginId: PluginId;
    legend: string[];
  }>,
): void => {
  state.pluginLegend = {
    ...state.pluginLegend,
    [payload.pluginId]: payload.legend,
  };
};

export const removePluginLegendReducer = (
  state: LegendState,
  { payload }: PayloadAction<string>,
): void => {
  if (state.pluginLegend[payload]) {
    delete state.pluginLegend[payload];
  }
};
