import { z } from 'zod';
import { overviewImageLink } from './overviewImageLink';

export const overviewImageView = z.object({
  id: z.number(),
  filename: z.string(),
  width: z.number(),
  height: z.number(),
  links: z.array(overviewImageLink),
});
