import { z } from 'zod';

export const authorSchema = z.union([
  z.string(),
  z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    organisation: z.string(),
  }),
]);
