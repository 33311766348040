interface ReloadIconProps {
  className?: string;
}

export const ReloadIcon = ({ className }: ReloadIconProps): JSX.Element => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.64253 2.45404C3.85223 1.40581 5.39985 0.829805 7.00053 0.832038C10.6825 0.832038 13.6672 3.8167 13.6672 7.4987C13.6672 8.9227 13.2205 10.2427 12.4605 11.3254L10.3339 7.4987H12.3339C12.3339 6.45312 12.0267 5.43057 11.4503 4.55821C10.8739 3.68584 10.0538 3.00214 9.09198 2.59212C8.13015 2.1821 7.069 2.06384 6.0405 2.25205C5.01199 2.44026 4.0615 2.92664 3.3072 3.6507L2.64253 2.45404ZM11.3585 12.5434C10.1488 13.5916 8.6012 14.1676 7.00053 14.1654C3.31853 14.1654 0.333862 11.1807 0.333862 7.4987C0.333862 6.0747 0.780529 4.7547 1.54053 3.67204L3.6672 7.4987H1.6672C1.66711 8.54429 1.97436 9.56684 2.55075 10.4392C3.12714 11.3116 3.94724 11.9953 4.90908 12.4053C5.87091 12.8153 6.93205 12.9336 7.96056 12.7454C8.98906 12.5571 9.93956 12.0708 10.6939 11.3467L11.3585 12.5434Z"
      fill="#4091F4"
    />
  </svg>
);
