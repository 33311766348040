import { STEP } from '@/constants/searchDrawer';
import type {
  DrawerState,
  OpenBioEntityDrawerByIdAction,
  OpenCommentDrawerByIdAction,
  OpenReactionDrawerByIdAction,
  OpenSearchDrawerWithSelectedTabReducerAction,
} from '@/redux/drawer/drawer.types';
import type { DrawerName } from '@/types/drawerName';
import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import {
  getChemicalsForBioEntityDrawerTarget,
  getDrugsForBioEntityDrawerTarget,
} from './drawer.thunks';

export const openDrawerReducer = (state: DrawerState, action: PayloadAction<DrawerName>): void => {
  state.isOpen = true;
  state.drawerName = action.payload;
};

export const openSearchDrawerWithSelectedTabReducer = (
  state: DrawerState,
  action: OpenSearchDrawerWithSelectedTabReducerAction,
): void => {
  state.isOpen = true;
  state.drawerName = 'search';
  state.searchDrawerState.currentStep = STEP.FIRST;
  state.searchDrawerState.selectedSearchElement = action.payload;
};

export const openSubmapsDrawerReducer = (state: DrawerState): void => {
  state.isOpen = true;
  state.drawerName = 'submaps';
};

export const openOverlaysDrawerReducer = (state: DrawerState): void => {
  state.isOpen = true;
  state.drawerName = 'overlays';
  state.overlayDrawerState.currentStep = STEP.FIRST;
};

export const displayAddOverlaysDrawerReducer = (state: DrawerState): void => {
  state.isOpen = true;
  state.drawerName = 'overlays';
  state.overlayDrawerState.currentStep = STEP.SECOND;
};

export const selectTabReducer = (
  state: DrawerState,
  action: OpenSearchDrawerWithSelectedTabReducerAction,
): void => {
  state.searchDrawerState.currentStep = STEP.FIRST;
  state.searchDrawerState.selectedSearchElement = action.payload;
};

export const closeDrawerReducer = (state: DrawerState): void => {
  state.isOpen = false;
  state.drawerName = 'none';
  state.searchDrawerState.currentStep = STEP.FIRST;
  state.searchDrawerState.selectedValue = undefined;
  state.searchDrawerState.stepType = 'none';
};

export const displayDrugsListReducer = (state: DrawerState): void => {
  state.drawerName = 'search';
  state.searchDrawerState.currentStep = STEP.SECOND;
  state.searchDrawerState.stepType = 'drugs';
};

export const displayChemicalsListReducer = (state: DrawerState): void => {
  state.drawerName = 'search';
  state.searchDrawerState.currentStep = STEP.SECOND;
  state.searchDrawerState.stepType = 'chemicals';
};

export const displayBioEntitiesListReducer = (
  state: DrawerState,
  action: PayloadAction<DrawerState['searchDrawerState']['listOfBioEnitites']>,
): void => {
  state.drawerName = 'search';
  state.searchDrawerState.currentStep = STEP.SECOND;
  state.searchDrawerState.listOfBioEnitites = action.payload;
  state.searchDrawerState.stepType = 'bioEntity';
};

export const displayGroupedSearchResultsReducer = (state: DrawerState): void => {
  state.searchDrawerState.currentStep = STEP.FIRST;
  state.searchDrawerState.stepType = 'none';
};

export const displayEntityDetailsReducer = (
  state: DrawerState,
  action: PayloadAction<DrawerState['searchDrawerState']['selectedValue']>,
): void => {
  state.searchDrawerState.currentStep = STEP.THIRD;
  state.searchDrawerState.selectedValue = action.payload;
};

export const openReactionDrawerByIdReducer = (
  state: DrawerState,
  action: OpenReactionDrawerByIdAction,
): void => {
  state.isOpen = true;
  state.drawerName = 'reaction';
  state.reactionDrawerState.reactionId = action.payload;
};

export const openBioEntityDrawerByIdReducer = (
  state: DrawerState,
  action: OpenBioEntityDrawerByIdAction,
): void => {
  state.isOpen = true;
  state.drawerName = 'bio-entity';
  state.bioEntityDrawerState.bioentityId = action.payload;
};

export const openCommentDrawerByIdReducer = (
  state: DrawerState,
  action: OpenCommentDrawerByIdAction,
): void => {
  state.isOpen = true;
  state.drawerName = 'comment';
  state.commentDrawerState.commentId = action.payload;
};

export const getBioEntityDrugsForTargetReducers = (
  builder: ActionReducerMapBuilder<DrawerState>,
): void => {
  builder.addCase(getDrugsForBioEntityDrawerTarget.pending, state => {
    const bioEntityId = state.bioEntityDrawerState.bioentityId || '';
    state.bioEntityDrawerState.drugs[bioEntityId] = {
      ...state.bioEntityDrawerState.drugs[bioEntityId],
      loading: 'pending',
    };
  });
  builder.addCase(getDrugsForBioEntityDrawerTarget.fulfilled, (state, action) => {
    const bioEntityId = state.bioEntityDrawerState.bioentityId || '';
    state.bioEntityDrawerState.drugs[bioEntityId] = {
      ...state.bioEntityDrawerState.drugs[bioEntityId],
      data: action.payload || [],
      loading: 'succeeded',
    };
  });
  builder.addCase(getDrugsForBioEntityDrawerTarget.rejected, state => {
    const bioEntityId = state.bioEntityDrawerState.bioentityId || '';
    state.bioEntityDrawerState.drugs[bioEntityId] = {
      ...state.bioEntityDrawerState.drugs[bioEntityId],
      loading: 'failed',
    };
    // TODO to discuss manage state of failure
  });
};

export const getBioEntityChemicalsForTargetReducers = (
  builder: ActionReducerMapBuilder<DrawerState>,
): void => {
  builder.addCase(getChemicalsForBioEntityDrawerTarget.pending, state => {
    const bioEntityId = state.bioEntityDrawerState.bioentityId || '';
    state.bioEntityDrawerState.chemicals[bioEntityId] = {
      ...state.bioEntityDrawerState.chemicals[bioEntityId],
      loading: 'pending',
    };
  });
  builder.addCase(getChemicalsForBioEntityDrawerTarget.fulfilled, (state, action) => {
    const bioEntityId = state.bioEntityDrawerState.bioentityId || '';
    state.bioEntityDrawerState.chemicals[bioEntityId] = {
      ...state.bioEntityDrawerState.chemicals[bioEntityId],
      data: action.payload || [],
      loading: 'succeeded',
    };
  });
  builder.addCase(getChemicalsForBioEntityDrawerTarget.rejected, state => {
    const bioEntityId = state.bioEntityDrawerState.bioentityId || '';
    state.bioEntityDrawerState.chemicals[bioEntityId] = {
      ...state.bioEntityDrawerState.chemicals[bioEntityId],
      loading: 'failed',
    };
    // TODO to discuss manage state of failure
  });
};
