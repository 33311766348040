import { createSlice } from '@reduxjs/toolkit';
import { REACTIONS_INITIAL_STATE } from './reactions.constants';
import {
  getReactionsReducer,
  resetReactionsDataReducer,
  setReactionsReducer,
} from './reactions.reducers';

export const reactionsSlice = createSlice({
  name: 'reactions',
  initialState: REACTIONS_INITIAL_STATE,
  reducers: {
    resetReactionsData: resetReactionsDataReducer,
    setReactions: setReactionsReducer,
  },
  extraReducers: builder => {
    getReactionsReducer(builder);
  },
});

export const { resetReactionsData, setReactions } = reactionsSlice.actions;

export default reactionsSlice.reducer;
