import { z } from 'zod';

export const geneVariant = z.object({
  position: z.number(),
  originalDna: z.string(),
  modifiedDna: z.string(),
  contig: z.string(),
  allelFrequency: z.number(),
  aminoAcidChange: z.string(),
  variantIdentifier: z.string(),
});
