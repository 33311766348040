import { PROJECT_ID } from '@/constants';
import {
  DEFAULT_CENTER_POINT,
  DEFAULT_MAX_ZOOM,
  DEFAULT_MIN_ZOOM,
  DEFAULT_TILE_SIZE,
} from '@/constants/map';
import { Point } from '@/types/map';
import MapBackgroundsEnum from '@/redux/map/map.enums';
import { MapData, MapState, OppenedMap } from './map.types';

export const MAIN_MAP = 'Main map';

export const MAP_BACKGROUND_TYPES = [
  { id: MapBackgroundsEnum.NETWORK, name: 'Network' },
  { id: MapBackgroundsEnum.SEMANTIC, name: 'Semantic' },
];

export const MODEL_ID_DEFAULT: number = 0;

export const BACKGROUND_ID_DEFAULT: number = 0;

export const OVERVIEW_IMAGE_ID_DEFAULT: number = 0;

export const MOL_ART_UNIPROT_ID_DEFAULT: string = '';

export const MAP_DATA_INITIAL_STATE: MapData = {
  projectId: PROJECT_ID,
  meshId: '',
  modelId: MODEL_ID_DEFAULT,
  backgroundId: BACKGROUND_ID_DEFAULT,
  overlaysIds: [],
  position: {
    last: DEFAULT_CENTER_POINT,
    initial: DEFAULT_CENTER_POINT,
  },
  show: {
    legend: false,
    comments: false,
  },
  size: {
    width: 0,
    height: 0,
    tileSize: DEFAULT_TILE_SIZE,
    minZoom: DEFAULT_MIN_ZOOM,
    maxZoom: DEFAULT_MAX_ZOOM,
  },
  lastClick: {
    modelId: MODEL_ID_DEFAULT,
    position: {
      x: 0,
      y: 0,
    },
  },
  lastRightClick: {
    modelId: MODEL_ID_DEFAULT,
    position: {
      x: 0,
      y: 0,
    },
  },
};
export const DEFAULT_POSITION: Point = { x: 0, y: 0, z: 0 };

export const OPENED_MAPS_INITIAL_STATE: OppenedMap[] = [
  { modelId: MODEL_ID_DEFAULT, modelName: MAIN_MAP, lastPosition: DEFAULT_POSITION },
];

export const MIDDLEWARE_ALLOWED_ACTIONS: string[] = ['map/setMapData'];

export const MAP_INITIAL_STATE: MapState = {
  data: MAP_DATA_INITIAL_STATE,
  loading: 'idle',
  error: { name: '', message: '' },
  openedMaps: OPENED_MAPS_INITIAL_STATE,
  backgroundType: MapBackgroundsEnum.SEMANTIC,
};

export const INIT_MAP_SIZE_MODEL_ID_ERROR_PREFIX = 'Failed to initialize map size and model ID';

export const INIT_MAP_POSITION_ERROR_PREFIX = 'Failed to initialize map position';

export const INIT_MAP_BACKGROUND_ERROR_PREFIX = 'Failed to initialize map background';

export const INIT_OPENED_MAPS_ERROR_PREFIX = 'Failed to initialize opened maps';
