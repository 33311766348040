interface PageIconProps {
  className?: string;
}

export const PageIcon = ({ className }: PageIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2028_6398)">
      <path d="M3.50002 6.81797L7.83552 2.66797H15.7764C16.175 2.66797 16.5 2.98268 16.5 3.3541V15.8152C16.4998 15.9972 16.4242 16.1717 16.2897 16.3004C16.1552 16.429 15.9729 16.5013 15.7829 16.5013H4.21718C4.12234 16.5007 4.02856 16.4822 3.94118 16.4468C3.85381 16.4115 3.77457 16.36 3.70797 16.2953C3.64138 16.2306 3.58874 16.154 3.55305 16.0699C3.51737 15.9857 3.49935 15.8956 3.50002 15.8048V6.81797ZM8.55557 4.0513V7.50964H4.94446V15.118H15.0556V4.0513H8.55557Z" />
    </g>
    <defs>
      <clipPath id="clip0_2028_6398">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
