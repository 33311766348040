import { z } from 'zod';
import { bioEntitySchema } from './bioEntitySchema';
import { referenceSchema } from './referenceSchema';
import { targetParticipantSchema } from './targetParticipantSchema';

export const targetSchema = z.object({
  /** target name */
  name: z.string(),
  /** list of target references */
  references: z.array(referenceSchema),
  /**  list of elements on the map associated with this target */
  targetElements: z.array(bioEntitySchema),
  /** list of identifiers associated with this target */
  targetParticipants: z.array(targetParticipantSchema),
});
