import { ChemicalsState } from '@/redux/chemicals/chemicals.types';
import { createSlice } from '@reduxjs/toolkit';
import {
  clearChemicalsDataReducer,
  getChemicalsReducer,
  getMultiChemicalsReducer,
} from './chemicals.reducers';

const initialState: ChemicalsState = {
  data: [],
  loading: 'idle',
  error: { name: '', message: '' },
};

export const chemicalsSlice = createSlice({
  name: 'chemicals',
  initialState,
  reducers: {
    clearChemicalsData: clearChemicalsDataReducer,
  },
  extraReducers: builder => {
    getChemicalsReducer(builder);
    getMultiChemicalsReducer(builder);
  },
});

export const { clearChemicalsData } = chemicalsSlice.actions;

export default chemicalsSlice.reducer;
