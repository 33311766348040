import { commentSchema } from '@/models/commentSchema';
import { apiPath } from '@/redux/apiPath';
import { axiosInstance, axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { ThunkConfig } from '@/types/store';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { BioEntity, Comment, NewReaction } from '@/types/models';
import { z } from 'zod';
import { bioEntitySchema } from '@/models/bioEntitySchema';
import { GetElementProps } from '@/redux/comment/comment.types';
import { getError } from '@/utils/error-report/getError';
import { newReactionSchema } from '@/models/newReactionSchema';

export const getComments = createAsyncThunk<Comment[], void, ThunkConfig>(
  'project/getComments',
  async () => {
    try {
      const response = await axiosInstance.get<Comment[]>(apiPath.getComments());

      const isDataValid = validateDataUsingZodSchema(response.data, z.array(commentSchema));

      return isDataValid ? response.data : [];
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);

export const getCommentElement = createAsyncThunk<BioEntity | null, GetElementProps, ThunkConfig>(
  'project/getCommentElement',
  async ({ elementId, modelId }) => {
    try {
      const response = await axiosInstanceNewAPI.get<BioEntity>(
        apiPath.getElementById(elementId, modelId),
      );

      const isDataValid = validateDataUsingZodSchema(response.data, bioEntitySchema);

      return isDataValid ? response.data : null;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);

export const getCommentReaction = createAsyncThunk<
  NewReaction | null,
  GetElementProps,
  ThunkConfig
>('project/getCommentReaction', async ({ elementId, modelId }) => {
  try {
    const response = await axiosInstanceNewAPI.get<NewReaction>(
      apiPath.getNewReaction(modelId, elementId),
    );

    const isDataValid = validateDataUsingZodSchema(response.data, z.array(newReactionSchema));

    return isDataValid ? response.data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});
