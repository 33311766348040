interface ChevronLeftIconProps {
  className?: string;
}

export const ChevronLeftIcon = ({ className }: ChevronLeftIconProps): JSX.Element => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2005_6475)">
      <path d="M6.31611 6.9986L9.20361 9.8861L8.37878 10.7109L4.66645 6.9986L8.37878 3.28627L9.20361 4.1111L6.31611 6.9986Z" />
    </g>
    <defs>
      <clipPath id="clip0_2005_6475">
        <rect width="14" height="14" fill="white" transform="translate(14 14) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
);
