import { PROJECT_ID } from '@/constants';
import { Point } from '@/types/map';
import { PerfectSearchParams } from '@/types/search';
import { GetPublicationsParams, PublicationsQueryParams } from './publications/publications.types';

const getPublicationsURLSearchParams = (
  providedParams: PublicationsQueryParams,
): URLSearchParams => {
  const params = new URLSearchParams();

  const validProvidedParamsArray = Object.entries(providedParams).filter(([, value]) =>
    Boolean(value),
  );

  validProvidedParamsArray.forEach(([key, value]) => {
    params.append(key, value.toString());
  });

  return params;
};

export const apiPath = {
  getElementById: (elementId: number, modelId: number): string =>
    `projects/${PROJECT_ID}/models/${modelId}/bioEntities/elements/${elementId}`,

  getBioEntityContentsStringWithQuery: ({
    searchQuery,
    isPerfectMatch,
  }: PerfectSearchParams): string =>
    `projects/${PROJECT_ID}/models/*/bioEntities/:search?query=${searchQuery}&size=1000&perfectMatch=${isPerfectMatch}`,
  getSingleBioEntityContentsStringWithCoordinates: (
    { x, y }: Point,
    currentModelId: number,
  ): string => {
    const coordinates = [x, y].join();
    return `projects/${PROJECT_ID}/models/${currentModelId}/bioEntities:search/?coordinates=${coordinates}&count=1`;
  },
  getReactionsWithIds: (ids: number[]): string =>
    `projects/${PROJECT_ID}/models/*/bioEntities/reactions/?id=${ids.join(',')}&size=1000`,
  getDrugsStringWithQuery: (searchQuery: string): string =>
    `projects/${PROJECT_ID}/drugs:search?query=${searchQuery}`,
  getDrugsStringWithColumnsTarget: (columns: string, target: string): string =>
    `projects/${PROJECT_ID}/drugs:search?columns=${columns}&target=${target}`,
  getChemicalsStringWithColumnsTarget: (columns: string, target: string): string =>
    `projects/${PROJECT_ID}/chemicals:search?columns=${columns}&target=${target}`,
  getModelsString: (): string => `projects/${PROJECT_ID}/models/`,
  getModelElementsForModel: (modelId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/bioEntities/elements/?size=10000`,
  getShapes: (): string => `projects/${PROJECT_ID}/shapes/`,
  getLineTypes: (): string => `projects/${PROJECT_ID}/lineTypes/`,
  getArrowTypes: (): string => `projects/${PROJECT_ID}/arrowTypes/`,
  getLayers: (modelId: number): string => `projects/${PROJECT_ID}/maps/${modelId}/layers/`,
  getLayerTexts: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/texts/`,
  getLayerRects: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/rects/`,
  getLayerOvals: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/ovals/`,
  getLayerLines: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/lines/`,
  getGlyphImage: (glyphId: number): string =>
    `projects/${PROJECT_ID}/glyphs/${glyphId}/fileContent`,
  getNewReactionsForModel: (modelId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/bioEntities/reactions/?size=10000`,
  getNewReaction: (modelId: number, reactionId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/bioEntities/reactions/${reactionId}`,
  getChemicalsStringWithQuery: (searchQuery: string): string =>
    `projects/${PROJECT_ID}/chemicals:search?query=${searchQuery}`,
  getAllOverlaysByProjectIdQuery: (
    projectId: string,
    { publicOverlay }: { publicOverlay: boolean },
  ): string => `projects/${projectId}/overlays/?publicOverlay=${String(publicOverlay)}`,
  getAllBackgroundsByProjectIdQuery: (projectId: string): string =>
    `projects/${projectId}/backgrounds/`,
  getProjectById: (projectId: string): string => `projects/${projectId}`,
  getProjects: (): string => `projects/`,
  getSessionValid: (): string => `users/isSessionValid`,
  postLogin: (): string => `doLogin`,
  getConfigurationOptions: (): string => 'configuration/options/',
  getConfiguration: (): string => 'configuration/',
  getOverlayBioEntity: ({ overlayId, modelId }: { overlayId: number; modelId: number }): string =>
    `projects/${PROJECT_ID}/overlays/${overlayId}/models/${modelId}/bioEntities/`,
  createOverlay: (projectId: string): string => `projects/${projectId}/overlays/`,
  createOverlayFile: (): string => `files/`,
  uploadOverlayFileContent: (fileId: number): string => `files/${fileId}:uploadContent`,
  getStatisticsById: (projectId: string): string => `projects/${projectId}/statistics/`,
  getCompartmentPathwaysIds: (objectId: number): string =>
    `projects/${PROJECT_ID}/models/${objectId}/bioEntities/elements/?columns=id&type=Compartment,Pathway`,
  getCompartmentPathwayDetails: (ids: number[]): string =>
    `projects/${PROJECT_ID}/models/*/bioEntities/elements/?id=${ids.join(',')}`,
  sendCompartmentPathwaysIds: (): string => `projects/${PROJECT_ID}/models/*/bioEntities/elements/`,
  downloadNetworkCsv: (): string =>
    `projects/${PROJECT_ID}/models/*/bioEntities/reactions/:downloadCsv`,
  getAllUserOverlaysByCreatorQuery: ({
    publicOverlay,
    creator,
  }: {
    publicOverlay: boolean;
    creator: string;
  }): string =>
    `projects/${PROJECT_ID}/overlays/?creator=${creator}&publicOverlay=${String(publicOverlay)}`,
  updateOverlay: (overlayId: number): string => `projects/${PROJECT_ID}/overlays/${overlayId}/`,
  removeOverlay: (overlayId: number): string => `projects/${PROJECT_ID}/overlays/${overlayId}/`,
  downloadElementsCsv: (): string =>
    `projects/${PROJECT_ID}/models/*/bioEntities/elements/:downloadCsv`,
  downloadOverlay: (overlayId: number): string =>
    `projects/${PROJECT_ID}/overlays/${overlayId}:downloadSource`,
  getSourceFile: (): string => `/projects/${PROJECT_ID}:downloadSource`,
  getMesh: (meshId: string): string => `mesh/${meshId}`,
  getTaxonomy: (taxonomyId: string): string => `taxonomy/${taxonomyId}`,
  getPublications: ({ params, modelId = '*' }: GetPublicationsParams): string =>
    `/projects/${PROJECT_ID}/models/${modelId}/publications/?${getPublicationsURLSearchParams(
      params,
    )}`,
  registerPluign: (): string => `plugins/`,
  getPlugin: (pluginId: string): string => `plugins/${pluginId}/`,
  getAllPlugins: (): string => `/plugins/`,
  getSubmapConnections: (): string => `projects/${PROJECT_ID}/submapConnections/`,
  logout: (): string => `doLogout`,
  user: (login: string): string => `users/${login}`,
  updateUser: (login: string): string => `users/${login}`,
  getStacktrace: (code: string): string => `stacktrace/${code}`,
  submitError: (): string => `minervanet/submitError`,
  getOauthProviders: (): string => `oauth/providers/`,
  userPrivileges: (login: string): string => `users/${login}?columns=privileges`,
  getComments: (): string => `projects/${PROJECT_ID}/comments/models/*/`,
  addComment: (modelId: number, x: number, y: number): string =>
    `projects/${PROJECT_ID}/comments/models/${modelId}/points/${x},${y}/`,

  getSearchAutocomplete: (): string =>
    `projects/${PROJECT_ID}/models/*/bioEntities/suggestedQueryList`,
  getDrugAutocomplete: (): string => `projects/${PROJECT_ID}/drugs/suggestedQueryList`,
  getChemicalAutocomplete: (): string => `projects/${PROJECT_ID}/chemicals/suggestedQueryList`,
};
