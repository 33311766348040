import { createSlice } from '@reduxjs/toolkit';
import { BIOENTITY_INITIAL_STATE } from './bioEntity.constants';
import {
  clearBioEntitiesReducer,
  getBioEntityContentsReducer,
  getMultiBioEntityContentsReducer,
  getSubmapConnectionsBioEntityReducer,
  setBioEntityContentsReducer,
  setMultipleBioEntityContentsReducer,
  toggleIsContentTabOpenedReducer,
} from './bioEntity.reducers';

export const bioEntityContentsSlice = createSlice({
  name: 'bioEntityContents',
  initialState: BIOENTITY_INITIAL_STATE,
  reducers: {
    clearBioEntities: clearBioEntitiesReducer,
    toggleIsContentTabOpened: toggleIsContentTabOpenedReducer,
    setBioEntityContents: setBioEntityContentsReducer,
    setMultipleBioEntityContents: setMultipleBioEntityContentsReducer,
  },
  extraReducers: builder => {
    getBioEntityContentsReducer(builder);
    getMultiBioEntityContentsReducer(builder);
    getSubmapConnectionsBioEntityReducer(builder);
  },
});

export const {
  clearBioEntities,
  toggleIsContentTabOpened,
  setBioEntityContents,
  setMultipleBioEntityContents,
} = bioEntityContentsSlice.actions;

export default bioEntityContentsSlice.reducer;
