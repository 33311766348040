import { toast } from 'sonner';
import { Toast } from '@/shared/Toast';

const DEFAULT_DURATION_MS = 5000;

type ShowToastArgs = {
  type: 'success' | 'error';
  message: string;
  duration?: number;
};

export const showToast = (args: ShowToastArgs): void => {
  toast.custom(
    t => <Toast message={args.message} onDismiss={() => toast.dismiss(t)} type={args.type} />,
    { duration: args.duration ? args.duration : DEFAULT_DURATION_MS },
  );
};
