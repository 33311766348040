import axios from 'axios';
import { UNKNOWN_ERROR } from '../getErrorMessage/getErrorMessage.constants';

export const getErrorName = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    return error.name;
  }
  if (error instanceof Error) {
    return error.name;
  }
  return UNKNOWN_ERROR;
};
