import { ZERO } from '@/constants/common';
import type { AppDispatch, store } from '@/redux/store';
import { BioEntity, BioEntityContent } from '@/types/models';
import { MultiSearchByIdParams, PerfectMultiSearchParams } from '@/types/search';
import { ThunkConfig } from '@/types/store';
import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getError } from '@/utils/error-report/getError';
import { addNumbersToEntityNumberData } from '../../entityNumber/entityNumber.slice';
import { MULTI_BIO_ENTITY_FETCHING_ERROR_PREFIX } from '../bioEntity.constants';
import { getBioEntity, getBioEntityById } from './getBioEntity';
import { fetchReactionsAndGetBioEntitiesIds } from './utils/fetchReactionsAndGetBioEntitiesIds';

type GetMultiBioEntityProps = PerfectMultiSearchParams;
type GetMultiBioEntityActions = PayloadAction<BioEntityContent[] | undefined | string>[]; // if error thrown, string containing error message is returned

export const getMultiBioEntity = createAsyncThunk<
  BioEntityContent[],
  GetMultiBioEntityProps,
  ThunkConfig
>(
  'project/getMultiBioEntity',
  // eslint-disable-next-line consistent-return
  async ({ searchQueries, isPerfectMatch }, { dispatch, getState }) => {
    try {
      const asyncGetBioEntityFunctions = searchQueries.map(searchQuery =>
        dispatch(getBioEntity({ searchQuery, isPerfectMatch, addNumbersToEntityNumber: false })),
      );

      const bioEntityContentsActions = (await Promise.all(
        asyncGetBioEntityFunctions,
      )) as GetMultiBioEntityActions;

      const bioEntityContents = bioEntityContentsActions
        .map(bioEntityContentsAction => bioEntityContentsAction?.payload || [])
        .flat()
        .filter((payload): payload is BioEntityContent => typeof payload !== 'string')
        .filter(payload => 'bioEntity' in payload || {});

      const bioEntityIds = bioEntityContents.map(b => b.bioEntity.elementId);
      dispatch(addNumbersToEntityNumberData(bioEntityIds));

      const bioEntitiesIds = await fetchReactionsAndGetBioEntitiesIds({
        bioEntityContents,
        dispatch: dispatch as AppDispatch,
        getState: getState as typeof store.getState,
      });
      const bioEntitiesStringIds = bioEntitiesIds.map(id => String(id));
      if (bioEntitiesIds.length > ZERO) {
        await dispatch(
          getMultiBioEntity({ searchQueries: bioEntitiesStringIds, isPerfectMatch: true }),
        );
      }

      return bioEntityContents;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: MULTI_BIO_ENTITY_FETCHING_ERROR_PREFIX }));
    }
  },
);

type GetMultiBioEntityByIdProps = MultiSearchByIdParams;
type GetMultiBioEntityByIdActions = PayloadAction<BioEntity[] | undefined>[]; // if error thrown, string containing error message is returned

export const getMultiBioEntityByIds = createAsyncThunk<
  BioEntity[],
  GetMultiBioEntityByIdProps,
  ThunkConfig
>(
  'project/getMultiBioEntity',
  // eslint-disable-next-line consistent-return
  async ({ elementsToFetch }, { dispatch, getState }) => {
    try {
      const asyncGetBioEntityFunctions = elementsToFetch.map(elementToFetch =>
        dispatch(getBioEntityById(elementToFetch)),
      );

      const bioEntityContentsActions = (await Promise.all(
        asyncGetBioEntityFunctions,
      )) as GetMultiBioEntityByIdActions;

      const bioEntities = bioEntityContentsActions
        .map(bioEntityContentsAction => bioEntityContentsAction?.payload || [])
        .flat();

      const bioEntityIds = bioEntities.map(b => b.elementId);
      dispatch(addNumbersToEntityNumberData(bioEntityIds));

      const bioEntitiesIds = await fetchReactionsAndGetBioEntitiesIds({
        bioEntityContents: bioEntities.map(bioEntity => {
          return { perfect: true, bioEntity };
        }),
        dispatch: dispatch as AppDispatch,
        getState: getState as typeof store.getState,
      });
      if (bioEntitiesIds.length > ZERO) {
        const searchQueries = bioEntitiesIds.map(id => String(id));
        await dispatch(getMultiBioEntity({ searchQueries, isPerfectMatch: true }));
      }

      return bioEntities;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: MULTI_BIO_ENTITY_FETCHING_ERROR_PREFIX }));
    }
  },
);
