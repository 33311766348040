import { LayersState, LayersVisibilitiesState } from '@/redux/layers/layers.types';
import { DEFAULT_ERROR } from '@/constants/errors';
import { FetchDataState } from '@/types/fetchDataState';

export const LAYERS_STATE_INITIAL_MOCK: LayersState = {};

export const LAYERS_STATE_INITIAL_LAYER_MOCK: FetchDataState<LayersVisibilitiesState> = {
  data: {
    layers: [],
    layersVisibility: {},
  },
  loading: 'idle',
  error: DEFAULT_ERROR,
};

export const LAYER_STATE_DEFAULT_DATA = {
  layers: [],
  layersVisibility: {},
};
