import { createSlice } from '@reduxjs/toolkit';
import { getModelElementsReducer } from '@/redux/modelElements/modelElements.reducers';
import { MODEL_ELEMENTS_INITIAL_STATE_MOCK } from '@/redux/modelElements/modelElements.mock';

export const modelElements = createSlice({
  name: 'modelElements',
  initialState: MODEL_ELEMENTS_INITIAL_STATE_MOCK,
  reducers: {},
  extraReducers: builder => {
    getModelElementsReducer(builder);
  },
});

export default modelElements.reducer;
