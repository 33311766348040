import { getProjectById, setProjectId } from '@/redux/project/project.thunks';
import { ProjectState } from '@/redux/project/project.types';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const getProjectByIdReducer = (builder: ActionReducerMapBuilder<ProjectState>): void => {
  builder.addCase(getProjectById.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getProjectById.fulfilled, (state, action) => {
    state.data = action.payload || undefined;
    state.loading = 'succeeded';
  });
  builder.addCase(getProjectById.rejected, state => {
    state.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};

export const setProjectIdReducer = (builder: ActionReducerMapBuilder<ProjectState>): void => {
  builder.addCase(setProjectId.fulfilled, (state, action) => {
    state.projectId = action.payload;
  });
};
