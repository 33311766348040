interface CloseIconProps {
  className?: string;
}

export const CloseIcon = ({ className }: CloseIconProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2059_12637)">
      <path d="M11.9997 10.5867L16.9497 5.63672L18.3637 7.05072L13.4137 12.0007L18.3637 16.9507L16.9497 18.3647L11.9997 13.4147L7.04974 18.3647L5.63574 16.9507L10.5857 12.0007L5.63574 7.05072L7.04974 5.63672L11.9997 10.5867Z" />
    </g>
    <defs>
      <clipPath id="clip0_2059_12637">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
