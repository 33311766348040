import { idSchema } from '@/models/idSchema';
import { z } from 'zod';
import { referenceSchema } from './referenceSchema';
import { targetSchema } from './targetSchema';

export const chemicalSchema = z.object({
  id: idSchema,
  name: z.string(),
  description: z.string(),
  directEvidence: z.string().nullable(),
  directEvidenceReferences: z.array(referenceSchema),
  synonyms: z.array(z.string()),
  references: z.array(referenceSchema),
  targets: z.array(targetSchema),
});
