/* eslint-disable no-magic-numbers */
import { apiPath } from '@/redux/apiPath';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { NewReaction } from '@/types/models';
import { ThunkConfig } from '@/types/store';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getError } from '@/utils/error-report/getError';
import { newReactionSchema } from '@/models/newReactionSchema';
import { REACTIONS_FETCHING_ERROR_PREFIX } from './reactions.constants';

type ReactionId = {
  id: number;
  modelId: number;
};

type GetReactionsByIdsArgs = {
  ids: ReactionId[];
  shouldConcat?: boolean;
};

export type GetReactionsByIdsResult = {
  data: NewReaction[];
  shouldConcat: boolean;
};

export const getReactionsByIds = createAsyncThunk<
  GetReactionsByIdsResult | undefined,
  GetReactionsByIdsArgs,
  ThunkConfig
>('reactions/getByIds', async args => {
  const { ids } = args;
  const shouldConcat = args.shouldConcat || false;

  try {
    const reactionPromises = ids.map(reactionId =>
      axiosInstanceNewAPI.get<NewReaction>(
        apiPath.getNewReaction(reactionId.modelId, reactionId.id),
      ),
    );
    const reactionResponses = await Promise.all(reactionPromises);

    const result: GetReactionsByIdsResult = {
      data: [],
      shouldConcat,
    };
    for (let i = 0; i < reactionResponses.length; i += 1) {
      const response = reactionResponses[i];
      const isDataValid = validateDataUsingZodSchema(response.data, newReactionSchema);
      if (isDataValid) {
        result.data.push(response.data);
      }
    }

    return result;
  } catch (error) {
    return Promise.reject(getError({ error, prefix: REACTIONS_FETCHING_ERROR_PREFIX }));
  }
});
