import { z } from 'zod';
import { colorSchema } from './colorSchema';
import { segmentSchema } from './segmentSchema';
import { arrowSchema } from './arrowSchema';

export const lineSchema = z.object({
  id: z.number(),
  width: z.number(),
  color: colorSchema,
  z: z.number(),
  segments: z.array(segmentSchema),
  startArrow: arrowSchema,
  endArrow: arrowSchema,
  lineType: z.string(),
});
