import { createSlice } from '@reduxjs/toolkit';
import { CONTEXT_MENU_INITIAL_STATE } from './contextMenu.constants';
import {
  openContextMenuReducer,
  closeContextMenuReducer,
  setCurrentSelectedBioEntityIdReducer,
} from './contextMenu.reducers';

const contextMenuSlice = createSlice({
  name: 'contextMenu',
  initialState: CONTEXT_MENU_INITIAL_STATE,
  reducers: {
    openContextMenu: openContextMenuReducer,
    closeContextMenu: closeContextMenuReducer,
    setCurrentSelectedBioEntityId: setCurrentSelectedBioEntityIdReducer,
  },
});

export const { openContextMenu, closeContextMenu, setCurrentSelectedBioEntityId } =
  contextMenuSlice.actions;

export default contextMenuSlice.reducer;
