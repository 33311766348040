interface ExportIconProps {
  className?: string;
}

export const ExportIcon = ({ className }: ExportIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.0134 6.01583L10.4149 1.66797L6.81641 6.01583H9.6952V13.9869H11.1346V6.01583H14.0134Z" />
    <path d="M17.6138 18.3383H3.21985C3.10918 18.3384 2.99999 18.3127 2.90078 18.2633C2.80157 18.2139 2.71501 18.1422 2.64782 18.0536C2.58063 17.9651 2.53463 17.8621 2.51338 17.7528C2.49214 17.6434 2.49623 17.5306 2.52534 17.4231L4.68443 9.45203C4.72597 9.29862 4.81646 9.16323 4.94197 9.06671C5.06747 8.9702 5.22102 8.91794 5.37894 8.91797H7.53804V10.3673H5.92879L4.16265 16.889H16.671L14.9049 10.3673H13.2956V8.91797H15.4547C15.6127 8.91794 15.7662 8.9702 15.8917 9.06671C16.0172 9.16323 16.1077 9.29862 16.1492 9.45203L18.3083 17.4231C18.3374 17.5306 18.3415 17.6434 18.3203 17.7528C18.299 17.8621 18.253 17.9651 18.1859 18.0536C18.1187 18.1422 18.0321 18.2139 17.9329 18.2633C17.8337 18.3127 17.7245 18.3384 17.6138 18.3383Z" />
  </svg>
);
