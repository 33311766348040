import { mapBackground } from '@/models/mapBackground';
import { axiosInstance } from '@/services/api/utils/axiosInstance';
import { MapBackground } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { z } from 'zod';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { apiPath } from '../apiPath';
import { BACKGROUNDS_FETCHING_ERROR_PREFIX } from './backgrounds.constants';

export const getAllBackgroundsByProjectId = createAsyncThunk<MapBackground[], string, ThunkConfig>(
  'backgrounds/getAllBackgroundsByProjectId',
  async (projectId: string) => {
    try {
      const response = await axiosInstance.get<MapBackground[]>(
        apiPath.getAllBackgroundsByProjectIdQuery(projectId),
      );

      const isDataValid = validateDataUsingZodSchema(response.data, z.array(mapBackground));

      return isDataValid ? response.data : [];
    } catch (error) {
      return Promise.reject(getError({ error, prefix: BACKGROUNDS_FETCHING_ERROR_PREFIX }));
    }
  },
);
