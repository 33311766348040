import { createSlice } from '@reduxjs/toolkit';
import { CONSTANT_INITIAL_STATE } from './constant.adapter';
import { getConstantReducer } from './constant.reducers';

export const constantSlice = createSlice({
  name: 'constant',
  initialState: CONSTANT_INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    getConstantReducer(builder);
  },
});

export default constantSlice.reducer;
