import axios from 'axios';
import { UNKNOWN_ERROR } from './getErrorMessage.constants';
import { extractAxiosErrorMessage } from './getErrorMessage.utils';

type GetErrorMessageConfig = {
  error: unknown;
  message?: string;
  prefix?: string;
};

export const getErrorMessage = ({ error, message, prefix }: GetErrorMessageConfig): string => {
  let errorMessage: string;

  switch (true) {
    case !!message:
      errorMessage = message;
      break;
    case axios.isAxiosError(error):
      errorMessage = extractAxiosErrorMessage(error);
      break;
    case error instanceof Error:
      errorMessage = error.message;
      break;
    default:
      errorMessage = UNKNOWN_ERROR;
  }

  return prefix ? `${prefix}: ${errorMessage}` : errorMessage;
};
