import { MapInstance } from '@/types/map';
import { Map } from 'ol';

type MapManagerType = {
  mapInstance: null | MapInstance;
  setMapInstance: (mapInstance: MapInstance) => void;
  getMapInstance: () => MapInstance | null;
};

export const MapManager: MapManagerType = {
  mapInstance: null,

  setMapInstance: (mapInstance: MapInstance) => {
    if (!(mapInstance instanceof Map)) throw new Error('Not valid map instance');
    MapManager.mapInstance = mapInstance;
  },
  getMapInstance: () => MapManager.mapInstance,
};
