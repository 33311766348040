import { Action } from '@reduxjs/toolkit';

export const getModelIdFromAction = (action: Action): number | null => {
  try {
    const payload = 'payload' in action ? (action.payload as object) : {};
    return 'modelId' in payload ? (payload.modelId as number) : null;
  } catch {
    return null;
  }
};
