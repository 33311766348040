import { CookieBannerState } from './cookieBanner.types';

export const showBannerReducer = (state: CookieBannerState): void => {
  state.visible = true;
};

export const hideBannerReducer = (state: CookieBannerState): void => {
  state.visible = false;
};

export const acceptCookiesReducer = (state: CookieBannerState): void => {
  state.accepted = true;
  state.visible = false;
};
