import { createSlice } from '@reduxjs/toolkit';
import { MARKERS_INITIAL_STATE } from './markers.constants';
import {
  addMarkerToMarkersDataReducer,
  removeMarkerFromMarkersDataReducer,
  setMarkersDataReducer,
} from './markers.reducers';

const markersSlice = createSlice({
  name: 'markers',
  initialState: MARKERS_INITIAL_STATE,
  reducers: {
    setMarkersData: setMarkersDataReducer,
    addMarkerToMarkersData: addMarkerToMarkersDataReducer,
    removeMarkerFromMarkersData: removeMarkerFromMarkersDataReducer,
  },
  extraReducers: () => {},
});

export const { setMarkersData, addMarkerToMarkersData, removeMarkerFromMarkersData } =
  markersSlice.actions;

export default markersSlice.reducer;
