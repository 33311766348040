import { z } from 'zod';

export const shapeRelAbsSchema = z.object({
  type: z.enum(['REL_ABS_POINT', 'REL_ABS_RADIUS']),
  absoluteX: z.number(),
  absoluteY: z.number(),
  relativeX: z.number(),
  relativeY: z.number(),
  relativeHeightForX: z.number().nullable(),
  relativeWidthForY: z.number().nullable(),
});
