import axios from 'axios';

export const getErrorUrl = (error: unknown): string | null => {
  if (axios.isAxiosError(error)) {
    if (error.request) {
      if (error.request.responseURL) {
        return error.request.responseURL;
      }
    } else if (error.config) {
      if (error.config.url) {
        return error.config.url;
      }
    }
  }
  return null;
};
