import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getAllBackgroundsByProjectId } from './backgrounds.thunks';
import { BackgroundsState } from './backgrounds.types';

export const getAllBackgroundsByProjectIdReducer = (
  builder: ActionReducerMapBuilder<BackgroundsState>,
): void => {
  builder.addCase(getAllBackgroundsByProjectId.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getAllBackgroundsByProjectId.fulfilled, (state, action) => {
    state.data = action.payload || [];
    state.loading = 'succeeded';
  });
  builder.addCase(getAllBackgroundsByProjectId.rejected, state => {
    state.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};
