import { DEFAULT_ERROR } from '@/constants/errors';
import { Loading } from '@/types/loadingState';
import { ConfigurationOption } from '@/types/models';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { ConfigurationMainState } from './configuration.types';

export const configurationAdapter = createEntityAdapter<ConfigurationOption>({
  selectId: option => option.type,
});

const REQUEST_INITIAL_STATUS: { loading: Loading; error: Error } = {
  loading: 'idle',
  error: DEFAULT_ERROR,
};

const MAIN_CONFIGURATION_INITIAL_STATE: ConfigurationMainState = {
  data: undefined,
  ...REQUEST_INITIAL_STATUS,
};

export const CONFIGURATION_INITIAL_STATE = {
  options: configurationAdapter.getInitialState(REQUEST_INITIAL_STATUS),
  main: MAIN_CONFIGURATION_INITIAL_STATE,
};

export type ConfigurationState = typeof CONFIGURATION_INITIAL_STATE;
