import { axiosInstance } from '@/services/api/utils/axiosInstance';
import { OAuth } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { oauthSchema } from '@/models/oauthSchema';
import { OAUTH_FETCHING_ERROR_PREFIX } from '@/redux/oauth/oauth.constants';
import { apiPath } from '../apiPath';

export const getOAuth = createAsyncThunk<OAuth | undefined, void, ThunkConfig>(
  'oauth/getProviders',
  async () => {
    try {
      const response = await axiosInstance.get<OAuth>(apiPath.getOauthProviders());

      const isDataValid = validateDataUsingZodSchema(response.data, oauthSchema);

      return isDataValid ? response.data : undefined;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: OAUTH_FETCHING_ERROR_PREFIX }));
    }
  },
);
