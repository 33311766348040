import { createSlice } from '@reduxjs/toolkit';
import { PublicationsState } from './publications.types';
import {
  getPublicationsReducer,
  setSortOrderAndColumnReducer,
  setSelectedModelIdReducer,
  setSearchValueReducer,
} from './publications.reducers';

const initialState: PublicationsState = {
  data: undefined,
  loading: 'idle',
  error: { name: '', message: '' },
  sortColumn: '',
  sortOrder: 'asc',
  searchValue: '',
};

const publicationsSlice = createSlice({
  name: 'publications',
  initialState,
  reducers: {
    setSortOrderAndColumn: setSortOrderAndColumnReducer,
    setSelectedModelId: setSelectedModelIdReducer,
    setPublicationSearchValue: setSearchValueReducer,
  },
  extraReducers: builder => {
    getPublicationsReducer(builder);
  },
});

export const { setSortOrderAndColumn, setSelectedModelId, setPublicationSearchValue } =
  publicationsSlice.actions;

export default publicationsSlice.reducer;
