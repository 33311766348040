import { z } from 'zod';
import { userPrivilegeSchema } from '@/models/userPrivilegesSchema';
import { ZERO } from '@/constants/common';

export const userSchema = z.object({
  id: z.number().int().gt(ZERO),
  login: z.string(),
  name: z.string(),
  surname: z.string(),
  email: z.string().email().nullable(),
  orcidId: z.string().nullable(),
  minColor: z.string().nullable(),
  neutralColor: z.string().nullable(),
  simpleColor: z.string().nullable(),
  removed: z.boolean(),
  termsOfUseConsent: z.boolean(),
  privileges: z.array(userPrivilegeSchema),
  active: z.boolean(),
  confirmed: z.boolean(),
  ldapAccountAvailable: z.boolean(),
  lastActive: z.string().nullable(),
});
