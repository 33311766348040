import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getSessionValid, login, logout } from './user.thunks';
import { UserState } from './user.types';

export const loginReducer = (builder: ActionReducerMapBuilder<UserState>): void => {
  builder
    .addCase(login.pending, state => {
      state.loading = 'pending';
    })
    .addCase(login.fulfilled, (state, action) => {
      state.authenticated = true;
      state.loading = 'succeeded';
      state.role = action.payload?.role || null;
      state.login = action.payload?.login || null;
      state.userData = action.payload?.userData || null;
    })
    .addCase(login.rejected, state => {
      state.authenticated = false;
      state.loading = 'failed';
    });
};

export const getSessionValidReducer = (builder: ActionReducerMapBuilder<UserState>): void => {
  builder
    .addCase(getSessionValid.pending, state => {
      state.loading = 'pending';
    })
    .addCase(getSessionValid.fulfilled, (state, action) => {
      state.authenticated = true;
      state.loading = 'succeeded';
      state.login = action.payload?.login || null;
      state.role = action.payload?.role || null;
      state.userData = action.payload?.userData || null;
    })
    .addCase(getSessionValid.rejected, state => {
      state.authenticated = false;
      state.loading = 'failed';
      // TODO: error management to be discussed in the team
    });
};

export const logoutReducer = (builder: ActionReducerMapBuilder<UserState>): void => {
  builder
    .addCase(logout.pending, state => {
      state.loading = 'pending';
    })
    .addCase(logout.fulfilled, state => {
      state.authenticated = false;
      state.loading = 'succeeded';
      state.role = null;
      state.login = null;
      state.userData = null;
    })
    .addCase(logout.rejected, state => {
      state.loading = 'failed';
    });
};
