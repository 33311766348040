/* eslint-disable no-magic-numbers */
import { z } from 'zod';

export const pluginSchema = z.object({
  hash: z.string(),
  name: z.string(),
  version: z.string(),
  isPublic: z.boolean(),
  isDefault: z.boolean(),
  withoutPanel: z.boolean().nullable().optional(),
  urls: z.array(z.string().min(1)),
});
