import { ONE } from '@/constants/common';
import {
  AddNumbersToEntityNumberDataAction,
  EntityNumber,
  EntityNumberState,
} from './entityNumber.types';

export const clearEntityNumberDataReducer = (state: EntityNumberState): void => {
  state.data = {};
};

export const addNumbersToEntityNumberDataReducer = (
  state: EntityNumberState,
  action: AddNumbersToEntityNumberDataAction,
): void => {
  const { payload: ids } = action;
  const uniqueIds = [...new Set(ids)];

  const lastNumber = Object.keys(state.data).length || ONE; // min num = 1
  const newEntityNumber: EntityNumber = Object.fromEntries(
    uniqueIds.map((id, index) => [id, lastNumber + index]),
  );
  state.data = {
    ...newEntityNumber,
    ...state.data,
  };
};
