import { MapManager } from '@/services/pluginsManager/map/mapManager';
import { MapInstance } from '@/types/map';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

export interface MapInstanceContext {
  mapInstance: MapInstance;
  handleSetMapInstance: (mapInstance: MapInstance) => void;
}

export const MapInstanceContext = createContext<MapInstanceContext>({
  mapInstance: undefined,
  handleSetMapInstance: () => {},
});

export const useMapInstance = (): MapInstanceContext => useContext(MapInstanceContext);

export interface MapInstanceProviderProps {
  children: React.ReactNode;
  initialValue?: MapInstanceContext;
}

export const MapInstanceProvider = ({
  children,
  initialValue,
}: MapInstanceProviderProps): JSX.Element => {
  const [mapInstance, setMapInstance] = useState<MapInstance>(initialValue?.mapInstance);

  const handleSetMapInstance = useCallback(
    (map: MapInstance) => {
      if (!mapInstance) {
        setMapInstance(map);
        MapManager.setMapInstance(map);
      }
    },
    [mapInstance],
  );

  const mapInstanceContextValue = useMemo(
    () => ({
      mapInstance,
      handleSetMapInstance,
    }),
    [mapInstance, handleSetMapInstance],
  );

  return (
    <MapInstanceContext.Provider value={mapInstanceContextValue}>
      {children}
    </MapInstanceContext.Provider>
  );
};
