import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  addOverlay,
  getAllPublicOverlaysByProjectId,
  getAllUserOverlaysByCreator,
  removeOverlay,
  updateOverlays,
} from './overlays.thunks';
import type { OverlaysState } from './overlays.types';

export const getAllPublicOverlaysByProjectIdReducer = (
  builder: ActionReducerMapBuilder<OverlaysState>,
): void => {
  builder.addCase(getAllPublicOverlaysByProjectId.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getAllPublicOverlaysByProjectId.fulfilled, (state, action) => {
    state.data = action.payload || [];
    state.loading = 'succeeded';
  });
  builder.addCase(getAllPublicOverlaysByProjectId.rejected, state => {
    state.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};

export const addOverlayReducer = (builder: ActionReducerMapBuilder<OverlaysState>): void => {
  builder.addCase(addOverlay.pending, state => {
    state.addOverlay.loading = 'pending';
  });
  builder.addCase(addOverlay.fulfilled, state => {
    state.addOverlay.loading = 'succeeded';
  });
  builder.addCase(addOverlay.rejected, state => {
    state.addOverlay.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};

export const getAllUserOverlaysByCreatorReducer = (
  builder: ActionReducerMapBuilder<OverlaysState>,
): void => {
  builder.addCase(getAllUserOverlaysByCreator.pending, state => {
    state.userOverlays.loading = 'pending';
  });
  builder.addCase(getAllUserOverlaysByCreator.fulfilled, (state, action) => {
    state.userOverlays.data = action.payload;
    state.userOverlays.loading = 'succeeded';
  });
  builder.addCase(getAllUserOverlaysByCreator.rejected, state => {
    state.userOverlays.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};

export const updateOverlaysReducer = (builder: ActionReducerMapBuilder<OverlaysState>): void => {
  builder.addCase(updateOverlays.pending, state => {
    state.updateOverlays.loading = 'pending';
  });
  builder.addCase(updateOverlays.fulfilled, state => {
    state.updateOverlays.loading = 'succeeded';
  });
  builder.addCase(updateOverlays.rejected, state => {
    state.updateOverlays.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};

export const removeOverlayReducer = (builder: ActionReducerMapBuilder<OverlaysState>): void => {
  builder.addCase(removeOverlay.pending, state => {
    state.removeOverlay.loading = 'pending';
  });
  builder.addCase(removeOverlay.fulfilled, state => {
    state.removeOverlay.loading = 'succeeded';
  });
  builder.addCase(removeOverlay.rejected, state => {
    state.removeOverlay.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};
