import { createAsyncThunk } from '@reduxjs/toolkit';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { axiosInstance } from '@/services/api/utils/axiosInstance';
import { PublicationsResponse } from '@/types/models';
import { publicationsResponseSchema } from '@/models/publicationsResponseSchema';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { GetPublicationsParams } from './publications.types';
import { apiPath } from '../apiPath';
import { PUBLICATIONS_FETCHING_ERROR_PREFIX } from './publications.constatns';

export const getPublications = createAsyncThunk<
  PublicationsResponse | undefined,
  GetPublicationsParams,
  ThunkConfig
>('publications/getPublications', async params => {
  try {
    const response = await axiosInstance.get<PublicationsResponse>(apiPath.getPublications(params));

    const isDataValid = validateDataUsingZodSchema(response.data, publicationsResponseSchema);

    return isDataValid ? response.data : undefined;
  } catch (error) {
    return Promise.reject(getError({ error, prefix: PUBLICATIONS_FETCHING_ERROR_PREFIX }));
  }
});
