import axios from 'axios';
import { getErrorUrl } from '@/utils/error-report/getErrorUrl';

export const getErrorStack = (error: unknown): string => {
  let stack = null;
  if (axios.isAxiosError(error)) {
    const url = getErrorUrl(error);
    stack = (url ? `(Request URL: ${url}) ` : '') + error.stack;
  } else if (error instanceof Error) {
    stack = error.stack;
  }
  return stack || 'No stack provided';
};
