import { z } from 'zod';
import { targetElementSchema } from './targetElementSchema';
import { articleSchema } from './articleSchema';

export const publicationSchema = z.object({
  elements: z.array(targetElementSchema),
  publication: z.object({
    article: articleSchema,
  }),
});
