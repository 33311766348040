import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { OauthState } from '@/redux/oauth/oauth.types';
import { getOAuth } from '@/redux/oauth/oauth.thunks';

export const getOauthReducer = (builder: ActionReducerMapBuilder<OauthState>): void => {
  builder.addCase(getOAuth.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getOAuth.fulfilled, (state, action) => {
    state.data = action.payload || undefined;
    if (action.payload && action.payload.Orcid) {
      state.orcidEndpoint = action.payload.Orcid;
    }
    state.loading = 'succeeded';
  });
  builder.addCase(getOAuth.rejected, state => {
    state.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};
