import { createSlice } from '@reduxjs/toolkit';
import {
  acceptCookiesReducer,
  hideBannerReducer,
  showBannerReducer,
} from './cookieBanner.reducers';
import { CookieBannerState } from './cookieBanner.types';

const initialState: CookieBannerState = {
  visible: false,
  accepted: false,
};

const cookieBannerSlice = createSlice({
  name: 'cookieBanner',
  initialState,
  reducers: {
    showBanner: showBannerReducer,
    hideBanner: hideBannerReducer,
    acceptCookies: acceptCookiesReducer,
  },
});

export const { showBanner, hideBanner, acceptCookies } = cookieBannerSlice.actions;

export default cookieBannerSlice.reducer;
