interface LocationIconProps {
  className?: string;
}

export const LocationIcon = ({ className }: LocationIconProps): JSX.Element => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.9998 9.33073C11.4215 9.33073 9.33317 11.4191 9.33317 13.9974C9.33317 16.5757 11.4215 18.6641 13.9998 18.6641C16.5782 18.6641 18.6665 16.5757 18.6665 13.9974C18.6665 11.4191 16.5782 9.33073 13.9998 9.33073ZM24.4298 12.8307C24.1656 10.4649 23.1047 8.25919 21.4214 6.57587C19.738 4.89255 17.5324 3.83166 15.1665 3.5674V1.16406H12.8332V3.5674C10.4673 3.83166 8.26163 4.89255 6.57831 6.57587C4.895 8.25919 3.8341 10.4649 3.56984 12.8307H1.1665V15.1641H3.56984C3.8341 17.5299 4.895 19.7356 6.57831 21.4189C8.26163 23.1022 10.4673 24.1631 12.8332 24.4274V26.8307H15.1665V24.4274C17.5324 24.1631 19.738 23.1022 21.4214 21.4189C23.1047 19.7356 24.1656 17.5299 24.4298 15.1641H26.8332V12.8307H24.4298V12.8307ZM13.9998 22.1641C9.48484 22.1641 5.83317 18.5124 5.83317 13.9974C5.83317 9.4824 9.48484 5.83073 13.9998 5.83073C18.5148 5.83073 22.1665 9.4824 22.1665 13.9974C22.1665 18.5124 18.5148 22.1641 13.9998 22.1641Z"
      fill="#8E92A1"
    />
  </svg>
);
