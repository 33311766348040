import { ThunkConfig } from '@/types/store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getError } from '@/utils/error-report/getError';
import { axiosInstance } from '@/services/api/utils/axiosInstance';
import { apiPath } from '@/redux/apiPath';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { autocompleteSchema } from '@/models/autocompleteSchema';
import type { RootState } from '../store';

export const getSearchAutocomplete = createAsyncThunk<
  string[] | undefined,
  void,
  { state: RootState } & ThunkConfig
>(
  'project/getSearchAutocomplete',
  // eslint-disable-next-line consistent-return
  async () => {
    try {
      const response = await axiosInstance.get<string[]>(apiPath.getSearchAutocomplete());

      const isDataValid = validateDataUsingZodSchema(response.data, autocompleteSchema);

      return isDataValid ? response.data : undefined;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);

export const getDrugAutocomplete = createAsyncThunk<
  string[] | undefined,
  void,
  { state: RootState } & ThunkConfig
>(
  'project/getDrugAutocomplete',
  // eslint-disable-next-line consistent-return
  async () => {
    try {
      const response = await axiosInstance.get<string[]>(apiPath.getDrugAutocomplete());

      const isDataValid = validateDataUsingZodSchema(response.data, autocompleteSchema);

      return isDataValid ? response.data : undefined;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);

export const getChemicalAutocomplete = createAsyncThunk<
  string[] | undefined,
  void,
  { state: RootState } & ThunkConfig
>(
  'project/getChemicalAutocomplete',
  // eslint-disable-next-line consistent-return
  async () => {
    try {
      const response = await axiosInstance.get<string[]>(apiPath.getChemicalAutocomplete());

      const isDataValid = validateDataUsingZodSchema(response.data, autocompleteSchema);

      return isDataValid ? response.data : undefined;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);
