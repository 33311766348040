import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getCompartmentPathways } from './compartmentPathways.thunks';
import { CompartmentPathwaysState } from './compartmentPathways.types';

export const getCompartmentPathwaysReducer = (
  builder: ActionReducerMapBuilder<CompartmentPathwaysState>,
): void => {
  builder
    .addCase(getCompartmentPathways.pending, state => {
      state.loading = 'pending';
    })
    .addCase(getCompartmentPathways.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = 'succeeded';
    })
    .addCase(getCompartmentPathways.rejected, state => {
      state.loading = 'failed';
      // TODO: error management to be discussed in the team
    });
};
