import { DEFAULT_ERROR } from '@/constants/errors';
import { ShapesState } from '@/redux/shapes/shapes.types';

export const SHAPES_STATE_INITIAL_MOCK: ShapesState = {
  bioShapesState: {
    data: {},
    loading: 'idle',
    error: DEFAULT_ERROR,
  },
  lineTypesState: {
    data: {},
    loading: 'idle',
    error: DEFAULT_ERROR,
  },
  arrowTypesState: {
    data: {},
    loading: 'idle',
    error: DEFAULT_ERROR,
  },
};
