import { Marker } from '@/types/models';
import { PayloadAction } from '@reduxjs/toolkit';
import { MarkersState } from './markers.types';

export const setMarkersDataReducer = (
  state: MarkersState,
  action: PayloadAction<Marker[]>,
): void => {
  const { payload } = action;

  state.data = payload;
};

export const addMarkerToMarkersDataReducer = (
  state: MarkersState,
  action: PayloadAction<Marker>,
): void => {
  const { payload } = action;

  state.data.push(payload);
};

export const removeMarkerFromMarkersDataReducer = (
  state: MarkersState,
  action: PayloadAction<string>,
): void => {
  const currentMarkers = state.data;
  const { payload: markerId } = action;
  const newMarkers = currentMarkers.filter(m => m.id !== markerId);

  state.data = newMarkers;
};
