import { BASE_API_URL, BASE_NEW_API_URL } from '@/constants';
import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
});

export const axiosInstanceNewAPI = axios.create({
  baseURL: BASE_NEW_API_URL,
});

axiosInstance.interceptors.request.use(config => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  // eslint-disable-next-line no-param-reassign
  config.errorContext = new Error('Thrown at:');
  return config;
});

axiosInstance.interceptors.response.use(undefined, async error => {
  const originalStackTrace = error.config?.errorContext?.stack;
  if (originalStackTrace) {
    // eslint-disable-next-line no-param-reassign
    error.stack = `${error.stack}\n${originalStackTrace}`;
  }

  throw error;
});

axiosInstanceNewAPI.interceptors.request.use(config => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  // eslint-disable-next-line no-param-reassign
  config.errorContext = new Error('Thrown at:');
  return config;
});

axiosInstanceNewAPI.interceptors.response.use(undefined, async error => {
  const originalStackTrace = error.config?.errorContext?.stack;
  if (originalStackTrace) {
    // eslint-disable-next-line no-param-reassign
    error.stack = `${error.stack}\n${originalStackTrace}`;
  }

  throw error;
});
