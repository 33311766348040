import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getNewReactionsForModel } from '@/redux/newReactions/newReactions.thunks';
import { NewReactionsState } from '@/redux/newReactions/newReactions.types';
import { NEW_REACTIONS_STATE_INITIAL_REACTIONS_MOCK } from '@/redux/newReactions/newReactions.mock';
import { DEFAULT_ERROR } from '@/constants/errors';

export const getNewReactionsReducer = (
  builder: ActionReducerMapBuilder<NewReactionsState>,
): void => {
  builder.addCase(getNewReactionsForModel.pending, (state, action) => {
    const modelId = action.meta.arg;
    if (state[modelId]) {
      state[modelId].loading = 'pending';
    } else {
      state[modelId] = { ...NEW_REACTIONS_STATE_INITIAL_REACTIONS_MOCK, loading: 'pending' };
    }
  });
  builder.addCase(getNewReactionsForModel.fulfilled, (state, action) => {
    const modelId = action.meta.arg;
    const data = action.payload || [];
    if (state[modelId]) {
      state[modelId].data = data;
      state[modelId].loading = 'succeeded';
    } else {
      state[modelId] = { data, loading: 'pending', error: DEFAULT_ERROR };
    }
  });
  builder.addCase(getNewReactionsForModel.rejected, (state, action) => {
    const modelId = action.meta.arg;
    if (state[modelId]) {
      state[modelId].loading = 'failed';
    } else {
      state[modelId] = { ...NEW_REACTIONS_STATE_INITIAL_REACTIONS_MOCK, loading: 'failed' };
    }
  });
};
