import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ConfigurationState, configurationAdapter } from './configuration.adapter';
import { getConfiguration, getConfigurationOptions } from './configuration.thunks';

export const getConfigurationOptionsReducer = (
  builder: ActionReducerMapBuilder<ConfigurationState>,
): void => {
  builder.addCase(getConfigurationOptions.pending, state => {
    state.options.loading = 'pending';
  });
  builder.addCase(getConfigurationOptions.fulfilled, (state, action) => {
    if (action.payload) {
      state.options.loading = 'succeeded';
      configurationAdapter.addMany(state.options, action.payload);
    }
  });
  builder.addCase(getConfigurationOptions.rejected, state => {
    state.options.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};

export const getConfigurationReducer = (
  builder: ActionReducerMapBuilder<ConfigurationState>,
): void => {
  builder.addCase(getConfiguration.pending, state => {
    state.main.loading = 'pending';
  });
  builder.addCase(getConfiguration.fulfilled, (state, action) => {
    state.main.loading = 'succeeded';
    state.main.data = action.payload;
  });
  builder.addCase(getConfiguration.rejected, state => {
    state.main.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};
