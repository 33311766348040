interface InfoIconProps {
  className?: string;
}

export const InfoIcon = ({ className }: InfoIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2005_6409)">
      <path d="M9.99984 18.3346C5.39734 18.3346 1.6665 14.6038 1.6665 10.0013C1.6665 5.3988 5.39734 1.66797 9.99984 1.66797C14.6023 1.66797 18.3332 5.3988 18.3332 10.0013C18.3332 14.6038 14.6023 18.3346 9.99984 18.3346ZM9.99984 16.668C11.7679 16.668 13.4636 15.9656 14.7139 14.7153C15.9641 13.4651 16.6665 11.7694 16.6665 10.0013C16.6665 8.23319 15.9641 6.5375 14.7139 5.28726C13.4636 4.03701 11.7679 3.33464 9.99984 3.33464C8.23173 3.33464 6.53603 4.03701 5.28579 5.28726C4.03555 6.5375 3.33317 8.23319 3.33317 10.0013C3.33317 11.7694 4.03555 13.4651 5.28579 14.7153C6.53603 15.9656 8.23173 16.668 9.99984 16.668ZM9.1665 5.83464H10.8332V7.5013H9.1665V5.83464ZM9.1665 9.16797H10.8332V14.168H9.1665V9.16797Z" />
    </g>
    <defs>
      <clipPath id="clip0_2005_6409">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
