interface ChevronUpIconProps {
  className?: string;
}

export const ChevronUpIcon = ({ className }: ChevronUpIconProps): JSX.Element => (
  <svg viewBox="0 0 14 14" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2005_6483)">
      <path d="M7.00042 6.31563L4.11292 9.20312L3.28809 8.37829L7.00042 4.66596L10.7128 8.37829L9.88792 9.20312L7.00042 6.31563Z" />
    </g>
    <defs>
      <clipPath id="clip0_2005_6483">
        <rect width="14" height="14" fill="white" transform="translate(0 14) rotate(-90)" />
      </clipPath>
    </defs>
  </svg>
);
