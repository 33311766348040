import { AdminIcon } from '@/shared/Icon/Icons/AdminIcon';
import { ArrowIcon } from '@/shared/Icon/Icons/ArrowIcon';
import { ChevronDownIcon } from '@/shared/Icon/Icons/ChevronDownIcon';
import { ChevronLeftIcon } from '@/shared/Icon/Icons/ChevronLeftIcon';
import { ChevronRightIcon } from '@/shared/Icon/Icons/ChevronRightIcon';
import { ChevronUpIcon } from '@/shared/Icon/Icons/ChevronUpIcon';
import { CloseIcon } from '@/shared/Icon/Icons/CloseIcon';
import { DotsIcon } from '@/shared/Icon/Icons/DotsIcon';
import { ExportIcon } from '@/shared/Icon/Icons/ExportIcon';
import { LayersIcon } from '@/shared/Icon/Icons/LayersIcon';
import { InfoIcon } from '@/shared/Icon/Icons/InfoIcon';
import { LegendIcon } from '@/shared/Icon/Icons/LegendIcon';
import { PageIcon } from '@/shared/Icon/Icons/PageIcon';
import { Pin } from '@/shared/Icon/Icons/Pin';
import { PluginIcon } from '@/shared/Icon/Icons/PluginIcon';
import { PlusIcon } from '@/shared/Icon/Icons/PlusIcon';

import type { IconComponentType, IconTypes } from '@/types/iconTypes';
import { DownloadIcon } from '@/shared/Icon/Icons/DownloadIcon';
import { LocationIcon } from './Icons/LocationIcon';
import { MaginfierZoomInIcon } from './Icons/MagnifierZoomIn';
import { MaginfierZoomOutIcon } from './Icons/MagnifierZoomOut';
import { ReloadIcon } from './Icons/ReloadIcon';
import { ThreeDotsIcon } from './Icons/ThreeDotsIcon';
import { ClearIcon } from './Icons/ClearIcon';
import { UserIcon } from './Icons/UserIcon';
import { ManageUserIcon } from './Icons/ManageUserIcon';

export interface IconProps {
  className?: string;
  name: IconTypes;
}

const icons: Record<IconTypes, IconComponentType> = {
  'chevron-right': ChevronRightIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-up': ChevronUpIcon,
  'chevron-down': ChevronDownIcon,
  pin: Pin,
  plus: PlusIcon,
  arrow: ArrowIcon,
  dots: DotsIcon,
  admin: AdminIcon,
  export: ExportIcon,
  layers: LayersIcon,
  info: InfoIcon,
  download: DownloadIcon,
  legend: LegendIcon,
  page: PageIcon,
  plugin: PluginIcon,
  close: CloseIcon,
  location: LocationIcon,
  'magnifier-zoom-in': MaginfierZoomInIcon,
  'magnifier-zoom-out': MaginfierZoomOutIcon,
  'three-dots': ThreeDotsIcon,
  reload: ReloadIcon,
  clear: ClearIcon,
  user: UserIcon,
  'manage-user': ManageUserIcon,
} as const;

export const Icon = ({ name, className = '', ...rest }: IconProps): JSX.Element => {
  if (typeof name === 'undefined') {
    throw new Error('Icon component must have a name of icon!');
  }
  const IconComponent = icons[name];

  return <IconComponent className={className} {...rest} />;
};

Icon.displayName = 'Icon';
