/* eslint-disable no-magic-numbers */
import { z } from 'zod';

export const compartmentPathwaySchema = z.object({
  id: z.number(),
});

export const boundsSchema = z.object({
  height: z.number(),
  width: z.number(),
  x: z.number(),
  y: z.number(),
  z: z.number(),
});

export const otherSchema = z.object({
  modifications: z.array(z.unknown()),
  structuralState: z.null(),
  structures: z.object({}),
});

export const compartmentPathwayDetailsSchema = z.object({
  abbreviation: z.null(),
  activity: z.null(),
  boundaryCondition: z.null(),
  bounds: boundsSchema,
  compartmentId: z.number().nullable(),
  complexId: z.null(),
  constant: z.null(),
  elementId: z.string(),
  formerSymbols: z.array(z.unknown()),
  formula: z.null(),
  fullName: z.string().nullable(),
  glyph: z.any(),
  hierarchyVisibilityLevel: z.string().nullable(),
  homomultimer: z.null(),
  hypothetical: z.null(),
  id: z.number().gt(-1),
  initialAmount: z.null(),
  initialConcentration: z.null(),
  linkedSubmodel: z.null(),
  modelId: z.number(),
  name: z.string(),
  notes: z.string(),
  other: otherSchema,
  references: z.array(z.unknown()),
  symbol: z.null(),
  synonyms: z.array(z.unknown()),
  transparencyLevel: z.string(),
  type: z.string(),
});
