/* eslint-disable no-magic-numbers */
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PROJECT_ID } from '@/constants';
import { ExportNetwork, ExportElements } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { exportNetworkchema, exportElementsSchema } from '@/models/exportSchema';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { apiPath } from '../apiPath';
import { downloadFileFromBlob } from './export.utils';
import { ELEMENTS_DOWNLOAD_ERROR_PREFIX, NETWORK_DOWNLOAD_ERROR_PREFIX } from './export.constants';

type DownloadElementsBodyRequest = {
  columns: string[];
  submaps: number[];
  annotations: string[];
  includedCompartmentIds: number[];
  excludedCompartmentIds: number[];
};

export const downloadElements = createAsyncThunk<
  undefined,
  DownloadElementsBodyRequest,
  ThunkConfig
  // eslint-disable-next-line consistent-return
>('export/downloadElements', async data => {
  try {
    const response = await axiosInstanceNewAPI.post<ExportElements>(
      apiPath.downloadElementsCsv(),
      data,
      {
        withCredentials: true,
      },
    );

    const isDataValid = validateDataUsingZodSchema(response.data, exportElementsSchema);

    if (isDataValid) {
      downloadFileFromBlob(response.data, `${PROJECT_ID}-elementExport.csv`);
    }
  } catch (error) {
    return Promise.reject(getError({ error, prefix: ELEMENTS_DOWNLOAD_ERROR_PREFIX }));
  }
});

type DownloadNetworkBodyRequest = {
  columns: string[];
  submaps: number[];
  annotations: string[];
  includedCompartmentIds: number[];
  excludedCompartmentIds: number[];
};

export const downloadNetwork = createAsyncThunk<undefined, DownloadNetworkBodyRequest, ThunkConfig>(
  'export/downloadNetwork',
  // eslint-disable-next-line consistent-return
  async data => {
    try {
      const response = await axiosInstanceNewAPI.post<ExportNetwork>(
        apiPath.downloadNetworkCsv(),
        data,
        {
          withCredentials: true,
        },
      );

      const isDataValid = validateDataUsingZodSchema(response.data, exportNetworkchema);

      if (isDataValid) {
        downloadFileFromBlob(response.data, `${PROJECT_ID}-networkExport.csv`);
      }
    } catch (error) {
      return Promise.reject(getError({ error, prefix: NETWORK_DOWNLOAD_ERROR_PREFIX }));
    }
  },
);
