import { z } from 'zod';
import { overlayLeftBioEntitySchema } from './overlayLeftBioEntitySchema';
import { overlayLeftReactionSchema } from './overlayLeftReactionSchema';
import { overlayRightBioEntitySchema } from './overlayRightBioEntitySchema';

export const overlayElementWithBioEntitySchema = z.object({
  left: overlayLeftBioEntitySchema,
  right: overlayRightBioEntitySchema,
});

export const overlayElementWithReactionSchema = z.object({
  left: overlayLeftReactionSchema,
  right: overlayRightBioEntitySchema,
});

export const overlayBioEntitySchema = z.union([
  overlayElementWithBioEntitySchema,
  overlayElementWithReactionSchema,
]);
