import { PayloadAction } from '@reduxjs/toolkit';
import { Pixel } from 'ol/pixel';
import { ContextMenuState } from './contextMenu.types';

export const openContextMenuReducer = (
  state: ContextMenuState,
  action: PayloadAction<Pixel>,
): void => {
  state.isOpen = true;
  state.coordinates = action.payload;
};

export const closeContextMenuReducer = (state: ContextMenuState): void => {
  state.isOpen = false;
  state.coordinates = [];
};

export const setCurrentSelectedBioEntityIdReducer = (
  state: ContextMenuState,
  action: PayloadAction<number>,
): void => {
  state.currentSelectedBioEntityId = action.payload;
};

export const setUniprot = (state: ContextMenuState, action: PayloadAction<string>): void => {
  state.uniprot = action.payload;
};
