import { ONE } from '@/constants/common';
import { PLUGIN_HASH_PREFIX_SEPARATOR } from '@/constants/plugins';
import { isPluginHashWithPrefix } from './isPluginHashWithPrefix';

export const getPluginHashWithoutPrefix = (hash: string): string => {
  if (isPluginHashWithPrefix(hash)) {
    const hashWihtoutPrefix = hash.substring(hash.lastIndexOf(PLUGIN_HASH_PREFIX_SEPARATOR) + ONE);

    return hashWihtoutPrefix;
  }

  return hash;
};
