import { z } from 'zod';
import { ZERO } from '@/constants/common';

export const mapOverlay = z.object({
  idObject: z.number(),
  name: z.string(),
  order: z.number().int().gte(ZERO),
  creator: z.string(),
  description: z.string(),
  genomeType: z.string().nullable(),
  genomeVersion: z.string().nullable(),
  publicOverlay: z.boolean(),
  type: z.string(),
});

export const createdOverlayFileSchema = z.object({
  id: z.number(),
  filename: z.string(),
  length: z.number(),
  owner: z.string(),
  uploadedDataLength: z.number(),
});

export const uploadedOverlayFileContentSchema = createdOverlayFileSchema.extend({});

export const createdOverlaySchema = z.object({
  name: z.string(),
  creator: z.string(),
  description: z.string(),
  genomeType: z.string().nullable(),
  genomeVersion: z.string().nullable(),
  idObject: z.number(),
  publicOverlay: z.boolean(),
  type: z.string(),
  order: z.number(),
});
