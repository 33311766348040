import { ZERO } from '@/constants/common';
import { z } from 'zod';
import { reactionProduct } from '@/models/reactionProduct';
import { colorSchema } from './colorSchema';
import { glyphSchema } from './glyphSchema';
import { lineSchema } from './lineSchema';
import { modificationResiduesSchema } from './modificationResiduesSchema';
import { operatorSchema } from './operatorSchema';
import { referenceSchema } from './referenceSchema';
import { structuralStateSchema } from './structuralStateSchema';
import { submodelSchema } from './submodelSchema';

export const bioEntitySchema = z.object({
  id: z.union([z.number().int().positive(), z.string()]),
  name: z.string(),
  elementId: z.string(),
  model: z.number(),
  references: z.array(referenceSchema),
  z: z.number(),
  notes: z.string(),
  symbol: z.string().nullable(),
  homodimer: z.number().optional(),
  nameX: z.number().nullable().optional(),
  nameY: z.number().nullable().optional(),
  nameWidth: z.number().nullable().optional(),
  nameHeight: z.number().nullable().optional(),
  nameVerticalAlign: z.string().nullable().optional(),
  nameHorizontalAlign: z.string().nullable().optional(),
  width: z
    .number()
    .optional()
    .transform(width => width ?? ZERO),
  height: z
    .number()
    .optional()
    .transform(height => height ?? ZERO),
  visibilityLevel: z.string().nullable(),
  transparencyLevel: z.string().nullable().optional(),
  synonyms: z.array(z.string()),
  formerSymbols: z.array(z.string()).nullable().optional(),
  fullName: z.string().nullable().nullable().optional(),
  compartmentName: z.string().nullable().optional(),
  abbreviation: z.string().nullable(),
  formula: z.string().nullable(),
  glyph: glyphSchema.nullable().optional(),
  activity: z.boolean().optional(),
  structuralState: z.optional(structuralStateSchema.nullable()),
  hypothetical: z.boolean().nullable().optional(),
  boundaryCondition: z.boolean().optional().nullable(),
  constant: z.boolean().optional().nullable(),
  initialAmount: z.number().nullable().optional(),
  initialConcentration: z.number().nullable().optional(),
  charge: z.number().nullable().optional(),
  substanceUnits: z.string().nullable().optional(),
  onlySubstanceUnits: z.boolean().optional().nullable(),
  shape: z.string().nullable().optional(),
  modificationResidues: z.optional(z.array(modificationResiduesSchema)),
  complex: z.number().nullable().optional(),
  compartment: z.number().nullable().optional(),
  submodel: submodelSchema.nullable().optional(),
  x: z
    .number()
    .optional()
    .transform(x => x ?? ZERO),
  y: z
    .number()
    .optional()
    .transform(y => y ?? ZERO),
  lineWidth: z.number().optional(),
  fontColor: colorSchema.nullable().optional(),
  fontSize: z.number().nullable().optional(),
  fillColor: colorSchema.nullable().optional(),
  borderColor: colorSchema.nullable().optional(),
  smiles: z.optional(z.string()).nullable(),
  inChI: z.optional(z.string().nullable()),
  inChIKey: z.optional(z.string().nullable()),
  thickness: z.optional(z.number()),
  outerWidth: z.optional(z.number()),
  innerWidth: z.optional(z.number()),
  idReaction: z.optional(z.string()),
  reversible: z.optional(z.boolean()),
  mechanicalConfidenceScore: z.optional(z.boolean()).nullable().optional(),
  lowerBound: z.optional(z.boolean()).nullable().optional(),
  upperBound: z.optional(z.boolean()).nullable().optional(),
  subsystem: z.optional(z.string()).nullable().optional(),
  geneProteinReaction: z.optional(z.string()).nullable().optional(),
  kinetics: z.optional(z.null()),
  products: z.optional(z.array(reactionProduct)),
  reactants: z.optional(z.array(reactionProduct)),
  modifiers: z.optional(z.array(reactionProduct)),
  processCoordinates: z.optional(z.null()),
  line: z.optional(lineSchema),
  operators: z.optional(z.array(operatorSchema)),
  sboTerm: z.string(),
});
