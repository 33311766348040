import { DrawerState } from './drawer.types';

export const DRAWER_INITIAL_STATE: DrawerState = {
  isOpen: false,
  drawerName: 'none',
  searchDrawerState: {
    currentStep: 0,
    stepType: 'none',
    selectedValue: undefined,
    listOfBioEnitites: [],
    selectedSearchElement: '',
  },
  reactionDrawerState: {},
  bioEntityDrawerState: {
    drugs: {},
    chemicals: {},
  },
  overlayDrawerState: {
    currentStep: 0,
  },
  commentDrawerState: {
    commentId: undefined,
  },
};

export const RESULT_DRAWERS = ['search', 'reaction', 'bio-entity'];

export const DRUGS_FOR_BIO_ENTITY_FETCHING_ERROR_PREFIX = 'Failed to fetch drugs for bio entity';
export const CHEMICALS_FOR_BIO_ENTITY_FETCHING_ERROR_PREFIX =
  'Failed to fetch chemicals for bio entity';
