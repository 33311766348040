import { z } from 'zod';

const coordinatesSchema = z.object({
  x: z.number(),
  y: z.number(),
});

export const commentSchema = z.object({
  title: z.string(),
  icon: z.string(),
  type: z.enum(['POINT', 'ALIAS', 'REACTION']),
  content: z.string(),
  removed: z.boolean(),
  coord: coordinatesSchema,
  modelId: z.number(),
  elementId: z.string(),
  id: z.number(),
  pinned: z.boolean(),
  owner: z.string().nullable().optional(),
});
