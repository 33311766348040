import { createSlice } from '@reduxjs/toolkit';
import { ENTITY_NUMBER_INITIAL_STATE } from './entityNumber.constants';
import {
  addNumbersToEntityNumberDataReducer,
  clearEntityNumberDataReducer,
} from './entityNumber.reducers';

export const entityNumberSlice = createSlice({
  name: 'entityNumber',
  initialState: ENTITY_NUMBER_INITIAL_STATE,
  reducers: {
    addNumbersToEntityNumberData: addNumbersToEntityNumberDataReducer,
    clearEntityNumberData: clearEntityNumberDataReducer,
  },
  extraReducers: () => {},
});

export const { addNumbersToEntityNumberData, clearEntityNumberData } = entityNumberSlice.actions;

export default entityNumberSlice.reducer;
