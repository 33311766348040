import { z } from 'zod';
import { colorSchema } from '@/models/colorSchema';

export const modelElementModificationSchema = z.object({
  id: z.number().int().positive(),
  idModificationResidue: z.string(),
  name: z.string(),
  x: z.number(),
  y: z.number(),
  z: z.number(),
  width: z.number(),
  height: z.number(),
  nameX: z.number(),
  nameY: z.number(),
  nameWidth: z.number(),
  nameHeight: z.number(),
  nameVerticalAlign: z.enum(['TOP', 'MIDDLE', 'BOTTOM']),
  nameHorizontalAlign: z.enum(['LEFT', 'RIGHT', 'CENTER', 'END', 'START']),
  species: z.number(),
  borderColor: colorSchema,
  fillColor: colorSchema,
  fontSize: z.number(),
  state: z.string().nullable().optional(),
  stateAbbreviation: z.string().nullable().optional(),
  direction: z.enum(['RIGHT', 'LEFT']).optional(),
  active: z.boolean().optional(),
  sboTerm: z.string(),
  size: z.number(),
  elementId: z.string(),
});
