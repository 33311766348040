import axios from 'axios';

export const downloadFileFromBlob = (data: string, filename: string): void => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadFileFromUrl = async (url: string): Promise<void> => {
  const genericAxios = axios.create();

  const response = await genericAxios.get(url, {
    withCredentials: true,
    responseType: 'arraybuffer',
  });

  const content = Buffer.from(response.data, 'binary');

  let filename = 'file.xml';
  if (response.headers && response.headers['content-type'] === 'application/zip') {
    filename = 'file.zip';
  }

  const tmpUrl = window.URL.createObjectURL(new Blob([content], { type: 'application/zip' }));
  const link = document.createElement('a');
  link.href = tmpUrl;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
