import { LegendState } from './legend.types';

export const DEFAULT_LEGEND_ID = 'MAIN';

export const LEGEND_INITIAL_STATE: LegendState = {
  isOpen: false,
  pluginLegend: {},
  activeLegendId: DEFAULT_LEGEND_ID,
};

export const DEFAULT_LEGEND_TAB = {
  name: 'Main Legend',
  id: DEFAULT_LEGEND_ID,
};
