interface MaginfierZoomOutProps {
  className?: string;
}

export const MaginfierZoomOutIcon = ({ className }: MaginfierZoomOutProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_4_194)">
      <path d="M6 9H15V12H6V9Z" fill="#8E92A1" />
      <path
        d="M23.5605 19.9395L19.5 15.879C20.4798 14.2558 20.9985 12.396 21 10.5C21 4.71 16.2885 0 10.5 0C4.7115 0 0 4.71 0 10.5C0 16.29 4.7115 21 10.5 21C12.3962 20.9994 14.2562 20.4808 15.879 19.5L19.9395 23.5605C20.0785 23.7003 20.2437 23.8112 20.4257 23.8869C20.6077 23.9626 20.8029 24.0016 21 24.0016C21.1971 24.0016 21.3923 23.9626 21.5743 23.8869C21.7563 23.8112 21.9215 23.7003 22.0605 23.5605L23.5605 22.0605C23.6999 21.9213 23.8105 21.756 23.886 21.574C23.9615 21.3921 24.0003 21.197 24.0003 21C24.0003 20.803 23.9615 20.6079 23.886 20.426C23.8105 20.244 23.6999 20.0787 23.5605 19.9395V19.9395ZM10.5 18C8.51068 17.9998 6.60291 17.2094 5.19639 15.8026C3.78987 14.3957 2.9998 12.4878 3 10.4985C3.0002 8.50918 3.79064 6.60141 5.19745 5.19489C6.60425 3.78837 8.51218 2.9983 10.5015 2.9985C12.4908 2.9987 14.3986 3.78915 15.8051 5.19595C17.2116 6.60275 18.0017 8.51068 18.0015 10.5C18.0013 12.4893 17.2109 14.3971 15.8041 15.8036C14.3972 17.2101 12.4893 18.0002 10.5 18V18Z"
        fill="#8E92A1"
      />
    </g>
    <defs>
      <clipPath id="clip0_4_194">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
