export default function arrayToDict<T, K extends keyof T, V extends keyof T>(
  array: T[],
  keyKey: K,
  valueKey: V,
): Record<T[K] & PropertyKey, T[V]> {
  return array.reduce(
    (accumulator, currentItem) => {
      accumulator[currentItem[keyKey] as T[K] & PropertyKey] = currentItem[valueKey];
      return accumulator;
    },
    {} as Record<T[K] & PropertyKey, T[V]>,
  );
}
