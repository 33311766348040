import { z } from 'zod';

export const mapBackground = z.object({
  id: z.number(),
  name: z.string(),
  defaultOverlay: z.boolean(),
  project: z.object({ projectId: z.string() }),
  creator: z.object({ login: z.string() }),
  status: z.string(),
  progress: z.number(),
  description: z.string().nullable(),
  order: z.number(),
  images: z.array(
    z.object({
      id: z.number(),
      model: z.object({ id: z.number() }),
      projectBackground: z.object({ id: z.number() }),
      path: z.string(),
    }),
  ),
});
