import { AutocompleteState } from '@/redux/autocomplete/autocomplete.types';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  getChemicalAutocomplete,
  getDrugAutocomplete,
  getSearchAutocomplete,
} from '@/redux/autocomplete/autocomplete.thunks';

export const getSearchAutocompleteReducer = (
  builder: ActionReducerMapBuilder<AutocompleteState>,
): void => {
  builder.addCase(getSearchAutocomplete.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getSearchAutocomplete.fulfilled, (state, action) => {
    state.searchValues = action.payload ? action.payload : [];
    state.loading = 'succeeded';
  });
  builder.addCase(getSearchAutocomplete.rejected, state => {
    state.loading = 'failed';
    // TODO: error management to be discussed in the team
  });
};

export const getDrugAutocompleteReducer = (
  builder: ActionReducerMapBuilder<AutocompleteState>,
): void => {
  builder.addCase(getDrugAutocomplete.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getDrugAutocomplete.fulfilled, (state, action) => {
    state.searchValues = action.payload ? action.payload : [];
    state.loading = 'succeeded';
  });
  builder.addCase(getDrugAutocomplete.rejected, state => {
    state.loading = 'failed';
    // TODO: error management to be discussed in the team
  });
};

export const getChemicalAutocompleteReducer = (
  builder: ActionReducerMapBuilder<AutocompleteState>,
): void => {
  builder.addCase(getChemicalAutocomplete.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getChemicalAutocomplete.fulfilled, (state, action) => {
    state.searchValues = action.payload ? action.payload : [];
    state.loading = 'succeeded';
  });
  builder.addCase(getChemicalAutocomplete.rejected, state => {
    state.loading = 'failed';
    // TODO: error management to be discussed in the team
  });
};
