import { createSlice } from '@reduxjs/toolkit';
import {
  closePluginsDrawerReducer,
  getAllPluginsReducer,
  openPluginsDrawerReducer,
  registerPluginReducer,
  removePluginReducer,
  setCurrentDrawerPluginHashReducer,
} from './plugins.reducers';

import { PLUGINS_INITIAL_STATE } from './plugins.constants';

const pluginsSlice = createSlice({
  name: 'plugins',
  initialState: PLUGINS_INITIAL_STATE,
  reducers: {
    removePlugin: removePluginReducer,
    openPluginsDrawer: openPluginsDrawerReducer,
    closePluginsDrawer: closePluginsDrawerReducer,
    setCurrentDrawerPluginHash: setCurrentDrawerPluginHashReducer,
  },
  extraReducers: builder => {
    registerPluginReducer(builder);
    getAllPluginsReducer(builder);
  },
});

export const { removePlugin, openPluginsDrawer, closePluginsDrawer, setCurrentDrawerPluginHash } =
  pluginsSlice.actions;
export default pluginsSlice.reducer;
