import {
  clearSearchDataReducer,
  getSearchDataReducer,
  setPerfectMatchReducer,
} from '@/redux/search/search.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { SEARCH_INITIAL_STATE } from './search.constants';

export const searchSlice = createSlice({
  name: 'search',
  initialState: SEARCH_INITIAL_STATE,
  reducers: {
    setPerfectMatch: setPerfectMatchReducer,
    clearSearchData: clearSearchDataReducer,
  },
  extraReducers(builder) {
    getSearchDataReducer(builder);
  },
});

export const { setPerfectMatch, clearSearchData } = searchSlice.actions;

export default searchSlice.reducer;
