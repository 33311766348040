import { Configuration, ConfigurationOption } from '@/types/models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { z } from 'zod';
import { axiosInstance } from '@/services/api/utils/axiosInstance';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { configurationSchema } from '@/models/configurationSchema';
import { configurationOptionSchema } from '@/models/configurationOptionSchema';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { apiPath } from '../apiPath';
import {
  CONFIGURATION_FETCHING_ERROR_PREFIX,
  CONFIGURATION_OPTIONS_FETCHING_ERROR_PREFIX,
} from './configuration.constants';

export const getConfigurationOptions = createAsyncThunk<
  ConfigurationOption[] | undefined,
  void,
  ThunkConfig
>('configuration/getConfigurationOptions', async () => {
  try {
    const response = await axiosInstance.get<ConfigurationOption[]>(
      apiPath.getConfigurationOptions(),
    );

    const isDataValid = validateDataUsingZodSchema(
      response.data,
      z.array(configurationOptionSchema),
    );

    return isDataValid ? response.data : undefined;
  } catch (error) {
    return Promise.reject(getError({ error, prefix: CONFIGURATION_OPTIONS_FETCHING_ERROR_PREFIX }));
  }
});

export const getConfiguration = createAsyncThunk<Configuration | undefined, void, ThunkConfig>(
  'configuration/getConfiguration',
  async () => {
    try {
      const response = await axiosInstance.get<Configuration>(apiPath.getConfiguration());

      const isDataValid = validateDataUsingZodSchema(response.data, configurationSchema);

      return isDataValid ? response.data : undefined;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: CONFIGURATION_FETCHING_ERROR_PREFIX }));
    }
  },
);
