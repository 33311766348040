interface AdminIconProps {
  className?: string;
}

export const AdminIcon = ({ className }: AdminIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2028_6408)">
      <path d="M9.62979 11.9854V13.5727C8.37738 13.5727 7.17627 14.0744 6.29068 14.9675C5.40509 15.8605 4.90757 17.0717 4.90757 18.3346H3.3335C3.3335 16.6507 3.99685 15.0358 5.17764 13.8451C6.35842 12.6544 7.95991 11.9854 9.62979 11.9854ZM9.62979 11.1918C7.02076 11.1918 4.90757 9.06082 4.90757 6.42987C4.90757 3.79892 7.02076 1.66797 9.62979 1.66797C12.2388 1.66797 14.352 3.79892 14.352 6.42987C14.352 9.06082 12.2388 11.1918 9.62979 11.1918ZM9.62979 9.60448C11.3691 9.60448 12.7779 8.18384 12.7779 6.42987C12.7779 4.6759 11.3691 3.25527 9.62979 3.25527C7.89044 3.25527 6.48164 4.6759 6.48164 6.42987C6.48164 8.18384 7.89044 9.60448 9.62979 9.60448ZM16.7131 14.3664H17.5002V18.3346H11.2039V14.3664H11.9909V13.5727C11.9909 12.9413 12.2397 12.3357 12.6825 11.8891C13.1253 11.4426 13.7258 11.1918 14.352 11.1918C14.9782 11.1918 15.5788 11.4426 16.0216 11.8891C16.4644 12.3357 16.7131 12.9413 16.7131 13.5727V14.3664ZM15.1391 14.3664V13.5727C15.1391 13.3622 15.0561 13.1604 14.9085 13.0115C14.7609 12.8627 14.5608 12.7791 14.352 12.7791C14.1433 12.7791 13.9431 12.8627 13.7955 13.0115C13.6479 13.1604 13.565 13.3622 13.565 13.5727V14.3664H15.1391Z" />
    </g>
    <defs>
      <clipPath id="clip0_2028_6408">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
