/* eslint-disable no-magic-numbers */
import { CreatedOverlay, MapOverlay } from '@/types/models';
import { showToast } from '@/utils/showToast';
import { ERROR_INVALID_EVENT_TYPE, ERROR_PLUGIN_CRASH } from '../errorMessages';
import {
  ALLOWED_PLUGINS_EVENTS,
  LISTENER_NOT_FOUND,
  LOCAL_LISTENER_ID,
} from './pluginsEventBus.constants';
import type {
  CenteredCoordinates,
  ClickedBioEntity,
  ClickedPinIcon,
  ClickedSurfaceOverlay,
  Events,
  EventsData,
  PluginUnloaded,
  PluginsEventBusType,
  SearchData,
  UnknownCallback,
  ZoomChanged,
} from './pluginsEventBus.types';

export function dispatchEvent(type: 'onPluginUnload', data: PluginUnloaded): void;
export function dispatchEvent(type: 'onAddDataOverlay', createdOverlay: CreatedOverlay): void;
export function dispatchEvent(type: 'onRemoveDataOverlay', overlayId: number): void;
export function dispatchEvent(type: 'onShowOverlay', overlay: MapOverlay): void;
export function dispatchEvent(type: 'onHideOverlay', overlay: MapOverlay): void;
export function dispatchEvent(type: 'onBackgroundOverlayChange', backgroundId: number): void;
export function dispatchEvent(type: 'onSubmapOpen', submapId: number): void;
export function dispatchEvent(type: 'onSubmapClose', submapId: number): void;
export function dispatchEvent(type: 'onZoomChanged', data: ZoomChanged): void;
export function dispatchEvent(type: 'onCenterChanged', data: CenteredCoordinates): void;
export function dispatchEvent(type: 'onBioEntityClick', data: ClickedBioEntity): void;
export function dispatchEvent(type: 'onPinIconClick', data: ClickedPinIcon): void;
export function dispatchEvent(type: 'onSurfaceClick', data: ClickedSurfaceOverlay): void;
export function dispatchEvent(type: 'onSearch', data: SearchData): void;
export function dispatchEvent(type: Events, data: EventsData): void {
  if (!ALLOWED_PLUGINS_EVENTS.includes(type)) throw new Error(ERROR_INVALID_EVENT_TYPE(type));

  // eslint-disable-next-line no-restricted-syntax, no-use-before-define
  for (const event of PluginsEventBus.events) {
    if (event.type === type) {
      try {
        event.callback(data);
      } catch (error) {
        showToast({
          message: ERROR_PLUGIN_CRASH(event.pluginName),
          type: 'error',
        });
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }
}

export const PluginsEventBus: PluginsEventBusType = {
  events: [],

  addListener: (
    hash: string,
    pluginName: string,
    type: Events,
    callback: (data: unknown) => void,
  ) => {
    PluginsEventBus.events.push({
      hash,
      pluginName,
      type,
      callback,
    });
  },

  addLocalListener: <T>(type: Events, callback: (data: T) => void) => {
    PluginsEventBus.addListener(
      LOCAL_LISTENER_ID,
      LOCAL_LISTENER_ID,
      type,
      callback as UnknownCallback,
    );
  },

  removeListener: (hash: string, type: Events, callback: unknown) => {
    const eventIndex = PluginsEventBus.events.findIndex(
      event => event.hash === hash && event.type === type && event.callback === callback,
    );

    if (eventIndex !== LISTENER_NOT_FOUND) {
      PluginsEventBus.events.splice(eventIndex, 1);
    } else {
      throw new Error("Listener doesn't exist");
    }
  },

  removeLocalListener: <T>(type: Events, callback: T) => {
    PluginsEventBus.removeListener(LOCAL_LISTENER_ID, type, callback as UnknownCallback);
  },

  removeAllListeners: (hash: string) => {
    PluginsEventBus.events = PluginsEventBus.events.filter(event => event.hash !== hash);
  },

  dispatchEvent,
};
