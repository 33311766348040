import { AppWrapper } from '@/components/AppWrapper';
import type { AppProps } from 'next/app';
import '@/styles/index.css';
import { useEffect } from 'react';
import { store } from '@/redux/store';

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const project = store.getState().project.data;

  let title = '';
  if (project) {
    title = project.name;
  }

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <AppWrapper>
      <Component {...pageProps} />
    </AppWrapper>
  );
};

export default MyApp;
