interface ChevronDownIconProps {
  className?: string;
}

export const ChevronDownIcon = ({ className }: ChevronDownIconProps): JSX.Element => (
  <svg viewBox="0 0 14 14" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2005_6461)">
      <path d="M6.99958 7.68437L9.88708 4.79687L10.7119 5.62171L6.99958 9.33404L3.28725 5.62171L4.11208 4.79687L6.99958 7.68437Z" />
    </g>
    <defs>
      <clipPath id="clip0_2005_6461">
        <rect width="14" height="14" fill="white" transform="translate(14) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
);
