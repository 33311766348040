import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { CommentsState } from '@/redux/comment/comment.types';
import {
  getCommentElement,
  getCommentReaction,
  getComments,
} from '@/redux/comment/thunks/getComments';

export const getCommentsReducer = (builder: ActionReducerMapBuilder<CommentsState>): void => {
  builder.addCase(getComments.pending, state => {
    state.loading = 'pending';
  });

  builder.addCase(getComments.fulfilled, (state, action) => {
    state.loading = 'succeeded';
    state.data = action.payload;
  });

  builder.addCase(getComments.rejected, state => {
    state.loading = 'failed';
  });
};

export const getCommentElementReducer = (builder: ActionReducerMapBuilder<CommentsState>): void => {
  builder.addCase(getCommentElement.pending, state => {
    state.loading = 'pending';
    state.commentElement = null;
  });

  builder.addCase(getCommentElement.fulfilled, (state, action) => {
    state.loading = 'succeeded';
    state.commentElement = action.payload;
  });

  builder.addCase(getCommentElement.rejected, state => {
    state.loading = 'failed';
    state.commentElement = null;
  });
};

export const getCommentReactionReducer = (
  builder: ActionReducerMapBuilder<CommentsState>,
): void => {
  builder.addCase(getCommentReaction.pending, state => {
    state.loading = 'pending';
    state.commentReaction = null;
  });

  builder.addCase(getCommentReaction.fulfilled, (state, action) => {
    state.loading = 'succeeded';
    state.commentReaction = action.payload;
  });

  builder.addCase(getCommentReaction.rejected, state => {
    state.loading = 'failed';
    state.commentReaction = null;
  });
};

export const showCommentsReducer = (state: CommentsState): void => {
  state.isOpen = true;
};

export const hideCommentsReducer = (state: CommentsState): void => {
  state.isOpen = false;
};
