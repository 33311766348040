import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getModelElementsForModel } from '@/redux/modelElements/modelElements.thunks';
import { ModelElementsState } from '@/redux/modelElements/modelElements.types';
import { MODEL_ELEMENTS_STATE_INITIAL_MODEL_MOCK } from '@/redux/modelElements/modelElements.mock';
import { DEFAULT_ERROR } from '@/constants/errors';

export const getModelElementsReducer = (
  builder: ActionReducerMapBuilder<ModelElementsState>,
): void => {
  builder.addCase(getModelElementsForModel.pending, (state, action) => {
    const modelId = action.meta.arg;
    if (state[modelId]) {
      state[modelId].loading = 'pending';
    } else {
      state[modelId] = { ...MODEL_ELEMENTS_STATE_INITIAL_MODEL_MOCK, loading: 'pending' };
    }
  });
  builder.addCase(getModelElementsForModel.fulfilled, (state, action) => {
    const modelId = action.meta.arg;
    const data = action.payload || [];
    if (state[modelId]) {
      state[modelId].data = data;
      state[modelId].loading = 'succeeded';
    } else {
      state[modelId] = { data, loading: 'pending', error: DEFAULT_ERROR };
    }
  });
  builder.addCase(getModelElementsForModel.rejected, (state, action) => {
    const modelId = action.meta.arg;
    if (state[modelId]) {
      state[modelId].loading = 'failed';
    } else {
      state[modelId] = { ...MODEL_ELEMENTS_STATE_INITIAL_MODEL_MOCK, loading: 'failed' };
    }
  });
};
