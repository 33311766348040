import { submapConnection } from '@/models/submapConnection';
import { apiPath } from '@/redux/apiPath';
import { axiosInstance, axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { BioEntity, SubmapConnection } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { z } from 'zod';
import { getError } from '@/utils/error-report/getError';
import { bioEntitySchema } from '@/models/bioEntitySchema';
import { MULTI_BIO_ENTITY_FETCHING_ERROR_PREFIX } from '../bioEntity.constants';

export const getSubmapConnectionsBioEntity = createAsyncThunk<BioEntity[]>(
  'project/getSubmapConnectionsBioEntity',
  async () => {
    try {
      const response = await axiosInstance.get<SubmapConnection[]>(apiPath.getSubmapConnections());

      const isDataValid = validateDataUsingZodSchema(response.data, z.array(submapConnection));
      if (!isDataValid) {
        throw new Error('Submap connections validation error');
      }

      const targetElements = response.data.map(({ from }) => from);

      const asyncFetchBioEntityFunctions = targetElements.map(targetElement =>
        axiosInstanceNewAPI.get<BioEntity>(
          apiPath.getElementById(targetElement.id, targetElement.modelId),
        ),
      );

      const bioEntityContentResponse = await Promise.all(asyncFetchBioEntityFunctions);

      const bioEntityContents = bioEntityContentResponse
        .map(contentResponse => contentResponse.data)
        .flat()
        .filter(content => bioEntitySchema.safeParse(content).success);

      return bioEntityContents;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: MULTI_BIO_ENTITY_FETCHING_ERROR_PREFIX }));
    }
  },
);
