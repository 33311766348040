import { createSlice } from '@reduxjs/toolkit';
import { AUTOCOMPLETE_INITIAL_STATE } from '@/redux/autocomplete/autocomplete.constants';
import {
  getChemicalAutocompleteReducer,
  getDrugAutocompleteReducer,
  getSearchAutocompleteReducer,
} from '@/redux/autocomplete/autocomplete.reducers';

export const autocompleteSearchSlice = createSlice({
  name: 'autocompleteSearch',
  initialState: AUTOCOMPLETE_INITIAL_STATE,
  reducers: {},
  extraReducers(builder) {
    getSearchAutocompleteReducer(builder);
  },
});

export const autocompleteSearchReducer = autocompleteSearchSlice.reducer;

export const autocompleteDrugSlice = createSlice({
  name: 'autocompleteDrug',
  initialState: AUTOCOMPLETE_INITIAL_STATE,
  reducers: {},
  extraReducers(builder) {
    getDrugAutocompleteReducer(builder);
  },
});

export const autocompleteDrugReducer = autocompleteDrugSlice.reducer;

export const autocompleteChemicalSlice = createSlice({
  name: 'autocompleteChemical',
  initialState: AUTOCOMPLETE_INITIAL_STATE,
  reducers: {},
  extraReducers(builder) {
    getChemicalAutocompleteReducer(builder);
  },
});

export const autocompleteChemicalReducer = autocompleteChemicalSlice.reducer;
