import { z } from 'zod';

export const shapeRelAbsBezierPointSchema = z.object({
  type: z.literal('REL_ABS_BEZIER_POINT'),
  absoluteX1: z.number(),
  absoluteY1: z.number(),
  relativeX1: z.number(),
  relativeY1: z.number(),
  relativeHeightForX1: z.number().nullable(),
  relativeWidthForY1: z.number().nullable(),
  absoluteX2: z.number(),
  absoluteY2: z.number(),
  relativeX2: z.number(),
  relativeY2: z.number(),
  relativeHeightForX2: z.number().nullable(),
  relativeWidthForY2: z.number().nullable(),
  absoluteX3: z.number(),
  absoluteY3: z.number(),
  relativeX3: z.number(),
  relativeY3: z.number(),
  relativeHeightForX3: z.number().nullable(),
  relativeWidthForY3: z.number().nullable(),
});
