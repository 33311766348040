import axios from 'axios';
import {
  UNKNOWN_AXIOS_ERROR_CODE,
  UNKNOWN_ERROR,
} from '../getErrorMessage/getErrorMessage.constants';

export const getErrorCode = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    let code = UNKNOWN_AXIOS_ERROR_CODE;
    try {
      if (error.response) {
        if (typeof error.response.data === 'object') {
          code = error.response.data['error-id'];
        } else if (typeof error.response.data === 'string') {
          code = JSON.parse(error.response.data)['error-id'];
        }
        if (code === undefined || code === null) {
          code = `${error.response.status}`;
        }
      }
    } catch (e) {
      code = UNKNOWN_AXIOS_ERROR_CODE;
    }
    return code;
  }
  return UNKNOWN_ERROR;
};
