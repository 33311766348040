import { z } from 'zod';

export const articleSchema = z.object({
  title: z.string(),
  authors: z.array(z.string()),
  journal: z.string(),
  year: z.number(),
  link: z.string(),
  pubmedId: z.string(),
  citationCount: z.number(),
});
