import { z } from 'zod';
import { positionSchema } from './positionSchema';
import { colorSchema } from './colorSchema';

export const modificationResiduesSchema = z.object({
  id: z.number(),
  idModificationResidue: z.string(),
  name: z.string(),
  position: z.optional(positionSchema),
  z: z.number(),
  borderColor: colorSchema,
  state: z.optional(z.union([z.string(), z.number()]).nullable()),
  size: z.number(),
  elementId: z.string(),
});
