import { projectSchema } from '@/models/projectSchema';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { Project } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/types/store';
import { DEFAULT_PROJECT_ID } from '@/constants';
import { getError } from '@/utils/error-report/getError';
import { apiPath } from '../apiPath';
import { PROJECT_FETCHING_ERROR_PREFIX } from './project.constants';
import { SetProjectIdParams } from './project.types';

export const getProjectById = createAsyncThunk<Project | undefined, string, ThunkConfig>(
  'project/getProjectById',
  async id => {
    try {
      const response = await axiosInstanceNewAPI.get<Project>(apiPath.getProjectById(id));

      const isDataValid = validateDataUsingZodSchema(response.data, projectSchema);

      return isDataValid ? response.data : undefined;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: PROJECT_FETCHING_ERROR_PREFIX }));
    }
  },
);

export const setProjectId = createAsyncThunk<string, SetProjectIdParams, ThunkConfig>(
  'project/setProjectId',
  async ({ queryData }) => {
    const projectId = queryData?.id || DEFAULT_PROJECT_ID;

    return projectId;
  },
);
