import { z } from 'zod';
import { colorSchema } from './colorSchema';
import { referenceSchema } from './referenceSchema';

export const overlayLeftBioEntitySchema = z.object({
  id: z.number(),
  model: z.number(),
  glyph: z.unknown(),
  submodel: z.unknown(),
  compartment: z.number().nullable(),
  elementId: z.union([z.string(), z.number()]),
  x: z.number(),
  y: z.number(),
  z: z.number(),
  width: z.number(),
  height: z.number(),
  fontSize: z.number().optional(),
  fontColor: colorSchema.optional(),
  fillColor: colorSchema.optional(),
  borderColor: colorSchema,
  visibilityLevel: z.string().nullable(),
  transparencyLevel: z.string(),
  notes: z.string(),
  symbol: z.string().nullable(),
  fullName: z.string().nullable().optional(),
  abbreviation: z.unknown(),
  formula: z.unknown(),
  name: z.string(),
  nameX: z.number().optional(),
  nameY: z.number().optional(),
  nameWidth: z.number().optional(),
  nameHeight: z.number().optional(),
  nameVerticalAlign: z.string().optional(),
  nameHorizontalAlign: z.string().optional(),
  synonyms: z.array(z.string()),
  formerSymbols: z.array(z.string()).optional(),
  activity: z.boolean().optional(),
  lineWidth: z.number().optional(),
  complex: z.number().nullable().optional(),
  initialAmount: z.unknown().nullable(),
  charge: z.unknown(),
  initialConcentration: z.number().nullable().optional(),
  onlySubstanceUnits: z.boolean().nullable().optional(),
  homodimer: z.number().optional(),
  hypothetical: z.unknown(),
  boundaryCondition: z.boolean().optional().nullable(),
  constant: z.boolean().nullable().optional(),
  modificationResidues: z.unknown(),
  substanceUnits: z.boolean().nullable().optional(),
  references: z.array(referenceSchema),
  sboTerm: z.string(),
});
