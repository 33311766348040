import { OverlayReactionCoords } from '@/types/OLrendering';
import { Line } from '@/types/models';

export const getOverlayReactionCoordsFromLine = (line: Line): OverlayReactionCoords[] =>
  line.segments.map(segment => {
    const { x1, y1, x2, y2 } = segment;

    return {
      ...segment,
      id: line.id,
      width: x2 - x1,
      height: y1 - y2,
    };
  });
