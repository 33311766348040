import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { getOverlayBioEntity, getOverlayBioEntityForAllModels } from './overlayBioEntity.thunk';
import {
  OverlaysBioEntityState,
  RemoveOverlayBioEntityForGivenOverlayAction,
} from './overlayBioEntity.types';

export const getOverlayBioEntityReducer = (
  builder: ActionReducerMapBuilder<OverlaysBioEntityState>,
): void => {
  builder.addCase(getOverlayBioEntity.fulfilled, (state, action) => {
    if (action.payload) {
      const { overlayId, modelId } = action.meta.arg;
      if (!state.data[action.meta.arg.overlayId]) {
        state.data[overlayId] = {};
      }

      state.data[overlayId][modelId] = action.payload;
    }
  });
};

export const getOverlayBioEntityForAllModelsReducer = (
  builder: ActionReducerMapBuilder<OverlaysBioEntityState>,
): void => {
  builder.addCase(getOverlayBioEntityForAllModels.pending, (state, action) => {
    const { overlayId } = action.meta.arg;
    state.overlaysId.push(overlayId);
    state.data = {
      ...state.data, // this is expection to the rule of immutability from redux-toolkit. state.data[overlayId] = {} would add null values up to overlayId value witch leads to mess in the store
      [overlayId]: {},
    };
  });
};

export const removeOverlayBioEntityForGivenOverlayReducer = (
  state: OverlaysBioEntityState,
  action: RemoveOverlayBioEntityForGivenOverlayAction,
): void => {
  const { overlayId } = action.payload;
  state.overlaysId = state.overlaysId.filter(id => id !== overlayId);
  delete state.data[overlayId];
};

export const removeOverlayFromOverlaysIdReducer = (
  state: OverlaysBioEntityState,
  action: PayloadAction<number>,
): void => {
  const overlayId = action.payload;
  state.overlaysId = state.overlaysId.filter(id => id !== overlayId);
};

export const addOverlayToOverlaysIdReducer = (
  state: OverlaysBioEntityState,
  action: PayloadAction<number>,
): void => {
  const overlayId = action.payload;
  if (!state.overlaysId.includes(overlayId)) {
    state.overlaysId.push(overlayId);
  }
};
