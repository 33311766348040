import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { PublicationsState, SortColumn, SortOrder } from './publications.types';
import { getPublications } from './publications.thunks';

export const getPublicationsReducer = (
  builder: ActionReducerMapBuilder<PublicationsState>,
): void => {
  builder.addCase(getPublications.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getPublications.fulfilled, (state, action) => {
    state.data = action.payload || undefined;
    state.loading = 'succeeded';
  });
  builder.addCase(getPublications.rejected, state => {
    state.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};

export const setSortOrderAndColumnReducer = (
  state: PublicationsState,
  action: PayloadAction<{ sortOrder: SortOrder; sortColumn: SortColumn }>,
): void => {
  state.sortColumn = action.payload.sortColumn;
  state.sortOrder = action.payload.sortOrder;
};

export const setSelectedModelIdReducer = (
  state: PublicationsState,
  action: PayloadAction<string | undefined>,
): void => {
  state.selectedModelId = action.payload;
};

export const setSearchValueReducer = (
  state: PublicationsState,
  action: PayloadAction<string>,
): void => {
  state.searchValue = action.payload;
};
