import { ErrorData } from '@/utils/error-report/ErrorData';
import { SerializedError } from '@reduxjs/toolkit';
import { ONE_THOUSAND } from '@/constants/common';
import {
  GENERIC_AXIOS_ERROR_CODE,
  NOT_FOUND_AXIOS_ERROR_CODE,
  UNKNOWN_AXIOS_ERROR_CODE,
  UNKNOWN_ERROR,
} from '@/utils/getErrorMessage/getErrorMessage.constants';
import { axiosInstance } from '@/services/api/utils/axiosInstance';
import { JavaStacktrace } from '@/types/models';
import { apiPath } from '@/redux/apiPath';
import type { RootState } from '@/redux/store';

export const createErrorData = async (
  error: Error | SerializedError | undefined,
  state: RootState,
): Promise<ErrorData> => {
  let stacktrace = 'N/A';
  if (error !== undefined) {
    stacktrace = error.stack !== undefined ? error.stack : 'N/A';
  }

  let login = null;
  let userData = null;

  if (state.user) {
    login = state.user.login;
    userData = state.user.userData;
  }
  if (!login) {
    login = 'anonymous';
  }

  let email = null;
  if (userData) {
    email = userData.email;
  }

  const configuration = state?.configuration?.main?.data;
  const version = configuration ? configuration.version : null;

  let javaStacktrace = null;
  if (error !== undefined && 'code' in error) {
    const { code } = error;
    if (
      code &&
      code !== UNKNOWN_ERROR &&
      code !== UNKNOWN_AXIOS_ERROR_CODE &&
      code !== GENERIC_AXIOS_ERROR_CODE &&
      code !== NOT_FOUND_AXIOS_ERROR_CODE
    ) {
      try {
        javaStacktrace = (await axiosInstance.get<JavaStacktrace>(apiPath.getStacktrace(code))).data
          .content;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Problem with fetching javaStacktrace', e);
      }
    }
  }

  return {
    url: window?.location?.href,
    login,
    browser: navigator.userAgent,
    comment: null,
    email,
    javaStacktrace,
    stacktrace,
    timestamp: Math.floor(+new Date() / ONE_THOUSAND),
    version,
  };
};
