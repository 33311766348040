import { z } from 'zod';
import { referenceSchema } from './referenceSchema';
import { targetSchema } from './targetSchema';

export const drugSchema = z.object({
  /** identifier of the chemical */
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  /** list of synonyms  */
  synonyms: z.array(z.string()),
  brandNames: z.array(z.string()),
  /** does drug cross blood brain barrier */
  bloodBrainBarrier: z.string(),
  /** list of references */
  references: z.array(referenceSchema),
  /** list of targets */
  targets: z.array(targetSchema),
});
