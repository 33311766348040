/* eslint-disable no-magic-numbers */
export const CHUNK_SIZE = 65535 * 8;

export const OVERLAYS_FETCHING_ERROR_PREFIX = 'Failed to fetch overlays';
export const USER_OVERLAY_ADD_ERROR_PREFIX = 'Failed to add user overlay';
export const USER_OVERLAY_ADD_SUCCESS_MESSAGE = 'User overlay added successfully';
export const USER_OVERLAYS_FETCHING_ERROR_PREFIX = 'Failed to fetch user overlays';
export const USER_OVERLAY_UPDATE_ERROR_PREFIX = 'Failed to update user overlay';
export const USER_OVERLAY_UPDATE_SUCCESS_MESSAGE = 'User overlay updated successfully';
export const USER_OVERLAY_REMOVE_ERROR_PREFIX = 'Failed to remove user overlay';
export const USER_OVERLAY_REMOVE_SUCCESS_MESSAGE = 'User overlay removed successfully';
