import { createSlice } from '@reduxjs/toolkit';
import { StatisticsState } from './statistics.types';
import { getStatisticsByIdReducer } from './statistics.reducers';

const initialState: StatisticsState = {
  data: undefined,
  loading: 'idle',
  error: { name: '', message: '' },
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getStatisticsByIdReducer(builder);
  },
});

export default statisticsSlice.reducer;
