import { DEFAULT_ERROR } from '@/constants/errors';
import { NewReactionsState } from '@/redux/newReactions/newReactions.types';
import { FetchDataState } from '@/types/fetchDataState';
import { NewReaction } from '@/types/models';

export const NEW_REACTIONS_INITIAL_STATE_MOCK: NewReactionsState = {};

export const NEW_REACTIONS_STATE_INITIAL_REACTIONS_MOCK: FetchDataState<Array<NewReaction>> = {
  data: [],
  loading: 'idle',
  error: DEFAULT_ERROR,
};
