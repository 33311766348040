import { z } from 'zod';
import { bioEntityContentSchema } from './bioEntityContentSchema';

export const bioEntityResponseSchema = z.object({
  content: z.array(bioEntityContentSchema),
  totalPages: z.number(),
  totalElements: z.number(),
  numberOfElements: z.number(),
  size: z.number(),
  number: z.number(),
});
