import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { downloadNetwork, downloadElements } from './export.thunks';
import { ExportState } from './export.types';

export const downloadNetworkReducer = (builder: ActionReducerMapBuilder<ExportState>): void => {
  builder.addCase(downloadNetwork.pending, state => {
    state.downloadNetwork.loading = 'pending';
  });
  builder.addCase(downloadNetwork.fulfilled, state => {
    state.downloadNetwork.loading = 'succeeded';
  });
  builder.addCase(downloadNetwork.rejected, state => {
    state.downloadNetwork.loading = 'failed';
  });
};

export const downloadElementsReducer = (builder: ActionReducerMapBuilder<ExportState>): void => {
  builder.addCase(downloadElements.pending, state => {
    state.downloadElements.loading = 'pending';
  });
  builder.addCase(downloadElements.fulfilled, state => {
    state.downloadElements.loading = 'succeeded';
  });
  builder.addCase(downloadElements.rejected, state => {
    state.downloadElements.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};
