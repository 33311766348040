import { EMPTY_BACKGROUND_NAME } from '@/constants/backgrounds';
import { createSelector } from '@reduxjs/toolkit';
import { mapDataSelector } from '../map/map.selectors';
import { rootSelector } from '../root/root.selectors';
import { PATHWAYS_AND_COMPARTMENTS_BACKGROUND, SEMANTIC_BACKGROUND } from './backgrounds.constants';

export const backgroundsSelector = createSelector(rootSelector, state => state.backgrounds);

export const backgroundsDataSelector = createSelector(backgroundsSelector, backgrounds => {
  return (backgrounds?.data || []).map(background => {
    if (background.name === PATHWAYS_AND_COMPARTMENTS_BACKGROUND) {
      return {
        ...background,
        name: SEMANTIC_BACKGROUND,
      };
    }

    return background;
  });
});

const MAIN_BACKGROUND = 0;
export const mainBackgroundsDataSelector = createSelector(
  backgroundsDataSelector,
  backgrounds => backgrounds && backgrounds[MAIN_BACKGROUND],
);
export const mainBackgroundIdSelector = createSelector(
  backgroundsDataSelector,
  backgrounds => backgrounds && backgrounds[MAIN_BACKGROUND]?.id,
);

export const currentBackgroundSelector = createSelector(
  backgroundsDataSelector,
  mapDataSelector,
  (backgrounds, mapData) =>
    backgrounds && backgrounds.find(background => background.id === mapData.backgroundId),
);

export const currentBackgroundImageSelector = createSelector(
  mapDataSelector,
  currentBackgroundSelector,
  (mapData, background) =>
    background && background.images.find(image => image.model.id === mapData.modelId),
);

export const currentBackgroundImagePathSelector = createSelector(
  currentBackgroundImageSelector,
  image => (image ? image.path : ''),
);

export const emptyBackgroundIdSelector = createSelector(backgroundsDataSelector, backgrounds => {
  const emptyBackground = backgrounds?.find(
    background => background.name === EMPTY_BACKGROUND_NAME,
  );
  return emptyBackground?.id;
});
