import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/types/store';
import { ConstantType } from '@/redux/constant/constant.types';
import { getConfigValue } from '@/constants/index.utils';
import { getError } from '@/utils/error-report/getError';

export const getConstant = createAsyncThunk<ConstantType | undefined, void, ThunkConfig>(
  'constant/getConstant',
  async () => {
    try {
      const apiBaseUrl = getConfigValue('BASE_API_URL');
      const result: ConstantType = {
        apiBaseUrl,
        apiDocsUrl: `${apiBaseUrl}/../docs/`,
      };
      return result;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: 'Failed to build constants' }));
    }
  },
);
