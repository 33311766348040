import { DEFAULT_ERROR } from '@/constants/errors';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getDrugs, getMultiDrugs } from './drugs.thunks';
import { DrugsState } from './drugs.types';

export const getDrugsReducer = (builder: ActionReducerMapBuilder<DrugsState>): void => {
  builder.addCase(getDrugs.pending, (state, action) => {
    state.data.push({
      searchQueryElement: action.meta.arg,
      data: undefined,
      loading: 'pending',
      error: DEFAULT_ERROR,
    });
  });
  builder.addCase(getDrugs.fulfilled, (state, action) => {
    const drugs = state.data.find(drug => drug.searchQueryElement === action.meta.arg);
    if (drugs) {
      drugs.data = action.payload;
      drugs.loading = 'succeeded';
    }
  });
  builder.addCase(getDrugs.rejected, (state, action) => {
    const drugs = state.data.find(drug => drug.searchQueryElement === action.meta.arg);
    if (drugs) {
      drugs.loading = 'failed';
      // TODO: error management to be discussed in the team
    }
  });
};

export const getMultiDrugsReducer = (builder: ActionReducerMapBuilder<DrugsState>): void => {
  builder.addCase(getMultiDrugs.pending, state => {
    state.data = [];
    state.loading = 'pending';
  });
  builder.addCase(getMultiDrugs.fulfilled, state => {
    state.loading = 'succeeded';
  });
  builder.addCase(getMultiDrugs.rejected, state => {
    state.loading = 'failed';
    // TODO: error management to be discussed in the team
  });
};

export const clearDrugsDataReducer = (state: DrugsState): void => {
  state.data = [];
  state.loading = 'idle';
};
