import { createSlice } from '@reduxjs/toolkit';
import {
  addOverlayToOverlaysIdReducer,
  getOverlayBioEntityForAllModelsReducer,
  getOverlayBioEntityReducer,
  removeOverlayBioEntityForGivenOverlayReducer,
  removeOverlayFromOverlaysIdReducer,
} from './overlayBioEntity.reducers';
import { OverlaysBioEntityState } from './overlayBioEntity.types';

const initialState: OverlaysBioEntityState = {
  overlaysId: [],
  data: [],
};

export const overlayBioEntitySlice = createSlice({
  name: 'overlayBioEntity',
  initialState,
  reducers: {
    removeOverlayBioEntityForGivenOverlay: removeOverlayBioEntityForGivenOverlayReducer,
    removeOverlayFromOverlaysId: removeOverlayFromOverlaysIdReducer,
    addOverlayToOverlaysId: addOverlayToOverlaysIdReducer,
  },
  extraReducers: builder => {
    getOverlayBioEntityReducer(builder);
    getOverlayBioEntityForAllModelsReducer(builder);
  },
});

export const {
  removeOverlayBioEntityForGivenOverlay,
  removeOverlayFromOverlaysId,
  addOverlayToOverlaysId,
} = overlayBioEntitySlice.actions;
export default overlayBioEntitySlice.reducer;
