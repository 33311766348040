import { DEFAULT_ERROR } from '@/constants/errors';
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { getBioEntityById } from '@/redux/bioEntity/thunks/getBioEntity';
import { BioEntity, BioEntityContent } from '@/types/models';
import { BIOENTITY_SUBMAP_CONNECTIONS_INITIAL_STATE } from './bioEntity.constants';
import { getBioEntity, getMultiBioEntity } from './bioEntity.thunks';
import { BioEntityContentsState } from './bioEntity.types';
import { getSubmapConnectionsBioEntity } from './thunks/getSubmapConnectionsBioEntity';

export const getBioEntityContentsReducer = (
  builder: ActionReducerMapBuilder<BioEntityContentsState>,
): void => {
  builder.addCase(getBioEntity.pending, (state, action) => {
    state.data.push({
      searchQueryElement: action.meta.arg.searchQuery,
      data: undefined,
      loading: 'pending',
      error: DEFAULT_ERROR,
    });
  });
  builder.addCase(getBioEntityById.pending, (state, action) => {
    state.data.push({
      searchQueryElement: `${action.meta.arg.elementId}`,
      data: undefined,
      loading: 'pending',
      error: DEFAULT_ERROR,
    });
  });
  builder.addCase(getBioEntity.fulfilled, (state, action) => {
    const bioEntities = state.data.find(
      bioEntity => bioEntity.searchQueryElement === action.meta.arg.searchQuery,
    );
    if (bioEntities) {
      bioEntities.data = action.payload;
      bioEntities.loading = 'succeeded';
    }
  });
  builder.addCase(getBioEntityById.fulfilled, (state, action) => {
    const bioEntities = state.data.find(
      bioEntity => bioEntity.searchQueryElement === `${action.meta.arg.elementId}`,
    );
    if (bioEntities) {
      bioEntities.data = action.payload ? [{ perfect: true, bioEntity: action.payload }] : [];
      bioEntities.loading = 'succeeded';
    }
  });
  builder.addCase(getBioEntity.rejected, (state, action) => {
    const bioEntities = state.data.find(
      bioEntity => bioEntity.searchQueryElement === action.meta.arg.searchQuery,
    );
    if (bioEntities) {
      bioEntities.loading = 'failed';
      // TODO: error management to be discussed in the team
    }
  });
  builder.addCase(getBioEntityById.rejected, (state, action) => {
    const bioEntities = state.data.find(
      bioEntity => bioEntity.searchQueryElement === `${action.meta.arg.elementId}`,
    );
    if (bioEntities) {
      bioEntities.loading = 'failed';
      // TODO: error management to be discussed in the team
    }
  });
};

export const getMultiBioEntityContentsReducer = (
  builder: ActionReducerMapBuilder<BioEntityContentsState>,
): void => {
  builder.addCase(getMultiBioEntity.pending, state => {
    state.data = [];
    state.loading = 'pending';
  });
  builder.addCase(getMultiBioEntity.fulfilled, state => {
    state.loading = 'succeeded';
  });
  builder.addCase(getMultiBioEntity.rejected, state => {
    state.loading = 'failed';
    // TODO: error management to be discussed in the team
  });
};

export const getSubmapConnectionsBioEntityReducer = (
  builder: ActionReducerMapBuilder<BioEntityContentsState>,
): void => {
  builder.addCase(getSubmapConnectionsBioEntity.pending, state => {
    state.submapConnections = {
      ...BIOENTITY_SUBMAP_CONNECTIONS_INITIAL_STATE,
      ...(state?.submapConnections ? state?.submapConnections : {}),
      data: [],
      loading: 'pending',
    };
  });
  builder.addCase(getSubmapConnectionsBioEntity.fulfilled, (state, action) => {
    state.submapConnections = {
      ...BIOENTITY_SUBMAP_CONNECTIONS_INITIAL_STATE,
      ...(state?.submapConnections ? state?.submapConnections : {}),
      data: action.payload,
      loading: 'succeeded',
    };
  });
  builder.addCase(getSubmapConnectionsBioEntity.rejected, state => {
    state.submapConnections = {
      ...BIOENTITY_SUBMAP_CONNECTIONS_INITIAL_STATE,
      ...(state?.submapConnections ? state?.submapConnections : {}),
      loading: 'failed',
    };
  });
};

export const clearBioEntitiesReducer = (state: BioEntityContentsState): void => {
  state.data = [];
  state.loading = 'idle';
};

export const toggleIsContentTabOpenedReducer = (
  state: BioEntityContentsState,
  action: PayloadAction<boolean>,
): void => {
  state.isContentTabOpened = action.payload;
};

export const setBioEntityContentsReducer = (
  state: BioEntityContentsState,
  action: PayloadAction<BioEntityContent>,
): void => {
  state.data = [
    {
      data: [action.payload],
      loading: 'succeeded',
      error: DEFAULT_ERROR,
      searchQueryElement: action.payload.bioEntity.id.toString(),
    },
  ];
  state.loading = 'succeeded';
};

export const setMultipleBioEntityContentsReducer = (
  state: BioEntityContentsState,
  action: PayloadAction<Array<BioEntity>>,
): void => {
  state.data = action.payload.map(bioEntity => {
    return {
      data: [
        {
          bioEntity,
          perfect: true,
        },
      ],
      searchQueryElement: bioEntity.id.toString(),
      loading: 'succeeded',
      error: DEFAULT_ERROR,
    };
  });
  state.loading = 'succeeded';
};
