import { createSlice } from '@reduxjs/toolkit';
import { ProjectsState } from '@/redux/projects/projects.types';

import { getProjectsReducer } from '@/redux/projects/projects.reducers';

const initialState: ProjectsState = {
  data: [],
  loading: 'idle',
  error: { name: '', message: '' },
};

const projectsSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getProjectsReducer(builder);
  },
});

export default projectsSlice.reducer;
