/* eslint-disable no-restricted-syntax */
import { axiosInstance } from '@/services/api/utils/axiosInstance';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { CompartmentPathway, CompartmentPathwayDetails } from '@/types/models';
import {
  compartmentPathwayDetailsSchema,
  compartmentPathwaySchema,
} from '@/models/compartmentPathwaySchema';
import { z } from 'zod';
import { getError } from '@/utils/error-report/getError';
import {
  COMPARMENT_PATHWAYS_FETCHING_ERROR_PREFIX,
  MAX_NUMBER_OF_IDS_IN_GET_QUERY,
} from './comparmentPathways.constants';
import { apiPath } from '../apiPath';

/** UTILS */

const fetchCompartmentPathwaysIds = async (
  modelsIds: number[] | undefined,
): Promise<number[][]> => {
  if (!modelsIds) return [];

  const compartmentIds = [];

  for (const modelId of modelsIds) {
    /* eslint-disable no-await-in-loop */
    const response = await axiosInstance<CompartmentPathway[]>(
      apiPath.getCompartmentPathwaysIds(modelId),
    );

    const isDataValid = validateDataUsingZodSchema(
      response.data,
      z.array(compartmentPathwaySchema),
    );

    if (isDataValid) {
      const result = response.data;
      const ids: number[] = [];

      result.forEach(item => {
        ids.push(item.id);
      });

      compartmentIds.push(ids);
    }
  }

  return compartmentIds;
};

const fetchCompartmentPathwayDetailsByPost = async (
  compartmentPathwayIds: number[],
): Promise<CompartmentPathwayDetails[]> => {
  const params = {
    id: compartmentPathwayIds.join(','),
  };
  const body = new URLSearchParams(params);

  const response = await axiosInstance.post<CompartmentPathwayDetails[]>(
    apiPath.sendCompartmentPathwaysIds(),
    body,
  );

  return response.data;
};

const fetchCompartmentPathwayDetailsByGet = async (
  compartmentPathwayIds: number[],
): Promise<CompartmentPathwayDetails[]> => {
  const response = await axiosInstance.get<CompartmentPathwayDetails[]>(
    apiPath.getCompartmentPathwayDetails(compartmentPathwayIds),
  );

  return response.data;
};

const fetchCompartmentPathwayDetails = async (
  compartmentPathwayIds: number[],
): Promise<CompartmentPathwayDetails[]> => {
  if (compartmentPathwayIds.length) {
    let compartmentPathwayDetails;
    if (compartmentPathwayIds.length > MAX_NUMBER_OF_IDS_IN_GET_QUERY) {
      compartmentPathwayDetails = await fetchCompartmentPathwayDetailsByPost(compartmentPathwayIds);
    } else {
      compartmentPathwayDetails = await fetchCompartmentPathwayDetailsByGet(compartmentPathwayIds);
    }

    const isDataValid = validateDataUsingZodSchema(
      compartmentPathwayDetails,
      z.array(compartmentPathwayDetailsSchema),
    );

    if (isDataValid) return compartmentPathwayDetails;
  }
  return [];
};

export const fetchCompartmentPathways = async (
  compartmentPathwaysData: number[][],
): Promise<CompartmentPathwayDetails[]> => {
  const compartments = [];

  /* eslint-disable no-await-in-loop */
  for (const compartmentPathwayIds of compartmentPathwaysData) {
    const compartmentPathwayDetails = await fetchCompartmentPathwayDetails(compartmentPathwayIds);

    if (compartmentPathwayDetails) compartments.push(...compartmentPathwayDetails);
  }

  return compartments;
};

/** UTILS */

export const getCompartmentPathways = createAsyncThunk(
  'compartmentPathways/getCompartmentPathways',
  async (modelsIds: number[] | undefined) => {
    try {
      const compartmentIds = await fetchCompartmentPathwaysIds(modelsIds);
      const comparmentPathways = await fetchCompartmentPathways(compartmentIds);

      return comparmentPathways;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: COMPARMENT_PATHWAYS_FETCHING_ERROR_PREFIX }));
    }
  },
);
