import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ZERO } from '@/constants/common';
import { getAllPlugins, registerPlugin } from './plugins.thunks';
import type {
  PluginsState,
  RemovePluginAction,
  SetCurrentDrawerPluginHashAction,
} from './plugins.types';

export const removePluginReducer = (state: PluginsState, action: RemovePluginAction): void => {
  const { pluginId } = action.payload;
  state.activePlugins.pluginsId = state.activePlugins.pluginsId.filter(id => id !== pluginId);
  delete state.activePlugins.data[pluginId];
};

export const registerPluginReducer = (builder: ActionReducerMapBuilder<PluginsState>): void => {
  builder.addCase(registerPlugin.pending, (state, action) => {
    const { hash } = action.meta.arg;
    if (state.activePlugins.pluginsId.indexOf(hash) < ZERO) {
      state.activePlugins.pluginsId.push(hash);
    }
  });
  builder.addCase(registerPlugin.fulfilled, (state, action) => {
    if (action.payload) {
      const { hash, withoutPanel } = action.meta.arg;

      state.activePlugins.data[hash] = action.payload;
      if (!withoutPanel) {
        state.drawer.currentPluginHash = hash;
      }
    }
  });
  builder.addCase(registerPlugin.rejected, state => {
    state.activePlugins.pluginsId = [];
  });
};

export const getAllPluginsReducer = (builder: ActionReducerMapBuilder<PluginsState>): void => {
  builder.addCase(getAllPlugins.pending, state => {
    state.list.loading = 'pending';
  });
  builder.addCase(getAllPlugins.fulfilled, (state, action) => {
    state.list.data = action.payload || [];
    state.list.loading = 'succeeded';
  });
  builder.addCase(getAllPlugins.rejected, state => {
    state.list.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};

export const openPluginsDrawerReducer = (state: PluginsState): void => {
  state.drawer.isOpen = true;
};

export const closePluginsDrawerReducer = (state: PluginsState): void => {
  state.drawer.isOpen = false;
};

export const setCurrentDrawerPluginHashReducer = (
  state: PluginsState,
  action: SetCurrentDrawerPluginHashAction,
): void => {
  state.drawer.currentPluginHash = action.payload;
};
