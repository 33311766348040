import { z } from 'zod';
import { colorSchema } from '@/models/colorSchema';
import { glyphSchema } from '@/models/glyphSchema';

export const layerTextSchema = z.object({
  id: z.number(),
  x: z.number(),
  y: z.number(),
  z: z.number(),
  width: z.number(),
  height: z.number(),
  fontSize: z.number(),
  size: z.number(),
  notes: z.string(),
  glyph: glyphSchema.nullable(),
  elementId: z.string(),
  verticalAlign: z.string(),
  horizontalAlign: z.string(),
  backgroundColor: colorSchema,
  borderColor: colorSchema,
  color: colorSchema,
});
