import { ZodSchema } from 'zod';

type IsApiResponseValid = <TData>(data: TData, schema: ZodSchema) => boolean;

export const validateDataUsingZodSchema: IsApiResponseValid = (data, schema: ZodSchema) => {
  const validationResults = schema.safeParse(data);

  if (validationResults.success === false) {
    // TODO - probably need to rethink way of handling parsing errors, for now let's leave it to console.log
    // eslint-disable-next-line no-console
    console.error('Error on parsing data', validationResults.error);
    // eslint-disable-next-line no-console
    console.error(validationResults.error.message);
  }

  return validationResults.success;
};
