interface ChevronRightIconProps {
  className?: string;
}

export const ChevronRightIcon = ({ className }: ChevronRightIconProps): JSX.Element => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2005_6453)">
      <path
        d="M7.68389 7.0014L4.79639 4.1139L5.62122 3.28906L9.33355 7.0014L5.62122 10.7137L4.79639 9.8889L7.68389 7.0014Z"
        fill="#070130"
      />
    </g>
    <defs>
      <clipPath id="clip0_2005_6453">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
