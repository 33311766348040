import { z } from 'zod';
import { ZodTypeAny } from 'zod/lib/types';

export const pageableSchema = <T extends ZodTypeAny>(type: T): ZodTypeAny =>
  z.object({
    totalPages: z.number().nonnegative(),
    totalElements: z.number().nonnegative(),
    numberOfElements: z.number().nonnegative(),
    size: z.number().positive(),
    number: z.number().nonnegative(),
    content: z.array(type),
  });
