import { apiPath } from '@/redux/apiPath';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { ModelElement, ModelElements } from '@/types/models';
import { MODEL_ELEMENTS_FETCHING_ERROR_PREFIX } from '@/redux/modelElements/modelElements.constants';
import { modelElementSchema } from '@/models/modelElementSchema';
import { pageableSchema } from '@/models/pageableSchema';

export const getModelElementsForModel = createAsyncThunk<
  Array<ModelElement> | undefined,
  number,
  ThunkConfig
>('vectorMap/getModelElementsForModel', async (modelId: number) => {
  try {
    const { data } = await axiosInstanceNewAPI.get<ModelElements>(
      apiPath.getModelElementsForModel(modelId),
    );
    const isDataValid = validateDataUsingZodSchema(data, pageableSchema(modelElementSchema));
    return isDataValid ? data.content : undefined;
  } catch (error) {
    return Promise.reject(getError({ error, prefix: MODEL_ELEMENTS_FETCHING_ERROR_PREFIX }));
  }
});
