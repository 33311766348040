interface LegendIconProps {
  className?: string;
}

export const LegendIcon = ({ className }: LegendIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2028_6411)">
      <path d="M2.5 14.168H17.5V15.8346H2.5V14.168ZM2.5 9.16797H5V11.668H2.5V9.16797ZM6.66667 9.16797H9.16667V11.668H6.66667V9.16797ZM2.5 4.16797H5V6.66797H2.5V4.16797ZM10.8333 4.16797H13.3333V6.66797H10.8333V4.16797ZM15 4.16797H17.5V6.66797H15V4.16797ZM10.8333 9.16797H13.3333V11.668H10.8333V9.16797ZM15 9.16797H17.5V11.668H15V9.16797ZM6.66667 4.16797H9.16667V6.66797H6.66667V4.16797Z" />
    </g>
    <defs>
      <clipPath id="clip0_2028_6411">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
